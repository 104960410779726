import React from "react";
import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import { category_create, category_update, category_delete, all_category_list } from "./../../../actions/Masters/category";
import { useSelector } from "react-redux";
import ViewChange from "../../utils/ViewChange";
import { useDispatch } from "react-redux";
import { TableData } from "./TableData";
import { formStructure } from "./FormStructure";
import { useEffect } from "react";
import { useLocation ,useNavigate } from "react-router-dom";
import Reload from "../../utils/Reload";
export default function Category() {
	const user = useSelector((state) => state.layout.profile)
	const dispatch = useDispatch();
	const rights = useSelector((state) => state.layout.rights)
	const location = useLocation();
	const navigate = useNavigate()
	const tempTableData = {
		...TableData(), deleteRecord: category_delete, updateRecord: category_update,
		onDeleteText: "Are you sure want to delete the Category?",
		customisedStatusUpdateMessage: true,
		onActiveText: "Videos will be visible to all users when you activate this category, are you sure want to change it ?",
		onInactiveText: ["Videos are associated with this category, are you still want to change it.?"],
	}
	const [tableData, setTableData] = useState({ ...tempTableData })
	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
	const [view, setView] = useState(location?.state?.view || "view_all");
	useMemo(() => {
		if (isEdit) { setView("create_new") }
		else {
			setView("view_all")
			setForm({ sequence: Number(tableData.tableBody[tableData.tableBody.length - 1]?.["sequence"]) + 1 });
		}
	}, [isEdit]);
	const path = location?.pathname.split("/")[3]
	const message = useSelector((state) => state.layout.message);

	useEffect(() => {
		setView(path != "Category" ? "create_new" : "view_all");
		setForm((location?.state?.form && JSON.parse(location?.state?.form)) || { sequence: Number(tableData.tableBody[tableData.tableBody.length - 1]?.["sequence"]) + 1 });
		setIsEdit(path == "EditCategory");
	}, [location]);

	useMemo(() => {
		setTableData({ ...tempTableData })
	}, [rights])

	const formTitle = isEdit ? "Edit Category" : "Create Category";



	useEffect(() => {
		dispatch(all_category_list())
	}, [message])


	const categories = useSelector((state) => state.masters.categories);

	useMemo(() => {

		if (categories?.statuscode == 200) {
			const temp = tableData
			temp.tableBody = categories?.data
			setTableData({ ...temp })
			setForm({ ...form, sequence: Number(tableData.tableBody[tableData.tableBody.length - 1]?.["sequence"]) + 1 })
		}
	}, [categories])

	const handleSubmit = (event) => {

		event.preventDefault();
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		data.append("user", user?.id);
		formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		if (isEdit) {
			dispatch(category_update(data))
		}
		else {
			dispatch(category_create(data))
		}


	};
	useMemo(() => {
		if (message?.statuscode == 200) {
			const temp = tableData;
			if (isEdit) {
				temp.tableBody.map(
					(value, index) =>
						value.id == form.id && (temp.tableBody[index] = { ...form })
				);

			} else {
				temp.tableBody[temp.tableBody.length] = {
					id: temp.tableBody.length,
					...form,
					edit: temp.tableBody.length,
				};

			}
			setTableData({ ...temp });
			setIsEdit(false);
			setForm({});
			setTimeout(() => {
				dispatch(all_category_list());
				navigate("/Masters/Category/Category/", { state: { view: "view_all" } })
				setView("view_all");
			}, 1000);
		}
		else {

			setForm({ ...form, category_image: form?.tempcategory_image });
		}
	}, [message])
	return (
		<>

			<ViewChange
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
				access={rights?.["Masters"]?.["create"] == "true"}
				create_new={"/Masters/Category/CreateCategory"}
				view_all={"/Masters/Category/Category"}
				form={form}
				reload={<Reload isClubed={true} />}
			/>

			{view == "create_new" && (
				<Form
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					create_new={"/Masters/Category/EditCategory"}

				/>
			)}


		</>
	);
}
