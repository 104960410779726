import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import {
  notification_create,
  notification_delete,
  notification_update,
  all_notification_list,
  notification_read_status_update,
  notification_unread_count,
} from "../../actions/notification";
import { all_customer_list } from "../../actions/customer";
import { all_distributor_list } from "../../actions/distributor";
import { all_advertiser_list } from "../../actions/Advertiser/advertiser";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EnlargedView from "../utils/EnlargedView";
import Reload from "../utils/Reload";
export default function Notification() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.layout.profile);
  const rights = useSelector((state) => state.layout.rights);
  const role = useSelector((state) => state.layout.role);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState();
  const handleOpen = () => setOpen(true);
  const location = useLocation();
  const navigate = useNavigate();

  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [viewColor , setViewColor]= useState("red")
  const [isEdit, setIsEdit] = useState(false);

  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
      setForm({});
    }
  }, [isEdit]);
  const path = location?.pathname.split("/")[2];
  const ReadNotification  = (id) =>{
    console.log( id,"parthGoje")
  }
  console.log(location, "partttt");
  useEffect(() => {
    setView(path != "Notifications" ? "create_new" : "view_all");
    setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    setIsEdit(path == "EditNotifications");
  }, [location]);
  const tempTableData = {
    tableTitle: "Notification",
    deleteRecord: notification_delete,
    updateRecord: notification_update,
    readNotification :notification_read_status_update,
    openModal: handleOpen,
    disableDelete: role == "Advertiser" || role == "Producer",
    onDeleteText: "Are you sure want to delete?",
    deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onUpdateText: "Are you Sure?",
    tableHead: [
      role != "Advertiser" && role != "Producer" &&    {
        id: "notification_title",
        label: "Notification Title",
        // isSpecial: true,
        // align:"left"
        // color: viewColor,
      },
      (role === "Advertiser" || role === "Producer") &&     {
        id: "notification_title1",
        label: "Notification Title",
        isSpecial: true,
        align:"left"
        // color: viewColor,
      },
      role !== "Producer" &&
        role !== "Advertiser" && {
          id: "created_at",
          label: "Sent On",
        },
      (role == "Producer" || role == "Advertiser") && {
        id: "created_at",
        label: "Received On",
      },
      {
        id: "notification_image",
        label: "Image",
        isImage: true,
      },
      {
        id: "description",
        label: "Description",
        isModal: true,
        readNotification : true,
        n_id:"noti_id",
        default: (
          <>
            <button
              style={{
                padding: "5px 15px",
                color: "rgb(238, 127, 37)",
                background: "transparent",
                border: "1px solid rgb(238, 127, 37)",
                borderRadius: "5px",
              }}
              // value="noti_id"
              onClick={()=>ReadNotification("noti_id")}
            >
              VIEW
            </button>
          </>
        ),
      },
      role != "Advertiser" &&
        role != "Producer" && {
          id: "customers",
          label: "customers",
          isModal: true,
         
          default: (
            <>
              <button
                style={{
                  padding: "5px 15px",
                  color: "rgb(238, 127, 37)",
                  background: "transparent",
                  border: "1px solid rgb(238, 127, 37)",
                  borderRadius: "5px",
                }}
              >
                VIEW
              </button>
            </>
          ),
        },
    ].filter((e) => e),
    tableBody: [],
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "inputBox",
      size: "4",
      title: "Notification Title",
      name: "notification_title",
      required: true,
    },
    {
      id: "3",
      type: "select",
      title: "Select User Type",
      size: "4",
      name: "user_type",
      options: ["Advertiser", "Producer", "Customer"],
      required: true,
    },
    {
      id: "3",
      type: "select_multiple",
      title: "Select User",
      size: "4",
      name: "customers",
      options: [],
      required: true,
    },

    {
      id: "3",
      type: "inputBox",
      title: "Description",
      name: "description",
      maxLength: "100",
      required: true,
    },
    {
      id: "2",
      type: "image",
      title: "Notification Image",
      name: "notification_image",
      subtitle: "(Resolution : 910px x 1280px) *",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
    },
    {
      id: "8",
      type: "button",
      title: "Send",
    },
  ]);

  const notifications = useSelector(
    (state) => state?.merchandise?.notifications
  );
  const customers = useSelector((state) => state?.customers?.customers);
  const advertisers = useSelector((state) => state?.advertisers?.advertisers);
  const distributors = useSelector(
    (state) => state?.distributors?.distributors
  );
  useEffect(() => {
    dispatch(all_customer_list());
    dispatch(all_distributor_list());
    dispatch(all_advertiser_list());
  }, []);
  useMemo(() => {
    const temp = formStructure;
    if (location?.state?.send == "nofitication") {
      temp[1]["display"] = "none";
      temp[2]["display"] = "none";
    } else {
      temp[1]["display"] = "block";
      temp[2]["display"] = "block";
    }
    setFormStructure([...temp]);
  }, [location?.state?.send]);
  console.log(notifications, form, "0000");
  useMemo(() => {
    const temp = formStructure;
    if (form?.user_type) {
      const tempArray = ["All Users"];
      if (customers?.statuscode == 200 && form?.user_type == "Customer") {
        customers?.data.map((ele) =>
          tempArray.push(ele.firstName + " " + ele.lastName)
        );
      }
      if (advertisers?.statuscode == 200 && form?.user_type == "Advertiser") {
        advertisers?.data.map((ele) => tempArray.push(ele.name));
      }
      if (distributors?.statuscode == 200 && form?.user_type == "Producer") {
        distributors?.data.map((ele) => tempArray.push(ele.name));
      }
      temp[2]["options"] = tempArray;
    }
    setFormStructure([...temp]);
  }, [form?.user_type]);

  useEffect(() => {
    const data = new FormData();
    data.append("id", user?.id);
    dispatch(all_notification_list(data));
  }, [user]);
  useMemo(() => {
    if (notifications?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = notifications?.data.map((ele) => ({
        ...ele,
        notification_title1:  ele?.customers?.[0]?.["notification_read"] !== "false" ? <p style={{color:"#e4e4e4"}}>{ele?.notification_title}</p> : <p style={{color:"#fff" , fontWeight:"900"}}>{ele?.notification_title}</p>,
        noti_id : ele?.id,
        customers:
        ele?.user_type === "Customer" ? (
          ele?.customers.length !== customers?.data.length ? (
           
            ele?.customers?.map((customer) => (
              <>
                  <p style={{ color: "white" }}>
                    {customer.firstName + " " + customer.lastName}
                  </p>
                  <p style={{ color: "white" }}>{temp?.tableHead?.default}</p>
                </>
              ))
            ) : (
              <>
                <p style={{ color: "white" }}>{"All Customer"}</p>
                <p style={{ color: "white" }}>{temp?.tableHead?.default}</p>
              </>
            )
          ) : ele?.user_type === "Producer" ? (
            ele?.customers.length !== distributors?.data.length ? (
              ele?.customers?.map((producer) => (
                <>
                  <p style={{ color: "white" }}>
                    {producer.firstName + " " + producer.lastName}
                  </p>
                  <p style={{ color: "white" }}>{temp?.tableHead?.default}</p>
                </>
              ))
            ) : (
              <>
                <p style={{ color: "white" }}>{"All Producer"}</p>
                <p style={{ color: "white" }}>{temp?.tableHead?.default}</p>
              </>
            )
          ) : ele?.customers.length !== advertisers?.data.length ? (
            ele?.customers?.map((advertiser) => (
              <>
                <p style={{ color: "white" }}>
                  {advertiser.firstName + " " + advertiser.lastName}
                </p>
                <p style={{ color: "white" }}>{temp?.tableHead?.default}</p>
              </>
            ))
          ) : (
            <>
              <p style={{ color: "white" }}>{"All Advertisers"}</p>
              <p style={{ color: "white" }}>{temp?.tableHead?.default}</p>
            </>
          ),
        description: (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: ele?.description }}
              style={{ color: "white" }}
            />
            <p style={{ color: "white" }}>{temp?.tableHead?.default}</p>{" "}
          </>
        ),
        
      }));

      setTableData({ ...temp });
    }
    console.log(tableData, "00000000");
  }, [notifications , viewColor]);

  const formTitle = isEdit ? "Edit Notification" : "Create Notification";
  const notification_read = useSelector((state)=> state?.merchandise?.notification_read)
  console.log(notification_read,"mkovd")
  const message = useSelector((state) => state.layout.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    const tempForm = form;
    console.log(form, "kkkar");

    tempForm["tempcustomers"] = form?.["customers"];
    if (tempForm["user_type"] == undefined || "") {
      tempForm["customers"] = location.state.customer;
    } else {
      console.log("Notification ka code");
      if (tempForm["user_type"] == "Advertiser") {
        if (tempForm["customers"] == "All Users") {
          tempForm["customers"] = advertisers?.data
            .map((option) => option.id)
            .filter((e) => e);
        } else {
          tempForm["customers"] = advertisers?.data
            .map(
              (option) =>
                form?.["customers"]?.includes(option.name) && option.id
            )
            .filter((e) => e);
        }
      }
      if (tempForm["user_type"] == "Customer") {
        if (tempForm["customers"] == "All Users") {
          tempForm["customers"] = customers?.data
            .map((option) => option.id)
            .filter((e) => e);
        } else {
          tempForm["customers"] = customers?.data
            .map(
              (option) =>
                form?.["customers"]?.includes(
                  option.firstName + " " + option.lastName
                ) && option.id
            )
            .filter((e) => e);
        }
      }
      if (tempForm["user_type"] == "Producer") {
        if (tempForm["customers"] == "All Users") {
          tempForm["customers"] = distributors?.data
            .map((option) => option.id)
            .filter((e) => e);
        } else {
          tempForm["customers"] = distributors?.data
            .map(
              (option) =>
                form?.["customers"]?.includes(option.name) && option.id
            )
            .filter((e) => e);
        }
      }
    }

    setForm({
      ...tempForm,
    });
    console.log(form, "notifications user_type");
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });
    if (isEdit) {
      dispatch(notification_update(data));
    } else {
      dispatch(notification_create(data));
    }
  };
  useMemo(()=>{
    if(notification_read?.statuscode === 200){
      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);
        dispatch(all_notification_list(data));
        dispatch(notification_unread_count(data))
        navigate("/Notifications/Notifications", {
          state: { view: "view_all" },
        });
        setView("view_all");
      }, 1000);
    }
  },[notification_read])
  useMemo(() => {
    if (message?.statuscode == 200) {
      console.log("Notification ka code 1");
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });
      setIsEdit(false);
      setForm({});

      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);
        dispatch(all_notification_list(data));
        navigate("/Notifications/Notifications", {
          state: { view: "view_all" },
        });
        setView("view_all");
      }, 1000);
      setView("view_all");
    } else {
      const temp = form;
      temp["customers"] = form["tempcustomers"];
      temp["notification_image"] = form["tempnotification_image"];
      setForm({ ...temp });
    }
  }, [message]);
  console.log("Edit clicked", isEdit);
  return (
    <>
      <EnlargedView open={open} setOpen={setOpen} content={content} />
      {role != "Advertiser" && role != "Producer" && (
        <ViewChange
          setForm={setForm}
          setView={setView}
          setIsEdit={setIsEdit}
          view={view}
          isEdit={isEdit}
          access={rights?.["Masters"]?.["create"] == "true"}
          create_new={"/Notifications/CreateNotifications"}
          view_all={"/Notifications/Notifications"}
          form={form}
          reload={<Reload isClubed={true} />}
        />
      )}

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          setContent={setContent}
          create_new={"/Notifications/EditNotifications"}
        />
      )}
    </>
  );
}
