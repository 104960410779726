import { Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import ContentAdvisory from "../components/Masters/ContentAdvisory/ContentAdvisory";

function ContentAdvisoryModule() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/ContentAdvisory"
          element={<Layout children={ContentAdvisory()} />}
        />
        <Route
          path="/CreateContentAdvisory"
          element={<Layout children={ContentAdvisory()} />}
        />
        <Route
          path="/EditContentAdvisory"
          element={<Layout children={ContentAdvisory()} />}
        />
      </Routes>
    </div>
  );
}

export default ContentAdvisoryModule;
