import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import ProductDetails from "../components/ProductDetails/ProductDetailsContent"
import PageNotFound from "../components/PageNotFound/PageNotFound";
import Episode from '../components/WebSeries/Episode/Episode';

function EpisodeModule() {
  return (
    <div className="App">
      <Routes>
        <Route path="/Episode" element={<Layout children={Episode()} />} />
        <Route
          path="/CreateEpisode"
          element={<Layout children={Episode()} />}
        />
        <Route
          path="/EditEpisode"
          element={<Layout children={Episode()} />}
        />
        <Route
          path="/Episode/EpisodeDetails"
          element={<Layout children={ProductDetails()} />}
        />
        <Route path="*" element={<PageNotFound />} />

      </Routes>
    </div>
  );
}

export default EpisodeModule;
