import React from "react"
import { useSelector } from "react-redux"

export const TableData= () => {
  const rights=useSelector((state) => state.layout.rights)
  return{
    tableTitle:"Categories",
    onDeleteText:"Are you Sure?",
    deleteAccess:rights?.["Masters"]?.["delete"]=="true",
    tableHead:[
        {
            id: 'category_name',
            label: 'Category',
          },
          {
            id: 'category_image',
            label: 'Image',
            isImage:true
          },
          {
            id: 'sequence',
            label: 'Sequence',
            align:"center"
          },
          
          {
            id: 'edit',
            label: 'Update',
            access:rights?.["Masters"]?.["edit"]=="true"
          },
    ],
    tableBody:[
       
    ]}}