
import { DASHBOARDCOUNT,RENEWAL,DASHBOARDMOVIES,TOTALUSERS,DAILY_REVENUE,TOTALSUBSCRIBEUSER,MONTHLYREVENUE,MAX_LOGIN_DURATION,TOTALREVENUE,TV_SHOW_DASHBOARD,RECENT_SUBSCRIBER_DASHBOARD, ADVERTISEMENT_VIEW_GRAPH } from './../constants/actionTypes';
import * as api from './../api/index.js';
export const movies_data_dashboard = (formData) => async (dispatch) => {
    try {
      const { data } = await api.movies_data_dashboard(formData);
      
      dispatch({ type: DASHBOARDMOVIES, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const count_on_dashboard = (formData) => async (dispatch) => {
    try {
      const { data } = await api.count_on_dashboard(formData);
      
      dispatch({ type: DASHBOARDCOUNT, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const total_user = (formData) => async (dispatch) => {
    try {
      const { data } = await api.total_user(formData);
      
      dispatch({ type: TOTALUSERS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const total_subscribe_user = (formData) => async (dispatch) => {
    try {
      const { data } = await api.total_subscribe_user(formData);
      
      dispatch({ type: TOTALSUBSCRIBEUSER, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const monthly_revenue = (formData) => async (dispatch) => {
    try {
      const { data } = await api.monthly_revenue(formData);
      
      dispatch({ type: MONTHLYREVENUE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const total_revenue = (formData) => async (dispatch) => {
    try {
      const { data } = await api.total_revenue(formData);
      
      dispatch({ type: TOTALREVENUE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const advertisement_view_graph = (formData) => async (dispatch) => {
    try {
      const { data } = await api.advertisement_view_graph(formData);
  
      dispatch({ type: ADVERTISEMENT_VIEW_GRAPH, payload: data });
    } catch (error) {
      console.log(error);
    }
  };
  
  export const most_watch_tv_show_dashboard = (formData) => async (dispatch) => {
    try {
      const { data } = await api.most_watch_tv_show_dashboard(formData);
      
      dispatch({ type: TV_SHOW_DASHBOARD, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const recent_subscriber_dashboard = (formData) => async (dispatch) => {
    try {
      const { data } = await api.recent_subscriber_dashboard(formData);
      
      dispatch({ type: RECENT_SUBSCRIBER_DASHBOARD, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const daily_revenue = (formData) => async (dispatch) => {
    try {
      const { data } = await api.daily_revenue(formData);
      
      dispatch({ type: DAILY_REVENUE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const max_login_duration = (formData) => async (dispatch) => {
    try {
      const { data } = await api.max_login_duration(formData);
      
      dispatch({ type: MAX_LOGIN_DURATION, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const renewal = (formData) => async (dispatch) => {
    try {
      const { data } = await api.renewal(formData);
      
      dispatch({ type: RENEWAL, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };




  