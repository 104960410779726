

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Watchhours from '../components/Watchhours/Watchhours';
import Layout from '../components/Layout/Layout';

function WatchhoursModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/WatchHours' element={<Layout children={Watchhours()}/>}/>


      </Routes>
    </div>
  );
}

export default WatchhoursModule;