

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Coupon from '../components/Coupon/Coupon';
import CouponHistory from '../components/Coupon/CouponHistory';
function MerchandiseModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Coupon/*' element={<Layout children={Coupon()}/>}/>
< Route path='/CreateCoupon/*' element={<Layout children={Coupon()}/>}/>
< Route path='/EditCoupon/*' element={<Layout children={Coupon()}/>}/>

< Route path='/PromocodeHistory/*' element={<Layout children={CouponHistory()}/>}/>

      </Routes>
    </div>
  );
}

export default MerchandiseModule;
