

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Category from '../components/Masters/Category/Category';
import SubCategory from '../components/Masters/SubCategory/SubCategory';
import Language from '../components/Masters/Language/Language';
import Genre from '../components/Masters/Genre/Genre';
import Cast from '../components/Masters/Cast/Cast';
import Country from '../components/Masters/Country/Country';
import Layout from '../components/Layout/Layout';
// import Distributors from "../components/Masters/Distributors/Distributors"
import SubAdmin from "../components/Masters/SubAdmin/SubAdmin"
// import Subscriptions from '../components/Masters/Subscription/Subscriptions';
import Avatar from "../components/Masters/Avatar/Avatar"
import CategoryModule from './CategoryModule';
import SubCategoryModule from './SubCategoryModule';
import LanguageModule from './LanguageModule';
import CastModule from './CastModule';
import GenreModule from './GenreModule';
import AvatarModule from './AvatarModule';
import SubAdminModule from './SubAdminModule';
import ContentAdvisoryModule from './ContentAdvisoryModule';

function MastersModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Category/*' element={<CategoryModule key="Category"/>}/>
< Route path='/Avatar/*' element={<AvatarModule key="Avatar"/>}/>
< Route path='/SubCategory/*' element={<SubCategoryModule key="SubCategory"/>}/>
< Route path='/Country/*' element={<Layout children={Country()}/>}/>

< Route path='/Language/*' element={<LanguageModule key="Language"/>}/>
< Route path='/Genre/*' element={<GenreModule key="Genre"/>}/>
< Route path='/Cast/*' element={<CastModule key="Cast"/>}/>
<Route path='/ContentAdvisory/*' element={<ContentAdvisoryModule key="ContentAdvisory" />}/>
{/* < Route path='/Distributors' element={<Layout children={Distributors()}/>}/> */}
< Route path='/SubAdmin/*' element={<SubAdminModule key="SubAdmin"/>}/>
{/* < Route path='/Subscriptions' element={<Layout children={Subscriptions()}/>}/> */}
      </Routes>
    </div>
  );
}

export default MastersModule;
