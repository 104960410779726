

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import PPVCustomer from '../components/Report/PPVCustomer/PPVCustomer';
import RegisteredCustomer from '../components/Report/RegisteredCustomer/RegisteredCustomer';
import SubscribedCustomer from '../components/Report/SubscribedCustomer/SubscribedCustomer';
function ReportModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/PPVCustomer' element={<Layout children={PPVCustomer()}/>}/>

< Route path='/RegisteredCustomer' element={<Layout children={RegisteredCustomer()}/>}/>

< Route path='/SubscribedCustomer' element={<Layout children={SubscribedCustomer()}/>}/>
      </Routes>
    </div>
  );
}

export default ReportModule;
