import { useSelector } from "react-redux";

export const TableData = () => {
  const rights = useSelector((state) => state.layout.rights);
  const role = useSelector((state) => state.layout.role);
  return {
    tableTitle: "Ad Collections",
    // disableDelete: true,
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: "product_name",
        label: "product name",
        // subText:"distribution_name",
        // link:"/DistributorsFormData/DistributorsFormDetails/DistributorsFormDetails"
      },
      role !== "Advertiser" && {
        id: "Advertiser_name",
        label: "Advertiser Name",
        subText:"company_name",

      },
      {
        id: "thumbnail",
        label: "Image",
        isImage: true,
      },
      {
        id: "recharge_amount",
        label: "Amount",
        // subText:"category_type"
      },
      {
        id: "subscription_type",
        label: "subscription Type",
        // subText:"mobileNumber"
      },
      // {
      //     id: "views_required",
      //     label: "Views" ,
      // },
      // {
      //     id: "rent1",
      //     label: "Rental Amount" ,
      // },
      {
        id: "status",
        label: "Status",
        isButtonDisplay: true,
      },
      role !== "Advertiser" && {
        id: "notification_send",
        label: "Notification",
        isSpecial: true,
        align: "center",
      },
      {
        id: "edit",
        label: "Update",
        access: "true",
        ErrorMsg: "You cannot change the details once it has been approved.",
      },
    ].filter((e) => e),
    tableBody: [],
    filterColumn: [
      {
        id: "1",
        title: "Status",
        name: "status",
        options: ["Approved", "Rejected" , 	"Pending"],
      },
    ],
  };
};
