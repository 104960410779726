

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from '../components/Layout/Layout';
import AboutUs from '../components/Setting/AboutUs';
import RefundPolicy from '../components/Setting/RefundPolicy';
import PrivacyPolicy from '../components/Setting/PrivacyPolicy';
import TermsConditions from '../components/Setting/TermsConditions';
import AppSetting from '../components/Setting/AppSetting';
import UserLogs from '../components/Setting/UserLogs';
// import UserDetails from "./UserLogDetailsModule"
// import UserLogDetails from '../components/Setting/UserLogDetails';
// import UserLogDetailsModuls from './UserLogDetailsModule';
import UserLogModule from './UserLogModule';
function AdvertisementModule() {
  return (
    <div className="App">
    
<Routes>


< Route path='/AboutUs' element={<Layout children={AboutUs()}/>}/>
< Route path='/PrivacyPolicy' element={<Layout children={PrivacyPolicy()}/>}/>
< Route path='/TermsConditions' element={<Layout children={TermsConditions()}/>}/>
< Route path='/RefundPolicy' element={<Layout children={RefundPolicy()}/>}/>
{/* < Route path='/UserLogs' element={<Layout children={UserLogs()}/>}/> */}
<Route path='/UserLogs/*'element={<UserLogModule key="UserLogs"/>}/>
{/* < Route path='/UserLogDetails/*' element={<Layout children={UserLogDetails()}/>}/> */}


< Route path='/AppSetting' element={<Layout children={AppSetting()}/>}/>


      </Routes>
    </div>
  );
}

export default AdvertisementModule;