import React from "react";
import { useState, useEffect, useMemo } from "react";

import { Link, useLocation } from "react-router-dom";
import styles from "./../../styles/PageTitle.module.css";
import ListTable from "../utils/Table";
import { all_transaction_list } from './../../actions/transaction';
import { useDispatch, useSelector } from 'react-redux';
import Export from "./../utils/Export"
export default function Transaction() {
	const dispatch = useDispatch()
	const location = useLocation()
	const [isEdit, setIsEdit] = useState(false);
	const rights = useSelector((state) => state.layout.rights)
	const [form, setForm] = useState({});
	const [tableData, setTableData] = useState({
		tableTitle: "All Transactions",
		disableDelete: true,
		column_sum: {
			name: "payment_amount",
			title: "Total Amount"
		},
		tableHead: [
			// {
			// 	id: "name",
			// 	label: "Name",
			// 	link: "/Customer/CustomerDetail/CustomerDetail",
			// 	color: "var(--gradientColor2)"
			// },
			{
				id: "email",
				label: "Customer Info",
				subText: "mobile_no"
			},
			{
				id: "plan",
				label: "Plan",
				isSpecial: true,
				align: "left"
			},
			{
				id: "payment_amount",
				label: "Amount",
			},
			{
				id: "payment_id",
				label: "Payment ID",
				subText: "payment_method"
			},
			{
				id: "date",
				label: "Payment Date",
			},

			{
				id: "device_type",
				label: "Device Type",
			},
			{
				id: "location",
				label: "Location",
			},
			// {
			// 	id: "payment_status",
			// 	label: "Payment Status",
			// 	isButtonDisplay: true
			// },
		],
		tableBody: [

		],
		filterColumn: [
			{
				id: "1",
				title: "Plan",
				name: "transaction_type",
				options: ["TVOD", "SVOD"],
				default: location?.state,
			},
			// {
			// 	id: "1",
			// 	title: "Payment Status",
			// 	name: "payment_status",
			// 	options: ["Success", "Failed", "Expired"],
			// }
		],
		isDateRangeFilter: "date"
	});



	const transactions = useSelector((state) => state.transactions.transactions);
	useEffect(() => {
		dispatch(all_transaction_list())
	}, [])
	useMemo(() => {

		if (transactions?.statuscode == 200) {
			const temp = tableData
			temp.tableBody = transactions?.data.map((ele) => ({
				...ele, id: ele?.user?.id, payment_status: ele?.status, name: ele?.user?.firstName + " " + ele?.user?.lastName,payment_amount : parseFloat(ele?.payment_amount).toFixed(2)  ,

				plan: ele?.transaction_type == "SVOD" ? (
					<p>{ele?.plan?.plan_name}</p>
				) : (
					<><p >{ele?.transaction_type}</p>
						<span style={{color:"#dd5107"}}>({ele?.movie?.movie_name})</span></>
				),
			}))
			setTableData({ ...temp })
		}
	}, [transactions])
	console.log(transactions, "0111111")



	return (
		<>



			<Export fileName={"Transactions"} access={rights?.["Transactions"]?.["export_excel"] == "true"} exportData={tableData?.exportData || tableData?.tableBody} headings={tableData.tableHead.map((value) => value.label)} />
			<ListTable
				tableData={tableData}
				key={"ListTable"}
				setTableData={setTableData}
				setForm={setForm}
				setIsEdit={setIsEdit}
			/>

		</>
	);
}
