import React from "react";
import { useSelector } from "react-redux";
export const FormStructure = (isEdit) => {
  const role = useSelector((state) => state.layout.role);
  return [
    {
      id: "5",
      type: isEdit ? "inputBox" : "select",
      title: "Ownership",
      name: "series_ownership",
      options: ["In house", "Collaborator"],
      required: role != "Producer",
      disabled: isEdit ? true : false,
      display: role == "Producer" ? "none" : "block",
    },
    {
      id: "23",
      type: isEdit ? "inputBox" : "select",
      title: "Select Collaborator",
      name: "series_distributor",
      options: ["Now", "Later"],
      required: false,
      disabled: isEdit ? true : false,
      display: "none",
    },
    {
      id: "23",
      type: "inputBox",
      title: "Company Name",
      name: "company_name",
      disabled: true,
      display: "none",
    },
    {
      id: "1",
      type: "inputBox",
      title: "Series Name",
      name: "series_name",
      maxLength: "25",
      regex: /^[a-zA-Z0-9\s\&]+$/,
      isCapitalise: true,
      required: true,
    },
    // {
    // 	id: "2",
    // 	type: "select",
    // 	title: "Select Season",
    // 	name: "season",
    // 	options: ["Season 1", "Season 2", "Season 3", "Season 4"],
    // 	required: true,
    // },

    {
      id: "3",
      type: "select",
      title: "Language",
      name: "language",
      options: ["English", "Hindi", "Gujarati"],
      required: true,
    },
    {
      id: "4",
      type: "select_multiple",
      title: "Select Genre",
      name: "genre",
      maxSelections: "3",
      errorText: "You can select max 3 genre at a time",
      options: ["Portrait", "Landscape"],
      required: true,
    },
    {
      id: "3",
      type: "select",
      title: "Series Access",
      name: "series_type",
      options: ["FREE", "TVOD", "SVOD"],
      required: true,
    },
    {
      id: "10",
      type: "inputBox",
      title: "Rental Price",
      name: "rental_price",
      regex: /^[0-9\.]+$/,
      maxLength: "6",
      display: "none",
    },
    {
      id: "12",
      type: "inputBox",
      title: "Available For Days",
      name: "available_for_days",
      regex: /^[0-9\.]/,
      maxLength: "2",
      display: "none",
    },
    {
      id: "16",
      type: "inputBox",
      title: "Trailer URL ( .mp4 or .mkv )",
      endsWith: ".mp4",
      endsWith1: ".mkv",
      errorText: "File extension should be .mp4 or .mkv only",
      name: "series_trailer",
      required: true,
    },
    {
      id: "15",
      type: isEdit ? "lockedInput" : "inputBox",
      title: "Free Preview URL",
      endsWith: ".mp4",
      endsWith1: ".mkv",
      errorText: "File extension should be .mp4 or .mkv only",
      name: "free_preview",
      // required: true,
    },
    {
      id: "2",
      type: "toggle",
      title: "First Episode Free",
      name: "first_episode_free",
      size: "3",
      options: [
        { value: "Yes", color: "success" },
        { value: "No", color: "danger" },
      ],
      required: true,
    },
    // {
    // 	id: "21",
    // 	type: "toggle",
    // 	title: "Admin's Approval",
    // 	size: "3",
    // 	name: "admin_approval",
    // 	options: [{ value: "Approve", color: "success" }, { value: "Reject", color: "danger" }],
    // 	display: isEdit ? "block" : "none",
    // },
    // {
    // 	id: "24",
    // 	type: "inputBox",
    // 	title: "Admin's Remarks",
    // 	name: "admin_remarks",
    // 	display: "none"
    // },
    {
      id: "20",
      type: "toggle",
      title: "Status",
      display: "block",
      name: "status",
      default: "Active",
      display: isEdit ? "block" : "none",
      size: "3",
      options: [
        { value: "Active", color: "success" },
        { value: "Inactive", color: "danger" },
      ],
    },
    {
      id: "7",
      type: "description",
      title: "Description",
      name: "series_description",
      required: true,
    },
    {
      id: "5",
      type: "image",
      title: "Browse Thumbnail",
      subtitle: "(Resolution : 1920px x 720px)",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      name: "series_thumbnail",
      size: "3",

      required: true,
    },
    {
      id: "6",
      type: "image",
      title: "Browse Poster",
      subtitle: "(Resolution : 1920px x 1080px)",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      size: "3",
      name: "series_poster",
      required: true,
    },
    {
      id: "6",
      type: "image",
      title: "Series Scene",
      subtitle: "(Resolution : 1080px x 1080px)",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      size: "3",
      name: "series_scene",
      required: true,
    },
    {
      id: "6",
      type: "image",
      title: "Series logo",
      subtitle: "(Resolution : 1080px x 1080px)",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      size: "3",
      name: "series_logo",
      required: true,
    },
    {
      id: "7",
      type: "button",
      title: "Create",
    },
  ];
};
