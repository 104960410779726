

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Producers from '../components/Producers/Producers';
import Layout from '../components/Layout/Layout';
function ProducersModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Producers' element={<Layout children={Producers()}/>}/>
< Route path='/CreateProducers' element={<Layout children={Producers()}/>}/>
< Route path='/EditProducers' element={<Layout children={Producers()}/>}/>




      </Routes>
    </div>
  );
}

export default ProducersModule;