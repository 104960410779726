

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import SliderBanner from "../components/SliderBanner/SliderBanner"

function MerchandiseModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/SliderBanner' element={<Layout children={SliderBanner()}/>}/>
< Route path='/CreateSliderBanner' element={<Layout children={SliderBanner()}/>}/>
< Route path='/EditSliderBanner' element={<Layout children={SliderBanner()}/>}/>



      </Routes>
    </div>
  );
}

export default MerchandiseModule;
