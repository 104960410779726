import { CURRENTTIME } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const live_movie_view_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.live_movie_view_list(formData);
      dispatch({ type: CURRENTTIME, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };