import React, { useMemo, useState } from "react";

import styles from "./Profile.module.css";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import profile from "./../../images/photo.png"
const ProfileContent = ({data}) => {
  const [years, setYears] = useState(null);
  useMemo(()=>{
if (data?.birthDate){
  const currentDate = new Date();
  if (new Date(data?.birthDate) > currentDate) {
    setYears(null);
    return;
  }
  const diffTime = currentDate - new Date(data?.birthDate);
  const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  setYears(Math.floor(totalDays / 365.25));
}
  },[data])


  return (
    <>
      <div className={styles.profileBox}>
        

        <div className={styles.profileInfoContent}>
        <Card
						sx={{
							textAlign: "center",
							boxShadow: "var(--gradientColorLighter2) 0px 6px 12px -2px, var(--gradientColorLighter1) 0px 3px 7px -3px",
							borderRadius: "10px",
							p: "20px 15px",
							
						}}
					>
						<img
							src={profile}
							alt="Member"
							height={"150px"}
							className="borRadius100"
						/>
						<Typography
							as="h4"
							sx={{
								fontSize: 16,
								fontWeight: 500,
								mt: "10px",
							}}
						>
							{data?.firstName+" "+data?.lastName}
						</Typography>
            {/* <Typography
                sx={{
                  fontSize: 13,
                  color: '#A9A9C8',
                 
                }}
              >
              User Code : <b>{data?.userCode}</b>
              </Typography> */}
            <Typography
                sx={{
                  fontSize: 13,
                  color: '#A9A9C8',
                 
                }}
              >
                {data?.email}
              </Typography>
              <Typography
                sx={{
                  fontSize: 13,
                  color: '#A9A9C8',
                 
                }}
              >
              {data?.mobileNumber}
              </Typography>
              <Typography
                sx={{
                  fontSize: 13,
                  color: '#A9A9C8',
                 
                }}
              >
              Age : {years}
              </Typography>
             
					</Card>
          
        </div>
      </div>
    </>
  );
};

export default ProfileContent;
