// import React, { useState, useMemo } from "react";
// import LeftSidebar from "./LeftSidebar";
// import TopNavbar from "./TopNavbar";
// import BreadCrumb from "./BreadCrumb";
// import ScrollToTop from "./ScrollToTop";
// import { useSelector, useDispatch } from "react-redux";
// import Snackbar from "@mui/material/Snackbar";
// import Alert from "@mui/material/Alert";
// import Footer from "./Footer";
// import { useLocation, useNavigate } from "react-router-dom";
// import { MESSAGE } from "./../../constants/actionTypes";

// const Layout = ({ children }) => {
//   const role = useSelector((state) => state.layout.role);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   // const [active, setActive] = useState(role == "Advertiser");
//   // useMemo(() => {
//   //   setActive(role == "Advertiser");
//   // }, [role]);
//   const toogleActive = () => {
//     // setActive(!active);
//   };
//   const message = useSelector((state) => state.layout.message);
//   const [open, setOpen] = useState(false);
//   useMemo(() => {
//     if (message?.message != undefined) {
//       setOpen(true);
//     }
//     if (
//       location?.state?.forceShow &&
//       message?.message != "" &&
//       message?.message != undefined
//     ) {
//       setOpen(true);
//     }
//   }, [message?.message]);
//   const handleClose = () => {
//     setOpen(false);

//     dispatch({ type: MESSAGE, payload: undefined });
//   };
//   const isLoggedIn = useSelector((state) => state.layout.loggedin);
//   if (isLoggedIn == true)
//     return (
//       <>
//         <div className={`main-wrapper-content ${active && "active"}`}>
//           <>
//             <TopNavbar toogleActive={toogleActive} />
//             {<BreadCrumb />}
//             {
//               <LeftSidebar toogleActive={toogleActive} />
//           }
//           </>

//           <div
//             className="main-content"
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               minHeight: "88.4vh",
//               margin: "0",
//             }}
//           >
//             <div style={{ flex: "1" }}>{children}</div>

//             <Footer />
//           </div>
//         </div>

//         <ScrollToTop />

//         <Snackbar
//           anchorOrigin={{ vertical: "top", horizontal: "center" }}
//           open={open}
//           autoHideDuration={3000}
//           onClose={handleClose}
//         >
//           <Alert severity="info" variant="filled" color="success">
//             {message?.message}
//           </Alert>
//         </Snackbar>
//       </>
//     );
//   else navigate("/Authentication");
// };

// export default Layout;

// New code

import React, { useState, useMemo, useEffect } from "react";
import LeftSidebar from "./LeftSidebar";
import TopNavbar from "./TopNavbar";
import BreadCrumb from "./BreadCrumb";
import ScrollToTop from "./ScrollToTop";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { MESSAGE } from "./../../constants/actionTypes";
import { notification_unread_count } from "../../actions/notification";
import email from "../../images/email.png"

const Layout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const user = useSelector((state) => state.layout.profile);

  const toogleActive = () => {
    setActive(!active);
  };
  const message = useSelector((state) => state.layout.message);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  useMemo(() => {
    if (message?.message != undefined) {
      setOpen(true);
    }
    if (
      location?.state?.forceShow &&
      message?.message != "" &&
      message?.message != undefined
    ) {
      setOpen(true);
    }
  }, [message?.message]);
  useEffect(() => {
    const data = new FormData();
    data.append("id", user?.id);
    dispatch(notification_unread_count(data));
  }, [user]);
  const count = useSelector(
    (state) => state.merchandise?.notifications_count?.data
  );
  useEffect(() => {
    if (count >= 1) {
      setShow(true)
      console.log(true,"newTruue")
      setTimeout(() => {
        setShow(false)
      }, 6000);
    }
  }, [count]);

  const handleClose = () => {
    setOpen(false);

    dispatch({ type: MESSAGE, payload: undefined });
  };
  const isLoggedIn = useSelector((state) => state.layout.loggedin);
  if (isLoggedIn == true)
    return (
      <>
        <div className={`main-wrapper-content ${active && "active"}`}>
          <>
            <TopNavbar toogleActive={toogleActive} />
            <BreadCrumb />
            <LeftSidebar toogleActive={toogleActive} />
          </>

          <div
            className="main-content"
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "85vh",
              margin: "0",
            }}
          >
            <div style={{ flex: "1" }}>{children}</div>

            <Footer />
          </div>
        </div>
        <ScrollToTop />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert severity="info" variant="filled" color="success">
            {message?.message}
          </Alert>
        </Snackbar>
        {show && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
           <div style={{backgroundColor:"transparent" ,background:"linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%" , color:"#fff" , borderRadius:"5px" ,display:"flex" , alignItems:"center",justifyContent:"center",height:"50px"}}>
              <p style={{ paddingLeft:"10px" , paddingRight:"10px"}}><img src={email} alt="msg" height="22px" style={{marginRight:"10px"}}/> New Message Received</p>
              </div>
          </Snackbar>
        )}
      </>
    );
  else navigate("/Authentication");
};

export default Layout;
