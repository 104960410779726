// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Notifications_Notifications__qYHTP {\n  border: 1px solid #E2E8F0;\n  border-radius: 4px;\n  display: block;\n  color:black !important;\n  padding: 10px 20px 10px 20px;\n  font-weight: 500; \n  font-size: 14px;\n  position: relative;\n}\n.Notifications_num__ORy2n {\n  position: absolute;\n  left: 34px;\n  top: 3px;\n  color: #ffffff;\n  font-size: 8px;\n  line-height: 8px;\n  font-weight: 500;\n  background-color: #ff0000;\n  border-radius: 100%;\n  padding:  6px;\n}\n\n@media only screen and (max-width: 767px) {\n  .Notifications_Notifications__qYHTP {\n    display: none;\n  }\n}\n\n/* For dark mode */\n[class=\"dark\"] .Notifications_Notifications__qYHTP {\n  border: 1px solid #373a40;\n}", "",{"version":3,"sources":["webpack://./src/components/Layout/TopNavbar/Notifications.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,sBAAsB;EACtB,4BAA4B;EAC5B,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,cAAc;EACd,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA,kBAAkB;AAClB;EACE,yBAAyB;AAC3B","sourcesContent":[".Notifications {\n  border: 1px solid #E2E8F0;\n  border-radius: 4px;\n  display: block;\n  color:black !important;\n  padding: 10px 20px 10px 20px;\n  font-weight: 500; \n  font-size: 14px;\n  position: relative;\n}\n.num {\n  position: absolute;\n  left: 34px;\n  top: 3px;\n  color: #ffffff;\n  font-size: 8px;\n  line-height: 8px;\n  font-weight: 500;\n  background-color: #ff0000;\n  border-radius: 100%;\n  padding:  6px;\n}\n\n@media only screen and (max-width: 767px) {\n  .Notifications {\n    display: none;\n  }\n}\n\n/* For dark mode */\n[class=\"dark\"] .Notifications {\n  border: 1px solid #373a40;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Notifications": "Notifications_Notifications__qYHTP",
	"num": "Notifications_num__ORy2n"
};
export default ___CSS_LOADER_EXPORT___;
