import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import {
	coupon_create,
	coupon_delete,
	coupon_update,
	all_coupon_list,
} from "../../actions/coupon";
import { all_subscription_list } from "../../actions/subscription";
import { all_movie_list } from "../../actions/Movie/movie";
import { useDispatch, useSelector } from "react-redux";
import Export from "../utils/Export";
import { useLocation, useNavigate } from "react-router-dom";
import EnlargedView from "../utils/EnlargedView";
import { saveAs } from 'file-saver'
import Promocode_icon from "./../../images/Promocode_icon.png";
import { IMAGE } from "../../api";
import Reload from "../utils/Reload";
export default function Coupon() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.layout.profile)
	const rights = useSelector((state) => state.layout.rights)
	const location = useLocation();
	const navigate = useNavigate()
	const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
	const [view, setView] = useState(location?.state?.view || "view_all");
	const [isEdit, setIsEdit] = useState(false);
	const [open, setOpen] = useState(false);
	const [content, setContent] = useState();
	const handleOpen = () => setOpen(true);
	useMemo(() => {
		if (isEdit) {
			setView("create_new");
		} else {
			setView("view_all");
			setForm({});
		}
	}, [isEdit]);
	const path = location?.pathname.split("/")[2]
	useEffect(() => {
		setView(path != "Coupon" ? "create_new" : "view_all");
		setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
		setIsEdit(path == "EditCoupon");
	}, [location]);
	const tempTableData = {
		tableTitle: "Promocode",
		deleteRecord: coupon_delete,
		updateRecord: coupon_update,
		deleteAccess: rights?.["Coupon"]?.["delete"] == "true",
		onDeleteText: "Are you sure want to delete?",
		onUpdateText: "Are you Sure?",
		tableHead: [
			{
				id: "promocode",
				label: "Promo code",
				link: "/Coupon/PromocodeHistory",
				color: "#4267B2",
			},
			{
				id: "coupon_title",
				label: "Title",
			},

			{
				id: "select_type",
				label: "Promo code Type",
				subText: "type_subType",
			},
			{
				id: "discount_amount1",
				label: "Total Discount",
			},
			{
				id: "no_of_user_limit",
				label: "Limit",
			},
			{
				id: "coupon_used_count",
				label: "Used",
			},
			{
				id: "remaining_coupon",
				label: "Remaining",
			},
			{
				id: "valid_up_to",
				label: "Valid Up to",
				isSpecial: true,
				align: "left"
			},

			{
				id: "edit",
				label: "Update",
				access: rights?.["Coupon"]?.["edit"] == "true",
				ErrorMsg :"You can not edit expired PromoCode"
			},
			// {
			// 	id: "promocode_image",
			// 	label: "",
			// 	isSpecial: true,
			// },
		],
		tableBody: [
			{
				id: 0,
				promocode: "Movie",
				select_type: "Landscape",
				mobile_email: "Landscape",
				user_limit: "Landscape",
				used_count: "Landscape",
				remaining_count: "2",
				used_on: "Active",
				promocode_image: (
					<img
						src={Promocode_icon}
						onClick={() => {
							setContent(
								<a href={Promocode_icon} download>
									<img src={Promocode_icon} />
								</a>
							);
							handleOpen();
						}}
					/>
				),
				type_subType: "Bhola",
			},
		],
		// filterColumn: [
		// 	{
		// 		id: "2",
		// 		title: "Select Usage",
		// 		name: "movie_series",
		// 		options: ["Used Promocode", "Unused Promocode"],
		// 	},
		// ],
	}
	const [tableData, setTableData] = useState({ ...tempTableData });
	useMemo(() => {
		setTableData({ ...tempTableData })
	}, [rights])
	const [formStructure, setFormStructure] = useState([
		{
			id: "1",
			type: "inputBox",
			title: "Promocode Title",
			name: "coupon_title",
			maxLength: "20",
			regex: /^[a-zA-Z0-9\s\&]+$/,
			required: true
		},

		{
			id: "2",
			type: "inputBox",
			title: "Promocode",
			name: "promocode",
			maxLength: "15",
			regex: /^[a-zA-Z0-9]+$/,
			isCaps: true,
			required: true
		},
		{
			id: "3",
			type: "select",
			title: "Select Promotype",
			name: "select_type",
			options: ["Plan", "Movie"],
			required: true
		},
		{
			id: "4",
			type: "select",
			title: "Select Plan",
			name: "plan",
			display: "none",
			options: [
				"Monthly Plan",
				"Quaterly Plan",
				"Semiquaterly Plan",
				"Yearly Plan",
			],
			required: true
		},
		{
			id: "5",
			type: "inputBox",
			variant: "number",
			title: "Actual Amount (INR)",
			name: "actual_amount",
			disabled: true,
			required: true
		},
		{
			id: "5",
			type: "inputBox",
			variant: "number",
			title: "Tax",
			name: "tax",
			disabled: true,
			display: "none",
			required: true
		},
		{
			id: "5",
			type: "inputBox",
			variant: "number",
			title: "Streaming Fees",
			name: "streaming_fees",
			disabled: true,
			display: "none",
			required: true
		},
		{
			id: "5",
			type: "inputBox",
			variant: "number",
			title: "Amount received",
			name: "amount_received",
			disabled: true,
			display: "none",
			required: true
		},
		{
			id: "4",
			type: "select",
			title: "Discount type",
			name: "discount_type",
			options: ["Percentage", "Fixed"],
			required: true
		},
		{
			id: "6",
			type: "inputBox",
			title: "Discount Percentage",
			name: "discount_amount",
			maxLength: "2",
			regex: /^[0-9\.]+$/,
			required: true
		},
		{
			id: "7",
			type: "inputBox",
			variant: "number",
			title: "Payable Amount (INR)",
			name: "final_amount",
			disabled: true,
			required: true
		},
		{
			id: "8",
			type: "inputBox",
			variant: "date",
			title: "Expiry Date",
			min: new Date().toISOString().split('T')[0],
			name: "expiry_date",
			required: true
		},
		{
			id: "9",
			type: "inputBox",
			title: "No of user Limit",
			name: "no_of_user_limit",
			maxLength: "4",
			regex: /^[0-9]+$/,
			required: true
		},
		{
			id: "8",
			type: "button",
			title: isEdit ? "Edit" : "Create",
			
		},
	]);

	const coupons = useSelector((state) => state.merchandise.coupons);
	const subscriptions = useSelector(
		(state) => state.subscriptions.subscriptions
	);
	console.log(tableData,"subseerrerer")

	useEffect(() => {
		dispatch(all_subscription_list());
	}, []);
	useEffect(() => {
		dispatch(all_coupon_list());
	}, []);
	const movies = useSelector((state) => state.movies.movies);
	useEffect(() => {
		const data = new FormData()
		data.append('id', user?.id)
		dispatch(all_movie_list(data))
	}, [user]);
	useMemo(() => {
		if (coupons?.statuscode == 200) {
			const temp = tableData;
			temp.tableBody = coupons?.data.map((ele) => ({
				...ele,
				// edit: new Date(ele?.expiry_date) <= new Date() && ele?.no_of_user_limit - ele?.coupon_used_count <= 0,
				plan: ele?.plan?.plan_name,
				type_subType: ele?.plan ? ele?.plan?.plan_name : ele?.movie?.movie_name,
				movie: ele?.movie?.movie_name,
				actual_amount: ele?.movie?.rental_price || ele?.plan?.price,
				tax: ele?.movie?.tax,
				streaming_fees: ele?.movie?.streaming_fees,
				amount_received: ele?.movie?.amount_received,
				discount_amount1 : parseFloat(ele.discount_amount).toFixed(2),
				remaining_coupon: ele?.no_of_user_limit - ele?.coupon_used_count,

				promocode_image: (
					<img
						src={Promocode_icon}
						onClick={() => {
							setContent(
								// <a href={IMAGE+ele?.promocode_image} download="promocode.png">
								<img src={IMAGE + ele?.promocode_image} onClick={() => { saveAs(IMAGE + ele?.promocode_image, 'promocode.png') }} />
								// </a>
							);
							handleOpen();
						}}
					/>
				),
				valid_up_to:
					(new Date(ele?.expiry_date) > new Date() && ele?.no_of_user_limit - ele?.coupon_used_count > 0) ? (
						<p>{ele?.expiry_date}</p>
					) : (
						<p style={{ color: "red" }}>Expired</p>
					),
					// edit:new Date(ele?.expiry_date) <= new Date() ?  ele?.expiry_date : "",
					edit: (ele?.no_of_user_limit - ele?.coupon_used_count <= 0) || (new Date(ele?.expiry_date) <= new Date()) ?ele?.movie?.movie_name : ""
			}));
			setTableData({ ...temp });
		}
	}, [coupons]);

	const formTitle = isEdit ? "Edit PROMOCODE" : "Create PROMOCODE";

	useMemo(() => {
		if (form?.select_type == "Plan") {
			const temp = formStructure;
			temp[3]["display"] = "block";
			temp[3]["title"] = "Select Plan";
			temp[3]["name"] = "plan";
			temp[3]["options"] =
				(subscriptions?.statuscode == 200 &&
					subscriptions?.data.map((subscription) => subscription?.status?.toLowerCase() == "active" && subscription?.plan_name)) ||
				[];
			temp[5]["display"] = "none";
			temp[6]["display"] = "none";
			temp[7]["display"] = "none";
			setFormStructure([...temp]);
		} else if (form?.select_type == "Movie") {
			const temp = formStructure;
			temp[3]["display"] = "block";
			temp[3]["title"] = "Select TVOD Movie";
			temp[3]["name"] = "movie";
			temp[3]["options"] =
				movies?.statuscode == 200 &&
				movies?.data
					.map((movie) => movie?.movie_access == "TVOD" && movie?.movie_name)
					.filter((e) => e);

			temp[5]["display"] = "block";
			temp[6]["display"] = "block";
			temp[7]["display"] = "block";
			setFormStructure([...temp]);
		} else {
			const temp = formStructure;
			temp[3]["display"] = "none";
			temp[5]["display"] = "none";
			temp[6]["display"] = "none";
			temp[7]["display"] = "none";
			setFormStructure([...temp]);
		}
	}, [form?.select_type]);
	useMemo(() => {
		if (form?.discount_type == "Percentage") {
			const temp = formStructure;
			temp[9]["title"] = temp[9]["title"].replace("Amount", "Percentage");
			temp[9]["max"] = "99";
			setFormStructure([...temp]);
		} else {
			const temp = formStructure;
			temp[9]["title"] = temp[9]["title"].replace("Percentage", "Amount");

			setFormStructure([...temp]);
		}
	}, [form?.discount_type]);
	useMemo(() => {
		const ele = tableData.tableBody.find((ele) => ele.id == form.id)
		if (ele) {
			if (!(form?.movie == ele?.movie?.movie_name || form?.plan == ele?.plan?.plan_name)) {

				const temp = form
				temp.discount_amount = ""
				temp.discount_type = ""
				temp.final_amount = ""
				setForm({ ...temp })
			}

		}
		else {

			const temp = form
			temp.discount_amount = ""
			temp.discount_type = ""
			temp.final_amount = ""
			setForm({ ...temp })
		}


		if (form?.select_type == "Plan") {
			subscriptions?.statuscode == 200 &&
				subscriptions?.data.map(
					(subscription) => {
						if (subscription?.plan_name == form?.plan) {
							const tempForm = form
							tempForm["actual_amount"] = subscription?.country_price[0]?.price
							setForm({
								...tempForm
							})
						}
					}
				);
		} else if (form?.select_type == "Movie") {
			movies?.statuscode == 200 &&
				movies?.data.map(
					(movie) => {
						if (movie?.movie_name == form?.movie) {
							const tempForm = form
							tempForm["actual_amount"] = movie?.rental_price
							tempForm["tax"] = movie?.tax
							tempForm["streaming_fees"] = movie?.streaming_fees
							tempForm["amount_received"] = movie?.amount_received
							setForm({
								...tempForm
							})
							const temp = formStructure;
							temp[9]["max"] = movie?.amount_received;

							setFormStructure([...temp]);
							console.log("coupon", form, movie?.rental_price)
						}
					}
				);
		}
	}, [form?.plan, form?.movie]);
	// useMemo(()=>{


	// },[form?.actual_amount,form?.amount_received])
	useMemo(() => {
		if (form?.discount_type == "Percentage") {
			setForm({
				...form,
				final_amount: Math.round(
					(form?.select_type == "Plan" ? form?.actual_amount : form?.amount_received) - (form?.select_type == "Plan" ? form?.actual_amount : form?.amount_received) *
					(form?.discount_amount / 100)
				).toFixed(2),
			})
		}
		else {
			const tempFormStructure = formStructure
			tempFormStructure[9]['maxLength'] = 3
			setFormStructure([...tempFormStructure])
			setForm({
				...form,
				final_amount: Math.round(
					(form?.select_type == "Plan" ? form?.actual_amount : form?.amount_received) -
					form?.discount_amount
				).toFixed(2),
			})
		}
	}, [form?.discount_amount, form?.discount_type, form?.actual_amount, form?.amount_received
	]);
	useMemo(() => {
		if (form?.final_amount < 0)
			setForm({ ...form, discount_amount: 0 })
	}, [form?.final_amount])
	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const temForm = form;
		if (form?.select_type == "Plan") {
			temForm["plan"] = subscriptions?.data
				.map(
					(option) => form?.["plan"]?.includes(option.plan_name) && option.id
				)
				.filter((e) => e);
		} else if (form?.select_type == "Movie") {
			temForm["movie"] = movies?.data
				.map(
					(option) => form?.["movie"]?.includes(option.movie_name) && option.id
				)
				.filter((e) => e);
		}

		setForm({
			...temForm,
		});

		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		data.append("user", user?.id);
		formStructure.map((element) => {
			if (
				element.type == "image" &&
				form?.[element.name] &&
				typeof form?.[element.name] != "string"
			) {
				const temp = form;
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		const temp = tableData;
		if (isEdit) {

			dispatch(coupon_update(data));
		} else {

			dispatch(coupon_create(data));
		}

	};
	useMemo(() => {
		if (message?.statuscode == 200) {
			const temp = tableData;
			if (isEdit) {
				temp.tableBody.map(
					(value, index) =>
						value.id == form.id && (temp.tableBody[index] = { ...form })
				);

			} else {
				temp.tableBody[temp.tableBody.length] = {
					id: temp.tableBody.length,
					...form,
					edit: temp.tableBody.length,
				};

			}
			setTableData({ ...temp });
			setIsEdit(false);
			setForm({});
			setTimeout(() => {
				dispatch(all_coupon_list());
				navigate("/Coupon/Coupon", { state: { view: "view_all" } })
				setView("view_all");
			}, 1000);
		}
		else {

			setForm({ ...form });
		}
	}, [message])
	return (
		<>
			<EnlargedView open={open} setOpen={setOpen} content={content} />
			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
				access={rights?.["Coupon"]?.["create"] == "true"}
				create_new={"/Coupon/CreateCoupon"}
				view_all={"/Coupon/Coupon"}
				form={form}
				export_excel={
					<Export
						fileName={"Promocodes"}
						isClubed={true}
						exportData={tableData?.exportData || tableData?.tableBody}
						headings={tableData.tableHead.map((value) => value.label)}
						access={rights?.["Coupon"]?.["export_excel"] == "true"}
					/>
				}
				reload={<Reload isClubed={true} />}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					create_new={"/Coupon/EditCoupon"}
				/>
			)}
		</>
	);
}
