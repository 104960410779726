import React from "react";
import { useState, useMemo,useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import { subadmin_create,subadmin_delete,subadmin_update,all_subadmin_list } from '../../../actions/Masters/subadmin';
import { useDispatch,useSelector } from 'react-redux';
import RightsTable from "./RightsTable";
import { useLocation } from "react-router-dom";
export default function SubAdmin() {
	const user=useSelector((state) => state.layout.profile)
	const dispatch=useDispatch()
	const rights=useSelector((state) => state.layout.rights)
const location = useLocation();
const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
const [view, setView] = useState(location?.state?.view || "view_all");
	const [isEdit, setIsEdit] = useState(false);
	useMemo(() => {
		if(isEdit) 
     {setView("create_new") }
    else
    {setView("view_all")
    setForm({});
  }
	}, [isEdit]);
	const path = location?.pathname.split("/")[3]
	useEffect(() => {
		setView(path != "SubAdmin" ? "create_new" : "view_all");
		setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
		setIsEdit(path == "EditSubAdmin");
	}, [location]);
	const tempTableData={
		tableTitle: "Sub Admin",deleteRecord:subadmin_delete,
		updateRecord:subadmin_update,
		deleteAccess:rights?.["Sub Admin"]?.["delete"]=="true",
		onDeleteText:"Are you sure want to delete?",
		onUpdateText:"Are you Sure?",
		tableHead: [
			{
				id: "name",
				label: "Name",
			},
			
			{
				id: "email",
				label: "Email ID",
			},
			{
				id: "mobileNumber",
				label: "Mobile No",
			},
			{
				id: "created_at",
				label: "Created On",
			},
			{
				id: "edit",
				label: "Update",
				access:rights?.["Sub Admin"]?.["edit"]=="true"
			},
		],
		tableBody: [
			{
				id: 0,
				subadmin: "Movie",
				password: "Landscape",
				email_id: "Active",
				rights: "Active",
				edit: 0,
			},
		],
	}
	const [tableData, setTableData] = useState({...tempTableData});
	
	useMemo(()=>{
		setTableData({...tempTableData})
	},[rights])
	const formStructure = [
		{
			id: "1",
			type: "inputBox",
			title: "Name",
			name: "name",
			maxLength:30,
			regex : /^[a-zA-Z\s]+$/,
			required:true
		},
		

		{
			id: "3",
			type: "inputBox",
			variant:"email",
			title: "Email ID",
			name: "email",
			required:true
		}
		,
	// 	{
	// 	  id:"5",
	// 	  type:"password",
	// 	  title:"Password",
	// 	  name:"password",
	// 	  maxLength:10
	//   },

		// {
		// 	id: "4",
		// 	type: "select_multiple",
		// 	title: "Add Rights",
		// 	name: "rights",
		// 	options: ["Add", "Edit"],
		// },
		{
			id: "5",
			type: "phone",
			title: "Mobile No",
			name: "mobileNumber",
			required:true
		},
		{
			id: "5",
			type: "specialElement",
			element:RightsTable({form,setForm})
		},

		{
			id: "8",
			type: "button",
			title: isEdit ? "Edit" : "Create",
		},
	];

	
    const subadmins = useSelector((state) => state.masters.subadmins);
    useEffect(()=>{
      dispatch(all_subadmin_list())
    },[])
    useMemo(()=>{
      
      if(subadmins?.statuscode==200){const temp=tableData
      temp.tableBody=subadmins?.data.map((ele)=>({...ele,name:ele?.firstName}))
      setTableData({...temp})}
    },[subadmins])

	const formTitle = isEdit ? "Edit Sub Admin" : "Create Sub Admin";
	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		const tempForm=form
		tempForm["username"]=form?.mobileNumber.slice(form?.countryCode.length-1)
      tempForm["mobileNumber"]=form?.mobileNumber.slice(form?.countryCode.length-1)
	  tempForm["rights"]=JSON.stringify(form?.rights)
      setForm({...tempForm})
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		data.append("user", user?.id);
		formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof(form?.[element.name])!="string") {
				const temp = form;
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		if (isEdit)
			{
		dispatch(subadmin_update(data))
		}
		else
		{	
			dispatch(subadmin_create(data))
		}
		
	};
	useMemo(()=>{
		if(message?.statuscode==200)
		{
			const temp = tableData;
		if (isEdit) {
			temp.tableBody.map(
				(value, index) =>
					value.id == form.id && (temp.tableBody[index] = { ...form })
			);
		
		} else {
			temp.tableBody[temp.tableBody.length] = {
				id: temp.tableBody.length,
				...form,
				edit: temp.tableBody.length,
			};
			
		}
			setTableData({ ...temp });
		setIsEdit(false);
		setForm({});
		setTimeout(() => {
			dispatch(all_subadmin_list());
		}, 1000);
		setView("view_all");}
		else{
			
		setForm({...form });
		}
	},[message])
	return (
		<>
			

			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
				access={rights?.["Sub Admin"]?.["create"]=="true"}
				create_new={"/Masters/SubAdmin/CreateSubAdmin"}
				view_all={"/Masters/SubAdmin/SubAdmin"}
				form={form}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					create_new={"/Masters/SubAdmin/EditSubAdmin"}
				/>
			)}
		</>
	);
}
