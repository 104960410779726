import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import {
	cast_create,
	cast_delete,
	cast_update,
	all_cast_list,
} from "../../../actions/Masters/cast";
import { useDispatch, useSelector } from "react-redux";
import Import from "../../utils/Import";
import { useLocation ,useNavigate } from "react-router-dom";
import Export from "../../utils/Export";
import Reload from "../../utils/Reload";
export default function Cast() {
	const user = useSelector((state) => state.layout.profile)
	const dispatch = useDispatch();
	const rights = useSelector((state) => state.layout.rights)
	const location = useLocation();
	const navigate = useNavigate()
	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
	const [view, setView] = useState(location?.state?.view || "view_all");
	useMemo(() => {
		if (isEdit) {
			setView("create_new");
		} else {
			setView("view_all");
			setForm({});
		}
	}, [isEdit]);
	const path = location?.pathname.split("/")[3]
	useEffect(() => {
		setView(path != "Cast" ? "create_new" : "view_all");
		setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
		setIsEdit(path == "EditCast");
	}, [location]);
	const tempTableData = {
		tableTitle: "Casts",
		deleteRecord: cast_delete,
		updateRecord: cast_update,
		onDeleteText: "Are you sure to delete?",
		deleteAccess: rights?.["Masters"]?.["delete"] == "true",
		onUpdateText: "Are you Sure?",
		tableHead: [
			{
				id: "cast_name",
				numeric: false,
				disablePadding: true,
				label: "Cast",
			},
			{
				id: "cast_image",
				label: "Image",
				isImage: true,
			},
			{
				id: "cast_type",
				label: "Cast Type",
				isButtonDisplay: true,
			},
			{
				id: "edit",
				label: "Update",
				access: rights?.["Masters"]?.["edit"] == "true",
			},
		],
		tableBody: [
			{
				id: 0,
				cast: "Movie",
				image: "Landscape",
				cast_type: "Active",
				edit: 0,
			},
		],
		filterColumn: [
			{
				id: "1",
				title: "Select Cast Type",
				name: "cast_type",
				options: ["Actor", "Actress", "Director", "Producer"],
			},
		],
	}
	const [tableData, setTableData] = useState({ ...tempTableData });
	useMemo(() => {
		setTableData({ ...tempTableData })
	}, [rights])
	const formStructure = [
		{
			id: "1",
			type: "inputBox",
			title: "Cast Name",
			name: "cast_name",
			required: true,
		},
		{
			id: "2",
			type: "select",
			title: "Cast Type",
			name: "cast_type",
			options: ["Actor", "Actress", "Director", "Producer"],
			required: true,
		},

		{
			id: "3",
			type: "image",
			title: "Image",
			subtitle: "(Resolution : 512px x 512px) *",
			subsubtitle: "Max File Size 1MB",
			subsubsubtitle: "Support only JPG,PNG,JPEG",
			name: "cast_image",
		},

		{
			id: "5",
			type: "button",
			title: isEdit ? "Edit" : "Create",
		},
	];

	const casts = useSelector((state) => state.masters.casts);
	useEffect(() => {
		dispatch(all_cast_list());
	}, []);
	useMemo(() => {
		if (casts?.statuscode == 200) {
			const temp = tableData;
			temp.tableBody = casts?.data;
			setTableData({ ...temp });
		}
	}, [casts]);

	const formTitle = isEdit ? "Edit Cast" : "Create Cast";
	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		data.append("user", user?.id);
		formStructure.map((element) => {
			if (
				element.type == "image" &&
				form?.[element.name] &&
				typeof form?.[element.name] != "string"
			) {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		if (isEdit) {
			dispatch(cast_update(data));
		} else {
			dispatch(cast_create(data));
		}
	};
	useMemo(() => {
		if (message?.statuscode == 200) {
			const temp = tableData;
			if (isEdit) {
				temp.tableBody.map(
					(value, index) =>
						value.id == form.id && (temp.tableBody[index] = { ...form })
				);
			} else {
				temp.tableBody[temp.tableBody.length] = {
					id: temp.tableBody.length,
					...form,
					edit: temp.tableBody.length,
				};
			}
			setTableData({ ...temp });
			setIsEdit(false);
			setForm({});
			setTimeout(() => {
				dispatch(all_cast_list());
				navigate("/Masters/Cast/Cast/", { state: { view: "view_all" } })
				setView("view_all");
			}, 1000);
		} else {
			setForm({ ...form, cast_image: form?.tempcast_image });
		}
	}, [message]);
	return (
		<>
			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				access={rights?.["Masters"]?.["create"] == "true"}
				view={view}
				isEdit={isEdit}
				create_new={"/Masters/Cast/CreateCast"}
				view_all={"/Masters/Cast/Cast"}
				form={form}
				reload={<Reload isClubed={true} />}
				import_excel={<Import isClubed={true} access={rights?.["Masters"]?.["import_excel"] == "true"} />}
				export_excel={
					<Export
						fileName={"Casts"}
						isClubed={true}
						exportData={tableData?.exportData || tableData?.tableBody}
						access={rights?.["Masters"]?.["export_excel"] == "true"}
						headings={tableData.tableHead.map((value) => value.label)}
					/>
				}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					create_new={"/Masters/Cast/EditCast"}

				/>
			)}
		</>
	);
}
