
import * as api from '../api/index.js';
import {MESSAGE, ADFORMDETAILS } from '../constants/actionTypes.js';


  export const advertise_form_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.advertise_form_create(formData);
      
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const all_advertise_form_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_advertise_form_list(formData);
      
      dispatch({ type: ADFORMDETAILS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const advertise_form_upate = (formData) => async (dispatch) => {
    try{
        const {data} = await api.advertise_form_upate(formData);
        dispatch({type:MESSAGE , payload:data})
    } catch(error){
        console.log(error)
    }
  }
  export const advertise_form_delete = (formData) => async (dispatch) => {
    try{
        const {data} = await api.advertise_form_delete(formData);
        dispatch({type:MESSAGE , payload:data})
    } catch(error){
        console.log(error)
    }
  }
  
  
  
  