import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import poster from "./../../images/poster.jpg";
import "./ProductDetailsContent.css";
import Features from "./Features";
import ProductProfile from "./ProductProfile";
import { movie_details } from "./../../actions/Movie/movie"
import { episode_details } from "./../../actions/WebSeries/episode"
import { useEffect, useMemo } from "react";
import { Link, useLocation ,useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE } from "./../../api/index";
import WatchedCustomers from './WatchedCustomers'
import ListTable from "./../utils/Table";
import EnlargedView from "./../utils/EnlargedView";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Button, TextField } from "@mui/material";

import { producer_movie_form_update } from "../../actions/producer";
const ProductDetailsContent = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const role = useSelector((state) => state.layout.role)
	const path = location.pathname.split('/').filter((x) => x)[1];
	const rights = useSelector((state) => state.layout.rights);
	const [contentAccess, setContentAccess] = useState();
	const [openAccess, setOpenAccess] = useState(false);

	const formData = new FormData()
	const navigate = useNavigate()
	formData.append("id", location.state?.id)
	useMemo(() => {
		if (path == "MovieDetails")
			dispatch(movie_details(formData))
		else
			dispatch(episode_details(formData))
	}, [location.state?.id])
	const data = useSelector((state) => path == "MovieDetails" ? state.movies.movie?.Data : state.webseries?.episode?.Data)
	const cast = useSelector((state) => path == "MovieDetails" ? state.movies.movie?.Cast : state.webseries?.episode?.Data?.series_cast);
	const distributor = useSelector((state) => path == "MovieDetails" ? state.movies.movie?.Distributor : state.webseries?.episode?.Data?.series_name?.series_distributor);
	// console.log(distributor, "dissss")
	const dataMovie = useSelector((state)=> state.movies.movie?.Data )
	const dataEpisode = useSelector((state)=> state.webseries?.episode?.Data )

	const advertise_list = useSelector((state) => path == "MovieDetails" ? state.movies.movie?.Advertise_list : state.webseries?.episode?.Advertise_list);
	const Subtitle_list = useSelector((state) => path == "MovieDetails" ? state.movies.movie?.subtitle_list :state.webseries?.episode?.subtitle);
	const Audio_list = useSelector((state) => path == "MovieDetails" ? state.movies.movie?.audio_file_list : state.webseries?.episode?.audio)
	const SubtitleCount = useSelector((state) => path == "MovieDetails" ? state.movies.movie?.subtitle_count : state.webseries?.episode?.subtitle_count)
	const AudioCount = useSelector((state) => path == "MovieDetails" ? state?.movies.movie?.audio_file_count :state.webseries?.episode?.audio_file_count)
	// console.log(SubtitleCount,Subtitle_list, "count")
	const watch_hours = useSelector((state) => path == "MovieDetails" ? state.movies.movie?.watch_hours : state.webseries?.episode?.watch_hours);
	const earning = useSelector((state) =>path == "MovieDetails" ? state.movies.movie : state.webseries?.episode)

	const access = path == "MovieDetails" ? data?.movie_access : data?.series_name?.series_type

	const handleEdit = () =>{
		if(path == "MovieDetails") { 
			if( rights?.["Movie"]?.["edit"] == "true"){

				const movieSubtitle = [];
				const subtitle = {};
				Subtitle_list &&
				Subtitle_list?.map((ele, index) => {
					  // console.log(ele?.subtitle_file, ele?.subtitle_language, "idd")
					  subtitle["subtitle_language_" + index] = ele?.subtitle_language;
					  subtitle["subtitle_file_" + index] = ele?.subtitle_file;
					  subtitle["subtitle_id_" + index] = ele?.id;
					//   movieSubtitle.push(subtitle);
					});
				
		  
				const movieAudio = [];
			
				  // console.log(value, "value")
				  const Audio = {};
				  Audio_list &&
				  Audio_list?.map((ele, index) => {
					  // console.log(index, "idd")
					  Audio["audio_language_" + index] = ele?.audio_language;
					  Audio["audio_file_" + index] = ele?.audio_file;
					  Audio["audio_id_" + index] = ele?.id;
					  // console.log(index, ele?.id, "12301230")
					//   movieAudio.push(Audio);
					});
				navigate("/Movie/EditMovie/", {
					state: {
					  view: "create_new",
					  form: JSON.stringify({ ...dataMovie , subtitle_list : Subtitle_list , movie_suggestion:dataMovie?.movie_suggestion?.movie_name, audio_file_list : Audio_list, ...Audio,...subtitle, movie_genre: dataMovie?.movie_genre.map((genre) => genre.genre_title),
						movie_cast: dataMovie.movie_cast.map((cast) => cast.cast_name),
						content_advisory: dataMovie.content_advisory.map(
						  (ele) => ele.content_advisory
						),
						uploaded_by: dataMovie?.created_by?.firstName,
						movie_distributor: dataMovie?.movie_distributor?.name,
						company_name: dataMovie?.movie_distributor?.company_name, }),
					},
				  });
			}else{
				setContentAccess(
					<p style={{ color: "#fff" }}>
					
						You do not have permission to Edit data
					</p>
				  );
				  setOpenAccess(true);
			}// console.log(value, "value")
		}else{
			if( rights?.["TV Shows"]?.["edit"] == "true"){

				
				const subtitle = {};
				Subtitle_list &&
				Subtitle_list?.map((ele, index) => {
					  // console.log(ele?.subtitle_file, ele?.subtitle_language, "idd")
					  subtitle["subtitle_language_" + index] = ele?.subtitle_language;
					  subtitle["subtitle_file_" + index] = ele?.subtitle_file;
					  subtitle["subtitle_id_" + index] = ele?.id;
					//   movieSubtitle.push(subtitle);
					});
				
		  
			
				  // console.log(value, "value")
				  const Audio = {};
				  Audio_list &&
				  Audio_list?.map((ele, index) => {
					  // console.log(index, "idd")
					  Audio["audio_language_" + index] = ele?.audio_language;
					  Audio["audio_file_" + index] = ele?.audio_file;
					  Audio["audio_id_" + index] = ele?.id;
					  // console.log(index, ele?.id, "12301230")
					//   movieAudio.push(Audio);
					});
				navigate("/Episode/EditEpisode/", {
					state: {
					  view: "create_new",
					  form: JSON.stringify({ ...dataEpisode , subtitle_list : Subtitle_list , audio_file_list : Audio_list, ...Audio,...subtitle,
						uploaded_by: dataEpisode?.series_name?.created_by?.firstName,
						series_ownership: dataEpisode?.series_name?.series_ownership,
						series_name: dataEpisode?.series_name?.series_name,
						series_cast: dataEpisode?.series_cast.map((cast) => cast.cast_name),
						season: dataEpisode?.season_name, }),
					},
				  });
			}else{
				setContentAccess(
					<p style={{ color: "#fff" }}>
					
						You do not have permission to Edit data
					</p>
				  );
				  setOpenAccess(true);
			}
		}
	}

	// console.log(advertise_list,"lasee")
	return (
		<>
			<Grid
				container
				rowSpacing={1}
				justifyContent="center"
				columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
			>
				      <EnlargedView
        open={openAccess}
        setOpen={setOpenAccess}
        content={contentAccess}
      />
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={"sdcsdc"}>
					<ProductProfile data={data} path={path} />

				</Grid>
				<Grid item spacing={4} xs={12} sm={12} md={6} lg={6} xl={6}>

					<Card
						sx={{
							boxShadow: "none",
							borderRadius: "10px",
							p: "15px 25px",
							mb: "15px",
						}}
					>
						<Box mt={2}>
							<Tabs className="product-details-tabs">
								<TabList>
									<Tab>{path == "MovieDetails" ? data?.movie_category : "Episode"}</Tab>
									<Tab>Subtitle </Tab>
									<Tab>Cast </Tab>
									{data?.movie_ownership == "Producer" && <Tab>Producer</Tab>}
									{advertise_list && <Tab>Advertisement</Tab>}
								</TabList>

								<TabPanel>
									<Card
										sx={{
											boxShadow: "none",
											borderRadius: "10px",
											p: "25px 20px",
											mb: "10px",
											backgroundColor: "#F7FAFF",

										}}
									>
										<Typography
											as="h3"
											sx={{
												fontSize: 16,
												mb: "15px",
												fontWeight: 500,
												mt: "-25px",
												ml: "-25px",
												mr: "-25px",
												padding: "10px 25px",
												borderRadius: "8px 8px 0px 0px",
												background:
													"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

												color: "black",
											}}
										>
											<span style={{color:"#fff"}}>{path == "MovieDetails" ? data?.movie_category : "Episode"} Details</span>
										</Typography>

										<Box>
											
											{path == "MovieDetails" && <Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Sub Category:
												</span>
												{data?.movie_subcategory}
											</Typography>}

											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Access:
												</span>
												{path == "MovieDetails" ? data?.movie_access : data?.series_name?.series_type}
											</Typography>

											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Language:
												</span>
												{path == "MovieDetails" ? data?.movie_language : data?.series_name?.language?.language_name}
											</Typography>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Duration:
												</span>
												{path == "MovieDetails" ? data?.movie_duration : data?.episode_duration}
											</Typography>

											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Release Date:
												</span>
												{data?.release_date}
											</Typography>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Watch Hours:
												</span>
												{watch_hours}
											</Typography>
										</Box>
									</Card>
								</TabPanel>
								<TabPanel>
									<Card
										sx={{
											boxShadow: "none",
											borderRadius: "10px",
											p: "25px 20px",
											mb: "10px",
											backgroundColor: "#F7FAFF",

										}}
									>
										<Typography
											as="h3"
											sx={{
												fontSize: 16,
												fontWeight: 500,
												mb: "15px",
												mt: "-25px",
												ml: "-25px",
												mr: "-25px",
												padding: "10px 25px",
												borderRadius: "8px 8px 0px 0px",
												background:
													"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

												color: "black",
											}}
										>
											<span style={{color:"#fff"}}>Subtitles & Downloads</span>
										</Typography>

										<Box>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Subtitles:
												</span>
												{data?.subtitles}
											</Typography>

											{path == "MovieDetails" && <Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													position:
												</span>
												{data?.set_position}
											</Typography>}

											{(access == "TVOD") && <Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Price Per View (Rent):
												</span>
												{data?.rental_price || data?.series_name?.rental_price} USD
											</Typography>}
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Is This Video 18+:
												</span>
												{path== "MovieDetails" ?data?.movie_is_for18Plus : data?.episode_is_for18Plus}
											</Typography>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Allow Download:
												</span>
												{data?.allow_download}
											</Typography>
											{access == "TVOD" && <Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Available For:
												</span>
												{data?.available_for_days} days
											</Typography>}
										</Box>
									</Card>
								</TabPanel>
								<TabPanel>
									<Card
										sx={{
											boxShadow: "none",
											borderRadius: "10px",
											p: "25px 20px",
											mb: "10px",
											backgroundColor: "#F7FAFF",

										}}
									>
										<Typography
											as="h3"
											sx={{
												fontSize: 16,
												fontWeight: 500,
												mb: "15px",
												mt: "-25px",
												ml: "-25px",
												mr: "-25px",
												padding: "10px 25px",
												borderRadius: "8px 8px 0px 0px",
												background:
													"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

												color: "black",
											}}
										>
											<span style={{color:"#fff"}}>Cast</span>
										</Typography>

										<Box>
											<Card
												sx={{
													fontSize: 13,
													mt: "10px",
													background: "none",
													display: "flex",
													boxShadow: 'none',
													color: "black",

												}}
											>
												{cast?.map((ele) => (

													<Card
														sx={{
															fontSize: 13,
															mt: "10px",
															background: "none",
															display: "flex",
															boxShadow: 'none',
															color: "black",
															flexDirection: "column",
															textAlign: "center",
															width: "fit-content",
															ml: "15px"
														}}
													>

														<p style={{color:"red"}}>{ele?.cast_name}</p>
														<p style={{color:"red"}}>{ele?.cast_type}</p>
														<img src={IMAGE + ele?.cast_image} width="100px" style={{ borderRadius: "10px" }} />

													</Card>


												))}</Card>

										</Box>
									</Card>
								</TabPanel>
								{data?.movie_ownership == "Producer" && <TabPanel>
									<Card
										sx={{
											boxShadow: "none",
											borderRadius: "10px",
											p: "25px 20px",
											mb: "10px",
											backgroundColor: "#F7FAFF",

										}}
									>
										<Typography
											as="h3"
											sx={{
												fontSize: 16,
												fontWeight: 500,
												mb: "15px",
												mt: "-25px",
												ml: "-25px",
												mr: "-25px",
												padding: "10px 25px",
												borderRadius: "8px 8px 0px 0px",
												background:
													"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

												color: "black",
											}}
										>
											Producer Details
										</Typography>

										<Box>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Producer name:
												</span>
												{distributor?.name}
											</Typography>

											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Company Name:
												</span>
												{distributor?.company_name}
											</Typography>

											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Email ID:
												</span>
												{distributor?.email}
											</Typography>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Modile:
												</span>
												{distributor?.mobileNumber}
											</Typography>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Commission:
												</span>
												{distributor?.commission}
											</Typography>
										</Box>
									</Card>
								</TabPanel>}
								<TabPanel>
									<Card
										sx={{
											boxShadow: "none",
											borderRadius: "10px",
											p: "25px 20px",
											mb: "10px",
											backgroundColor: "#F7FAFF",

										}}
									>
										<Typography
											as="h3"
											sx={{
												fontSize: 16,
												fontWeight: 500,
												mb: "15px",
												mt: "-25px",
												ml: "-25px",
												mr: "-25px",
												padding: "10px 25px",
												borderRadius: "8px 8px 0px 0px",
												background:
													"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

												color: "black",
											}}
										>
											<span style={{color:"#fff"}}>Advertisement Details</span>
										</Typography>

										{advertise_list?.length > 0 ? advertise_list.map((advertise) => <Box sx={{

											display: "flex", justifyContent: "space-between"
										}}>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														// width: "100px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													
												</span>
												{advertise?.advertise?.product_name}
											</Typography>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
													justifyContent: "flex-end"
												}}
											>
												<span
													style={{
														// width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Display Duration:
												</span>
												{advertise?.time}
											</Typography>

											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
													justifyContent: "flex-end"
												}}
											>
												<span
													style={{
														// width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Views:
												</span>
												{advertise?.advertise?.no_of_views}
											</Typography>
										</Box>) :
											<p style={{ color: "red" }}>No Advertisement is added to this {path == "MovieDetails" ? data?.movie_category : "Episode"}</p>}
									</Card>
								</TabPanel>
							</Tabs>
						</Box>
					</Card>
				 </Grid>
				 <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={"sdcsdc"}>
				 <Features earning={earning} path={path} />

				 </Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>{data?.movie_access == "TVOD" && path == "MovieDetails" && <p style={{textAlign:"center",color:"#fff"}}>Above mentioned amount is after deduction of <b>{earning?.tax_percentage}(%)</b> Entertainment Tax.
Tax Amount is : <b>{earning?.tax_amount} USD </b></p>}</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>	{role != "Producer" && <WatchedCustomers id={location.state?.id} path={path} />}</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
					<Card sx={{
						boxShadow: "none",
						borderRadius: "10px",
						p: "15px 25px",
						mb: "15px",
					}} >
						<Box>

							<Card sx={{
								boxShadow: "none",
								borderRadius: "10px",
								p: "25px 20px",
								mb: "10px",
								backgroundColor: "#F7FAFF",

							}}>
								<Typography
									as="h3"
									sx={{
										fontSize: 16,
										mb: "15px",
										fontWeight: 500,
										mt: "-25px",
										ml: "-25px",
										mr: "-25px",
										padding: "10px 25px",
										borderRadius: "8px 8px 0px 0px",
										background:
											"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

										color: "black",
									}}
								>
									<span style={{color:"#fff"}}>Subtitle List</span>
								</Typography>
								<Table>
									<TableHead>
										<TableRow style={{ fontWeight: 900, fontSize: "25px" }}>
											<TableCell style={{ fontWeight: 900, fontSize: "17px" }}>Language</TableCell>
											<TableCell style={{ fontWeight: 900, fontSize: "17px" }}>File</TableCell>
										</TableRow>
									</TableHead>
									{SubtitleCount !== 0 ?
										<TableBody>
											{Subtitle_list?.map((sub) => (

												<TableRow>
													<TableCell>
														{sub?.subtitle_language}
													</TableCell>
													<TableCell>
														<a href={IMAGE + sub?.subtitle_file} target="_blank" download="new-file" style={{color:"#ff87ef"}}>{sub?.subtitle_file.split("/")[4]}</a>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
										: <TableBody align="center">

											No subtitle is available for this Movie
										</TableBody>}

								</Table>

							</Card>


						</Box>

					</Card>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
					<Card sx={{
						boxShadow: "none",
						borderRadius: "10px",
						p: "15px 25px",
						mb: "15px",
					}} >
						<Box>

							<Card sx={{
								boxShadow: "none",
								borderRadius: "10px",
								p: "25px 20px",
								mb: "10px",
								backgroundColor: "#F7FAFF",

							}}>
								<Typography
									as="h3"
									sx={{
										fontSize: 16,
										mb: "15px",
										fontWeight: 500,
										mt: "-25px",
										ml: "-25px",
										mr: "-25px",
										padding: "10px 25px",
										borderRadius: "8px 8px 0px 0px",
										background:
											"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

										color: "black",
									}}
								>
									<span style={{color:"#fff"}}>Audio File List</span>
								</Typography>
								<Table>
									<TableHead>
										<TableRow style={{ fontWeight: 900, fontSize: "25px" }}>
											<TableCell style={{ fontWeight: 900, fontSize: "17px" }}>Audio</TableCell>
											<TableCell style={{ fontWeight: 900, fontSize: "17px" }}>File</TableCell>
										</TableRow>
									</TableHead>
									{AudioCount !== 0 ?
										<TableBody>
											{Audio_list?.map((sub) => (
												<TableRow>
													<TableCell>
														{sub?.audio_language}
													</TableCell>
													<TableCell>
														<a href={IMAGE + sub?.audio_file} target="_blank" download="new-file" style={{color:"#ff87ef"}}>{sub?.audio_file.split("/")[4]}</a>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
										: <TableBody align="center">

											No Audio files are available for this Movie
										</TableBody>}

								</Table>

							</Card>


						</Box>

					</Card>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center">
          <Button
            // disabled={disBtn}
            style={{
              background:
                "linear-gradient(225deg,  #ac1600 0%, #500303 91.25%)",
            }}
            sx={{
              textTransform: "capitalize",
              borderRadius: "10px",
              mt: "10px",
              p: "10px 30px",
              fontSize: "14px",
              color: "#fff !important",
            }}
            variant="contained"
            className="mr-10px"
            onClick={handleEdit}
          >
            update
          </Button>
        
        </Grid>
				
			</Grid>


		</>
	);
};

export default ProductDetailsContent;
