import React from "react";
import { useState,useEffect,useMemo } from "react";

import { Link } from "react-router-dom";
import styles from "./../../styles/PageTitle.module.css";
import ListTable from "../utils/Table";
import { all_watchhour_list } from './../../actions/watchhour';
import { useDispatch,useSelector } from 'react-redux';
import Export from "./../utils/Export"
export default function Watchhour() {
	const dispatch=useDispatch()
	const rights=useSelector((state) => state.layout.rights)
	const [tableData, setTableData] = useState({
		tableTitle: "Watchhours",
		disableDelete:true,
		tableHead: [
			{
				id: "name",
				label: "Name",
			},
			{
				id: "image",
				label: "Image",
				isImage:true
			},
			{
				id: "category_type",
				label: "Content Type",
				padding:"8px 20px"
			},
			{
				id: "watch_hours",
				label: "Watch Hours",
			},
			{
				id: "no_of_download",
				label: "Likes",
			},
			{
				id: "no_of_download",
				label: "Views",
			},
			{
				id: "no_of_download",
				label: "Downloads",
			},
			{
				id: "plan_detail",
				label: "Plan",
			},
		],
		tableBody: [
			{
				id: 0,
				movie_name: "Movie",
				movie_type: "Landscape",
				watch_hours: "2",
				no_of_downloads: "Active",
				earning_by_movie: 0,
			},
		],filterColumn:[
			{
				id: "1",
			title: "Select Plan",
			name: "plan_detail",
			options: ["FREE", "TVOD","SVOD"],
			},
			{
				id: "2",
			title: "Select Content Type",
			name: "category_type",
			options: ["Movie", "Episode","Live Stream"],
			}
		]
	});

	
    const watchhours = useSelector((state) => state.watchhours.watchhours);
	console.log(watchhours,"hours")
    useEffect(()=>{
      dispatch(all_watchhour_list())
    },[])
    useMemo(()=>{
      
      if(watchhours?.statuscode==200){const temp=tableData
      temp.tableBody=watchhours?.data
      setTableData({...temp})}
    },[watchhours])
	

	
	
	return (
		<>
		
			

		<Export fileName={"Watch Hours"} access={rights?.["Watch Hours"]?.["export_excel"]=="true"} exportData={tableData?.exportData||tableData?.tableBody} headings={tableData.tableHead.map((value)=>value.label)}/>
		
	
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setTableData={setTableData}
					
				/>
		
		</>
	);
}
