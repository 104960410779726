import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "./../utils/Table";
import { movie_watch_user_list } from "./../../actions/Movie/movie";
import { episode_watch_user_list } from "./../../actions/WebSeries/episode";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
export default function ValueAddedCustomer({ path, id }) {
  console.log(path , id ,"newId")
  const dispatch = useDispatch();
  const alertOpen = () => {
    setOpen(true);
  }
  const [tableData, setTableData] = useState({
    tableTitle: "Customers who have seen",
    disableDelete: true,
    tableHead: [
      {
        id: "watch_by1",
        label: "Name",
        // link: "/Customer/CustomerDetail/CustomerDetail",
        isSpecial: true,
        align: "left",
        color: "var(--gradientColor2)",
        width: "auto",
      },
      {
        id: "user",
        label: "Email",
      },
      // {
      // 	id: "mobileNumber",
      // 	label: "Mobile No",
      // },

      {
        id: "location",
        label: "Location",
      },
      {
        id: "device_name",
        label: "Device Type",
      },
      {
        id: "last_watch_on",
        label: "Last Watched On",
      },
      {
        id: "watch_hours",
        label: "Watch Hours",
      },
    ],
    tableBody: [],
  });
  const [form, setForm] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false)

  const customers = useSelector((state) =>
    path == "MovieDetails"
      ? state.movies.movie_watch_user
      : state.webseries.episode_watch_user
  );
  const master = useSelector((state) => state.movies);
  // console.log(master, "master");
  useEffect(() => {
    const data = new FormData();
    data.append("id", id);
    if (path == "MovieDetails") dispatch(movie_watch_user_list(data));
    else dispatch(episode_watch_user_list(data));
  }, []);
  useMemo(() => {
    if (customers?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = customers?.data.map((ele)=>({...ele,watch_by1 :ele.user_id !=="-" ? <Link to={"/Customer/CustomerDetail/CustomerDetail"} state={{id:ele.user_id}}>{ele?.watch_by}</Link> : <p onClick={alertOpen} style={{color:"red"}}>User Deleted</p>}));
      setTableData({ ...temp });
    }
  }, [customers]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
     
      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
      />
       <Snackbar
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    open={open}
    autoHideDuration={3000}
    onClose={handleClose}


  >
    <Alert severity="info" variant="filled" color="success">User Deleted</Alert>
  </Snackbar>
    </>
  );
}
