import * as actionType from "../constants/actionTypes";

const dashboardReducer = (state = { dashboardData: null }, action) => {
	switch (action.type) {
		
		case actionType.TOTALUSERS:
			return {...state,totalusers: action.payload};
            case actionType.DASHBOARDCOUNT:
			return {...state,dashboardcount: action.payload};
            case actionType.DASHBOARDMOVIES:
			return {...state,dashboardmovies: action.payload};
			case actionType.TOTALSUBSCRIBEUSER:
			return {...state,totalsubscribeuser: action.payload};
			case actionType.MONTHLYREVENUE:
			return {...state,monthlyrevenue: action.payload};
			case actionType.TOTALREVENUE:
			return {...state,totalrevenue: action.payload};
			case actionType.TV_SHOW_DASHBOARD:
			return {...state,tv_show_dashboard: action.payload};
			case actionType.RECENT_SUBSCRIBER_DASHBOARD:
			return {...state,recent_subscriber_dashboard: action.payload};
			case actionType.ADVERTISEMENT_VIEW_GRAPH:
				return { ...state, advertisement_view_graph: action.payload };
			case actionType.DAILY_REVENUE:
				return {...state,daily_revenue: action.payload};
				case actionType.MAX_LOGIN_DURATION:
				return {...state,max_login_duration: action.payload};
				case actionType.RENEWAL:
				return {...state,renewal: action.payload};


				
				
		default:
			return state;
	}
};

export default dashboardReducer;