import React from "react";
import { useState,useEffect,useMemo } from "react";
import { all_complaint_list,complaint_status_update } from "../../actions/complaint";
import ListTable from "../utils/Table";
import EnlargedView from "../utils/EnlargedView";
import image from "./../../images/logo.png"
import { useDispatch,useSelector } from 'react-redux';
import Export from "./../utils/Export"
export default function Complaint() {
	const dispatch=useDispatch()
	const rights=useSelector((state) => state.layout.rights)
	const [open, setOpen] = useState(false);
	const [content, setContent] = useState();
    const handleOpen = () => setOpen(true);
	const [tableData, setTableData] = useState({
		tableTitle: "Complaints",
		disableDelete:true,
		updateRecord:complaint_status_update,
		openModal:handleOpen,
		onUpdateText:"Are you sure want to close the complaint?",
		tableHead: [
			{
				id: "user",
				label: "User Name",
			},
			{
				id: "complaint_type",
				label: "Complaint Type",
			},
			
			{
				id: "email",
				label: "Contact Info",
				subText:"mobileNumber"
			},
			
			{
				id: "device",
				label: "Device Details",
			},
			{
				id: "description",
				label: "Description",
				isModal:true,
				// default:"VIEW"
				default: <><button style={{ padding: "5px 15px", color: "rgb(238, 127, 37)", background: "transparent", border: "1px solid rgb(238, 127, 37)", borderRadius: "5px" }}>VIEW</button></>

			},
			{
				id: "image",
				label: "Image",
				isImage:true,
				isModal:true
			}
			,
			{
				id: "created_at",
				label: "Raised On",
				isSpecial:true
			},
			{
				id: "status",
				label: "Status",
				keywords:["Open","Close"],
				nonEditableState:"Close"
			}
		],
		tableBody: [
			{
				id: 0,
				complaint_type: "Movie",
				user_name: "Landscape",
				email_mobile: "2",
				description: "Nor hence hoped her after other known defer his. For county now sister engage had season better had waited. Occasional mrs interested far expression acceptance. Day either mrs talent pulled men rather regret admire but. Life ye sake it shed. Five lady he cold in meet up. Service get met adapted matters offence for. Principles man any insipidity age you simplicity understood. Do offering pleasure no ecstatic whatever on mr directly.",
				image: image,
				date: 0,
			},
		],
		filterColumn:[
			{
				id: "1",
			title: "Complaint Type",
			name: "complaint_type",
			options: ["Player not working", "Subscription not working","Content is not appropriate","Profile related issue","Application related issue","Other"],
			},
			{
				id: "2",
			title: "Status",
			name: "status",
			options: ["Open","Close"],
			},
		],
		isDateRangeFilter:"created_at"
	});

	const complaints = useSelector((state) => state.complaints.complaints);
    useEffect(()=>{
      dispatch(all_complaint_list())
    },[])
    useMemo(()=>{
      
      if(complaints?.statuscode==200){const temp=tableData
      temp.tableBody=complaints?.data.map((ele)=>({...ele,user:ele?.user?.firstName+" "+ele?.user?.lastName,device:ele?.user?.deviceId+" "+ele?.user?.deviceType
	,	created_at:
	<>
		<p >{ele?.created_at}</p>
		<span style={{ color: "red" }}>{ele?.closed_on}</span></>,description:
					<><div dangerouslySetInnerHTML={{ __html: ele?.description }}  style={{color:"white"}}/>
						<p style={{color:"white"}}>{temp?.tableHead?.default}</p> </>,
	
	}))
      setTableData({...temp})}
    },[complaints])
	
	
	
	return (
		<>
		
		<EnlargedView open={open} setOpen={setOpen} content={content}/>

		<Export fileName={"Complaints"} access={rights?.["Complaints"]?.["export_excel"]=="true"}  exportData={tableData?.exportData||tableData?.tableBody} headings={tableData.tableHead.map((value)=>value.label)}/>
		
	
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setTableData={setTableData}
					setContent={setContent}
				/>
		
		</>
	);
}
