

import {  Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import AdTransaction from '../components/AdTransaction/AdTransaction';

function AdTransactionModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/AdTransaction' element={<Layout children={AdTransaction()}/>}/>
      </Routes>
    </div>
  );
}

export default  AdTransactionModule;
