

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Advertisers from '../components/Advertisers/Advertisers';
import Advertisement from '../components/Advertisers/Advertisement';
import AdvertisementPayment from '../components/Advertisers/AdvertisementPayment';
import Layout from '../components/Layout/Layout';

function AdvertisersModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Advertisers/' element={<Layout children={Advertisers()}/>}/>
< Route path='/CreateAdvertisers/' element={<Layout children={Advertisers()}/>}/>
< Route path='/EditAdvertisers/' element={<Layout children={Advertisers()}/>}/>


< Route path='/Advertisement/' element={<Layout children={Advertisement()}/>}/>
< Route path='/CreateAdvertisement/' element={<Layout children={Advertisement()}/>}/>
< Route path='/EditAdvertisement/' element={<Layout children={Advertisement()}/>}/>


< Route path='/AdvertisementPayment/' element={<Layout children={AdvertisementPayment()}/>}/>
< Route path='/EditAdvertisementPayment/' element={<Layout children={AdvertisementPayment()}/>}/>
< Route path='/CreateAdvertisementPayment/' element={<Layout children={AdvertisementPayment()}/>}/>


      </Routes>
    </div>
  );
}

export default AdvertisersModule;