import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import { quick_promotion_create ,all_quick_promotion_list , quick_promotion_update , quick_promotion_delete} from '../../actions/promotion';
import { all_movie_list, all_movie_name_list } from "../../actions/Movie/movie";
import { all_series_list } from "../../actions/WebSeries/series";
// import { all_customer_list } from '../../actions/customer';
// import { all_distributor_list } from '../../actions/distributor';
// import {

// 	all_advertiser_list,
// } from "../../actions/Advertiser/advertiser";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation , useNavigate } from "react-router-dom";
import EnlargedView from "../utils/EnlargedView";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
export default function Promotion() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const user = useSelector((state) => state.layout.profile)
	const rights = useSelector((state) => state.layout.rights)
	const role = useSelector((state) => state.layout.role)
	const [open, setOpen] = useState(false);
	const [content, setContent] = useState();
	const handleOpen = () => setOpen(true);
	const location = useLocation();
	const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
	const [view, setView] = useState(location?.state?.view || "view_all");
	const [isEdit, setIsEdit] = useState(false);
	useMemo(() => {
		if (isEdit) { setView("create_new") }
		else {
			setView("view_all")
			setForm({});
		}
	}, [isEdit]);
	const path = location?.pathname.split("/")[2]

	useEffect(() => {
		setView(path != "Promotion" ? "create_new" : "view_all");
		setForm((location?.state?.form && JSON.parse(location?.state?.form)) || { ...form, set_sequence: tableData?.tableBody?.length + 1 });
		setIsEdit(path == "EditPromotion");
	}, [location]);
	const tempTableData = {
		tableTitle: "Promotions", 
		deleteRecord: quick_promotion_delete,
		updateRecord: quick_promotion_update,
		disableDelete: role == "Advertiser" || role == "Producer",
		onDeleteText: "Are you sure want to delete?",
		deleteAccess: rights?.["Promotions"]?.["delete"] == "true",
		onUpdateText: "Are you Sure?",
		tableHead: [
			{
				id: "promotion_title",
				label: "Promotion Title",
				// isSpecial: true
			},
			{
				id:"video_type",
				label:"Video type"
			},

			{
				id: "promotion_image",
				label: "Image",
				isImage: true
			},
			{
				id: "promotion_end_date",
				label: "Expired on",
			},

		 {
				id: "edit",
				label: "Update",
				access: rights?.["Promotions"]?.["edit"] == "true",
				
			}
		].filter(e => e),
		tableBody: [

		],
	}
	const [tableData, setTableData] = useState({ ...tempTableData });
	useMemo(() => {
		setTableData({ ...tempTableData })
	}, [rights])
	const [formStructure, setFormStructure] = useState([
		{
			id: "1",
			type: "select",
			title: "Video Type",
			name: "video_type",
			disabled: isEdit ? true : false,
			options: ["Movie", "Series"],
			required: true,
		  },
	  
		  {
			id: "2",
			type: "select",
			title: "Select ",
			options: [],
			name: "movie_name",
			display: "none",
			required: true,
		  },
		{
			id: "1",
			type: "inputBox",
			size: "6",
			title: "Promotion Title",
			name: "promotion_title",
			required: true
		},
		// {
		// 	id: "3",
		// 	type: "inputBox",
		// 	title: "Promotion Link",
		// 	size: "6",
		// 	name: "promotion_link",
		// 	options: ["Advertiser", "Producer", "Customer"],
		// 	required: true
		// },
		{
			id: "4",
			type: "inputBox",
			variant: "date",
			title: "Start on",
			min: new Date().toISOString().split('T')[0],
			default:" ",
			name: "promotion_start_date",
			required: true
		},

		{
			id: "2",
			type: "image",
			title: "Promotion Image (App)",
			name: "promotion_image",
			subtitle: "(Resolution : 910px x 1280px) *",
			subsubtitle: "Max File Size 1MB",
			subsubsubtitle: "Support only JPG,PNG,JPEG",
		},
		{
			id: "19",
			type: "image",
			subtitle: "(Resolution : 1920px x 900px) *",
			subsubtitle: "Max File Size 1MB",
			subsubsubtitle: "Support only JPG,PNG,JPEG",
			title: 'Promotion Image (Web)',
			name: "promotion_image_web",
			size: 3
		},
		{
			id: "8",
			type: "button",
			title: "Send",
		},
	]);


	
	useEffect(() => {
		const data = new FormData()
		data.append('id', user?.id)
		dispatch(all_quick_promotion_list(data))
		dispatch(all_series_list(data));
		dispatch(all_movie_name_list(data));
	}, [user])
	const movies = useSelector((state) => state?.movies?.movie_name);
	const series = useSelector((state) => state.webseries.series);
	const promotion = useSelector((state) => state.promotion.promotion);
	// console.log(promotion,"promotion")
	useMemo(() => {

		if (promotion?.statuscode == 200) {
			const temp = tableData
			temp.tableBody = promotion?.data?.map((ele) => ({
				...ele, 
				series : ele?.series?.series_name,
				movie : ele?.movie?.movie_name
			}))
			//   temp.tableBody=notifications?.data

			setTableData({ ...temp })
		}
	}, [promotion])
	useMemo(() => {
		if (isEdit) {
		  const temp = formStructure;
		  temp[0]["type"] = "inputBox";
		  temp[0]["disabled"] = true;
		  setFormStructure([...temp]);
		} else {
		  const temp = formStructure;
	
		  temp[0]["type"] = "select";
		  temp[0]["disabled"] = false;
		  setFormStructure([...temp]);
		}
	  }, [isEdit]);
	useMemo(() => {
		if (form?.video_type == "Movie") {
		  const temp = formStructure;
		  temp[1]["display"] = "block";
		  temp[1]["title"] = "Select Movie";
		  temp[1]["name"] = "movie";
		  temp[1]["options"] = movies?.data?.map((ele) => ele.movie_name);
		  setFormStructure([...temp]);
		} else if (form?.video_type == "Series") {
		  const temp = formStructure;
		  temp[1]["display"] = "block";
		  temp[1]["title"] = "Select Series";
		  temp[1]["name"] = "series";
		  temp[1]["options"] = series?.data?.map((ele) => ele.series_name);
		  setFormStructure([...temp]);
		} 
		else {
		  const temp = formStructure;
		  temp[1]["display"] = "none";
		  setFormStructure([...temp]);
		}
		
	  }, [form?.video_type]);

	const formTitle = isEdit ? "Edit Promotion" : "Create Promotion";

	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();

		const temp_form = form;

		temp_form["tempmovie"] = form?.["movie"];
		temp_form["tempseries"] = form?.["series"];
		temp_form["movie"] = movies?.data
		  ?.map(
			(option) => form?.["movie"]?.includes(option?.movie_name) && option?.id
		  )
		  .filter((e) => e)[0];
		temp_form["series"] = series?.data
		  ?.map(
			(option) =>
			  form?.["series"]?.includes(option?.series_name) && option?.id
		  )
		  .filter((e) => e)[0];
		  setForm({ ...temp_form });

		
		Object.keys(form)?.map((key) => data.append(key, form?.[key]));
		data.append("user", user?.id);
		formStructure?.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});
		if (isEdit) {
			dispatch(quick_promotion_update(data))
		}
		else {
			dispatch(quick_promotion_create(data))
		}


	};
	useMemo(() => {
		if (message?.statuscode == 200) {
			const temp = tableData;
			if (isEdit) {
				temp.tableBody?.map(
					(value, index) =>
						value.id == form.id && (temp.tableBody[index] = { ...form })
				);

			} else {
				temp.tableBody[temp.tableBody.length] = {
					id: temp.tableBody.length,
					...form,
					edit: temp.tableBody.length,
				};

			}
			setTableData({ ...temp });
			setIsEdit(false);
			setForm({});
			setTimeout(() => {
				const data = new FormData()
				data.append('id', user?.id)
				dispatch(all_quick_promotion_list(data));
				navigate("/Promotion/Promotion/", { state: { view: "view_all" } })

				setView("view_all");
			}, 1000);
		}
		else {
			const temp = form
			
			setForm({ ...temp });
		}
	}, [message])

	return (
		<>
			<EnlargedView open={open} setOpen={setOpen} content={content} />
			{role != "Advertiser" && role != "Producer" && <ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
				create_new={"/Promotion/CreatePromotion"}
				view_all={"/Promotion/Promotion"}
				form={form}
				access={rights?.["Promotions"]?.["create"] == "true"}
			/>}

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					create_new={"/Promotion/EditPromotion"}
				/>
			)}
		</>
	);
}
