

import {  Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';

import CollectionForm from '../components/Collaborator/CollaboratorForm';

function CollaboratorFormModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/CollaboratorForm' element={<Layout children={CollectionForm()}/>}/>
< Route path='/CreateCollaboratorForm' element={<Layout children={CollectionForm()}/>}/>
< Route path='/EditCollaboratorForm' element={<Layout children={CollectionForm()}/>}/>



      </Routes>
    </div>
  );
}

export default  CollaboratorFormModule;
