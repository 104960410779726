import React from "react";
import { useState, useMemo,useEffect } from "react";
import ListTable from "../utils/Table";
import { coupon_details } from '../../actions/coupon';

import { useDispatch,useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

export default function CouponHistory() {
	const dispatch=useDispatch()
	const location = useLocation()
	
	const [tableData, setTableData] = useState({
		tableTitle: "Coupon History",
		tableHead: [
			{
				id: "promocode",
				label: "Promocode",
				
			},
			{
				id: "coupon_title",
				label: "Coupon Title",
			},
			
			{
				id: "select_type",
				label: "Coupon Type",
			},
			{
				id: "user",
				label: "User Name",
			},
			{
				id: "mobile_number",
				label: "Mobile",
			},
			{
				id: "email",
				label: "Email",
			},
			{
				id: "created_at",
				label: "Used On",
			}
		],
		tableBody: [
		
		],filterColumn:[
			
			{
				id: "2",
			title: "Select Type",
			name: "select_type",
			options: ["FREE", "TVOD","SVOD"],
			}
		]
	});
	const [form, setForm] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	
	
    const coupons = useSelector((state) => state.merchandise.couponhistory);
	const formData= new FormData()
	formData.append("id",location.state?.id)
    useMemo(()=>{
      dispatch(coupon_details(formData))
    },[location.state?.id])
	
    useMemo(()=>{
      
      if(coupons?.statuscode==200){const temp=tableData
      temp.tableBody=coupons?.data.map((ele)=>({...ele,plan:ele?.plan?.plan_name,movie:ele?.movie?.movie_name,user:ele?.user?.firstName+" "+ele?.user?.lastName}))
      setTableData({...temp})}
	  else{
		const temp=tableData
      temp.tableBody=[]
      setTableData({...temp})
	  }
    },[coupons])

	
	return (
		<>
			
			

			
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
				/>
			
		</>
	);
}
