import React from 'react'
import ListTable from "../utils/Table"
import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { live_movie_view_list } from '../../actions/live_movie_view';
const LiveMovieView = () => {
	const dispatch = useDispatch();

    const tempTableData={
		tableTitle: "Real time Movie Tracking",
		tableHead: [
			{
				id: "movie",
				label: "Movie Name",
			},
			{
				id: "total_live_count",
				label: "Live User Count",
			},
			
			
		],
		tableBody: [
			
		],
		
	}
	const [tableData, setTableData] = useState({...tempTableData});
	


	const currentTime = useSelector((state) => state?.currenttime?.currenttime);
    // console.log(currentTime,"newCurrner")
	useEffect(() => {
        setInterval(() => {
            dispatch(live_movie_view_list());
      }, 5000);
		
	}, []);
	useMemo(() => {
		if (currentTime?.statuscode == 200) {
			const temp = tableData;
			temp.tableBody = currentTime?.data?.map((ele)=>({...ele,movie:ele?.movie?.movie_name}));
			setTableData({ ...temp });
		}
	}, [currentTime]);
  return (
   <>
   <ListTable
					tableData={tableData}
					key={"ListTable"}
					// setForm={setForm}
					setTableData={setTableData}
					// setIsEdit={setIsEdit}
				/>
   </>
  )
}

export default LiveMovieView