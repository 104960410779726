import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import { Country, State } from 'country-state-city';

import {
	advertiser_create,
	advertiser_delete,
	advertiser_update,
	all_advertiser_list,
} from "../../actions/Advertiser/advertiser";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Export from "./../utils/Export";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import logoo from "../../images/slice_logo.png"
import Image from "../../images/file1.png"

import Reload from "../utils/Reload";
export default function Advertisers() {
	const user = useSelector((state) => state.layout.profile)
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate()
	const [open1, setOpen1] = useState(false);
	const [flag, setFlag] = useState(false)
	const [contactName,setContactName] = useState(false)
	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
	const [view, setView] = useState(location?.state?.view || "view_all");
	const rights = useSelector((state) => state.layout.rights)
	const role = useSelector((state) => state.layout.role)
	const country_list = {}
	Country.getAllCountries().map((value) => (country_list[JSON.stringify(value?.name)] = value?.isoCode))
	useMemo(() => {
		if (isEdit) { setView("create_new") }
		else {
			setView("view_all")
			setForm({});
		}
	}, [isEdit]);
	const path = location?.pathname.split("/")[2]
	useEffect(() => {
		setView(path != "Advertisers" ? "create_new" : "view_all");
		setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
		setIsEdit(path == "EditAdvertisers");
	}, [location]);
	const tempTableData = {
		tableTitle: "Advertisers",
		deleteRecord: advertiser_delete,
		deleteAccess: rights?.["Advertisement"]?.["delete"] == "true",
		updateRecord: advertiser_update,
		onDeleteText: "Are you sure want to delete?",
		onUpdateText: "Are you Sure?",
		tableHead: [
			{
				id: "name",
				label: "Advertisers Name",
				link: "/Advertisers/Advertisement/",
				color: "var(--gradientColor2)",
				width: "auto",
			},
			{
				id: "company_name",
				label: "Company Name",
			},
			{
				id: "company_logo",
				label: "Company Logo",
				isImage: true,
			},


			{
				id: "email",
				label: "Contact Info",
				subText: "mobileNumber"
			},
			// {
			// 	id: "country",
			// 	label: "Country",
			// },
			// {
			// 	id: "commission",
			// 	label: "Price per view (Paisa)",
			// 	align: "center"
			// },

			{
				id: "edit",
				label: "Update",
				access: rights?.["Advertisement"]?.["edit"] == "true"
			},
		],
		tableBody: [], filterColumn: [
			// {
			//   id: "1",
			// title: "State",
			// name: "subscription",
			// options: ["State", "State","State"],
			// },

		],
	}
	const [tableData, setTableData] = useState({ ...tempTableData });
	const advertisers = useSelector((state) => state.advertisers.advertisers);



	useMemo(() => {
		setTableData({ ...tempTableData })
	}, [rights])
	const [formStructure, setFormStructure] = useState(
		[



			{
				id: "5",
				type: "inputBox",
				title: "Company Name",
				name: "company_name",
				disabled: isEdit ? true : false,
				required: true,
			},
			{
				id: "5",
				type: "inputBox",
				title: "Advertisers Name",
				name: "name",
				disabled: isEdit ? true : false,
				required: true,
			},
			{
				id: "5",
				type: "inputBox",
				title: "Email ID",
				variant: "email",
				name: "email",
				required: true,
			},
			{
				id: "4",
				type: "phone",
				title: "Mobile Number",
				maxLength: 12,
				name: "mobileNumber",
				disabled:true,
				required: true,
			},
			{
				id: "1",
				type: "inputBox",
				name: "GST",
				title: "GST No",
				isCaps:true,

				// required: true

			},

			{
				id: "2",
				type: "inputBox",
				title: "Address",
				name: "address",
			},
			// {
			// 	id: "2",
			// 	type: "select",
			// 	title: "Pay per view (%)",
			// 	name: "commission",
			// 	options: ["10", "20", "30", "40", "50", "60", "70", "80", "90"],
			// 	required: true,
			// },

			{
				id: "4",
				type: "hr",

			},
			{
				id: "1",
				type: "headind_ad",
				title: "Contact Person Details "
			},
			{
				id: "4",
				type: "hr2",

			},
			{
				id:"6",
				type:"Checkbox",
				name:"contact_p",
				title:"same as above",
				forceBox:true,
				handleChanges	 :(event)=>{
					// console.log(event.target.checked ,"newCodeByO")
					setContactName(event.target.checked)
				}
			},

			{
				id: "4",
				type: "inputBox",
				title: "Contact Person Name",
				name: "contact_person_name",
				required: true,
			},
			{
				id: "4",
				type: "phone2",
				title: "Contact Person Number",
				maxLength: 12,
				name: "contact_person_number",
				required: true,
			},

			{
				id: "2",
				type: "image",
				title: "Company Registration Certificate",
				name: "company_register_certificate",
				accept: ".pdf"

			},
			{
				id: "2",
				type: "image",
				title: "Company Logo",
				name: "company_logo",
			},
			{
				id: "2",
				type: "image",
				title: "Contract Agreement",
				name: "contract_agreement",
				accept: ".pdf"
			},

			{
				id: "8",
				type: "button",
				title: "Update",
				disabled: false,

			},]

		,
	);
	// console.log(form?.contact_p,"newFormTesting")
	useMemo(() => {
		const temp = formStructure;
		if (form?.countryCode == "+91" || form?.countryCode == undefined) {
			temp[4]["title"] = "GST No";
		} else temp[4]["title"] = "Registration No";



		setFormStructure([...temp]);
	}, [form?.countryCode]);


	// const handleClick = () =>{
	// 	const temp = formStructure;
	// 	temp[15]["disabled"] = true
	// 	setTimeout(()=>{
	// 		temp[15]["disabled"] = false
	// 	},3000)

	// }


	useEffect(() => {
		dispatch(all_advertiser_list());
	}, []);
	useMemo(() => {
		if (advertisers?.statuscode == 200) {
			const temp = tableData;
			temp.tableBody = advertisers?.data.map((ele) => ({ ...ele, id: ele?.user , company_logo : ele?.company_logo !== "" ? ele?.company_logo : <img src={Image} alt="img"/> }));
			setTableData({ ...temp });
		}
	}, [advertisers]);
	useMemo(()=>{
if(isEdit){
	const temp = formStructure
	temp[0]["disabled"] = true
	temp[1]["disabled"] = true
	temp[2]["disabled"] = true
	temp[3]["disabled"] = true
	temp[4]["disabled"] = true

setFormStructure([...temp])
}else{
	const temp = formStructure
	temp[0]["disabled"] = false
	temp[1]["disabled"] = false
	temp[2]["disabled"] = false
	temp[3]["disabled"] = false
	temp[4]["disabled"] = false

setFormStructure([...temp])
}
	},[isEdit])

	const formTitle = isEdit ? "Edit Advertisers" : "Create Advertisers";
	const message = useSelector((state) => state.layout.message);
	useEffect(() => {
		if (advertisers?.statuscode !== 200) {
			setOpen1(true)
		}
		else {
			setOpen1(false)
		}
	}, [advertisers])
	const handleSubmit = (event) => {
		event.preventDefault();
		const temp = formStructure;

		temp[15]["disabled"] = true
		setTimeout(() => {
			temp[15]["disabled"] = false
		}, 3000)
		const tempForm = form;
		// console.log(form, "test")


		tempForm["username"] = form?.mobileNumber.slice(
			form?.countryCode.length - 1
		);
		tempForm["tempmobileNumber"] = form?.mobileNumber
		tempForm["mobileNumber"] = form?.mobileNumber.slice(
			form?.countryCode.length - 1
		);
		setForm({ ...tempForm });

		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		data.append("user", user?.id);
		formStructure.map((element) => {
			if (
				element.type == "image" &&
				form?.[element.name] &&
				typeof form?.[element.name] != "string"
			) {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name]
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}


		});


		if (isEdit) {

			dispatch(advertiser_update(data));
		} else {

			dispatch(advertiser_create(data));
		}

		setFlag(true)
	};
	
	useMemo(()=>{
		if(contactName){
			const temp = form
			temp["contact_person_name"] = form?.name;
			temp["contact_person_number"] = form?.mobileNumber
			setForm({...temp})
		}else{
			const temp = form

			temp["contact_person_name"] = ""
			temp["contact_person_number"] = ""
			setForm({...temp})

		}
	},[contactName])
	useMemo(() => {
		if (message?.statuscode == 200 && flag) {
			const temp = tableData;
			if (isEdit) {
				temp.tableBody.map(
					(value, index) =>
						value.id == form.id && (temp.tableBody[index] = { ...form })
				);

			} else {
				temp.tableBody[temp.tableBody.length] = {
					id: temp.tableBody.length,
					...form,
					edit: temp.tableBody.length,
				};

			}
			setTableData({ ...temp });
			setIsEdit(false);
			setForm({});
			setFlag(false)
			// console.log("parth")
			setTimeout(() => {
				dispatch(all_advertiser_list());
				// console.log("parth1")

				navigate("/Advertisers/Advertisers", { state: { view: "view_all" } })
				// console.log("parth2")

				setView("view_all");
			}, 1000);
		}
		else {
			const tempForm = form;

			tempForm["mobileNumber"] = form?.tempmobileNumber
			tempForm["company_logo"] = form?.tempcompany_logo
			setForm({ ...tempForm });
		}
	}, [message])
	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={open1}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				access={rights?.["Advertisement"]?.["create"] == "true"}
				view={view}
				isEdit={isEdit}
				create_new={"/Advertisers/CreateAdvertisers"}
				view_all={"/Advertisers/Advertisers"}
				form={form}
				export_excel={<Export fileName={"Advertisers"} access={rights?.["Advertisement"]?.["export_excel"] == "true"} isClubed={true} exportData={tableData?.exportData || tableData?.tableBody} headings={tableData.tableHead.map((value) => value.label)} />}
			/>

			{view == "create_new" && (
				<>
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}

						open={open1}
					>
						<CircularProgress color="inherit" />


					</Backdrop>
					<Form
						formStructure={formStructure}
						handleSubmit={handleSubmit}
						formTitle={formTitle}
						key={"Form"}
						setForm={setForm}
						form={form}
						tableData={tableData}
						setTableData={setTableData}
						isEdit={isEdit}
						setIsEdit={setIsEdit}

					/>
				</>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					create_new={"/Advertisers/EditAdvertisers"}
					reload={<Reload isClubed={true} />}
				/>
			)}
		</>
	);
}
