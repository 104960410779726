
import { useSelector } from "react-redux"

export const TableData = () => {
  const rights = useSelector((state) => state.layout.rights)
  const role = useSelector((state) => state.layout.role)
  return {
    tableTitle: "Video Collections",
	disableDelete: true,
    onUpdateText: "Are you Sure?",
    tableHead: [
        {
            id: "production_name",
            label: "Production Name",
            subText:"distribution_name",
            link:"/DistributorsFormData/DistributorsFormDetails/DistributorsFormDetails"
        },
        {
            id:"created_at",
            label:"Data"
        },
        {
            id: "type_of_film1",
            label: "Video Title",
            subText:"category_type"
        },
        {
            id: "email",
            label: "Contact info" ,
            subText:"mobileNumber"
        },
        {
            id: "language",
            label: "Language" ,
        },
        {
            id: "rent1",
            label: "Rental Amount" ,
        },
 {
    id:"status",
    label:"Status",
    isButtonDisplay: true

  },
 role == "Producer" && {
    id: 'edit',
    label: 'Update',
    access: rights?.["Movie Submission"]?.["edit"] == "true",
    ErrorMsg:"You cannot change the details once it has been approved."
  }
 
        
    ].filter(e => e),
    tableBody: [

    ],
    filterColumn: [
        {
            id: "1",
            title: "Status",
            name: "status",
            options: ["Approved", "Rejected" , 	"Pending"],
          },
    ],
    filterColumn_Pro: [
        {
            id: "1",
            title: "Status",
            name: "status",
            options: ["Approved", "Rejected" , 	"Pending"],
          },
    ]
  }
}