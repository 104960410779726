import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./../components/Dashboard/Dashboard";
import Layout from "../components/Layout/Layout";
import Profile from "../components/Profile/Profile";


function DashboardModule() {
  return ( 
    <div className="App">
      <Routes>
        {/* Test */}
        <Route path="/" element={<Layout children={Dashboard()} />} />
        {/* test */}
        <Route path="/Dashboard" element={<Layout children={Dashboard()} />} />

        {/* <Route
          path="/AdvertiserElements/*"
          element={<AdvertiserElementsModule key="AdvertiserElements" />}
        /> */}
        {/* <Route
          path="/EditAdvertisement"
          element={<Layout children={AdvertiserElements()} />}
        /> */}
        <Route path="/Profile" element={<Layout children={Profile()} />} />
      </Routes>
    </div>
  );
}

export default DashboardModule;
