import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import MultiStepForm from "../../utils/MultiStepForm";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
  episode_create,
  episode_delete,
  episode_update,
  all_episode_list,
  episode_audio_file_delete,
  episode_subtitle_delete,
} from "../../../actions/WebSeries/episode";
import { all_season_list } from "../../../actions/WebSeries/season";
import { all_advertisement_list } from "../../../actions/Advertiser/advertisement";
import { all_series_list } from "../../../actions/WebSeries/series";
import { all_language_list } from "../../../actions/Masters/language";
import { all_cast_list } from "../../../actions/Masters/cast";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import Popup from "../../utils/Popup";
import Export from "../../utils/Export";
import Reload from "../../utils/Reload";
export default function Episode() {
  const user = useSelector((state) => state.layout.profile);
  const role = useSelector((state) => state.layout.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rights = useSelector((state) => state.layout.rights);
  const location = useLocation();
  const tempTableData = {
    ...TableData(),
    deleteRecord: episode_delete,
    updateRecord: episode_update,
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  const [open, setOpen] = useState(false);
  const [adPosition, setAdPosition] = useState();
  const [flag, setFlag] = useState(false);

  const [subtitlePosition, setSubtitlePosition] = useState();
  const [audioPosition, setAudioPosition] = useState();
  const [subTitleID, setsubTitleID] = useState("");
  const [audioFileId, setAudioFileId] = useState("");

  const [result, setResult] = useState(undefined);

  const [content, setPopupContent] = useState("");
  const [openAdError, setOpenAdError] = useState(false);

  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );

  const formTitle = [
    "Episode Details",
    "Episode Resolution",
    "Audio Files",
    "Age Restrictions & Downloads",
    // role != "Producer" && "Advertisement Details",
  ].filter((e) => e);
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  const tempFormStruct = FormStructure(isEdit);
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);
  const path = location?.pathname.split("/")[2];

  useEffect(() => {
    setView(path != "Episode" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {
        subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        audio_language: "Inactive",
      }
    );
    setIsEdit(path == "EditEpisode");
  }, [location]);

  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  const episodes = useSelector((state) => state.webseries.episodes);
  // const advertisements = useSelector(
  //   (state) => state.advertisers.advertisements
  // );
  const language = useSelector((state) => state.masters.languages);
  const seasons = useSelector((state) => state.webseries.seasons);
  console.log(seasons, "10000");
  useEffect(() => {
    const data = new FormData();
    data.append("id", user?.id);
    dispatch(all_episode_list(data));
    dispatch(all_series_list(data));
    dispatch(all_cast_list());
    dispatch(all_language_list());
    // dispatch(all_advertisement_list(data));
    dispatch(all_season_list(data));
  }, [user]);
  const series = useSelector((state) => state.webseries.series);

  useMemo(() => {
    if (episodes?.statuscode == 200) {
      const temp = tableData;
      // const episodeAdvertise = [];
      // episodes?.data.map((value) => {
      //   const advertise = {};
      //   value?.advertise &&
      //     value?.advertise?.map((ele, index) => {
      //       advertise["advertise" + index] = ele?.advertise?.product_name;
      //       advertise["time" + index] = ele?.time;
      //       advertise["duration" + index] = ele?.advertise?.duration;
      //       advertise["company_name" + index] =
      //         ele?.advertise?.advertiser?.company_name;
      //       advertise["views_required" + index] =
      //         ele?.advertise?.views_required;
      //       advertise["available_views" + index] =
      //         ele?.advertise?.views_required - ele?.advertise?.no_of_views;
      //     });
      //   episodeAdvertise.push(advertise);
      // });
      const episodeSubtitle = [];
      episodes?.data.map((value) => {
        console.log(value, "value");
        const subtitle = {};
        value?.subtitle_list &&
          value?.subtitle_list?.map((ele, index) => {
            console.log(ele?.subtitle_file, ele?.subtitle_language, "idd");
            subtitle["subtitle_language_" + index] = ele?.subtitle_language;
            subtitle["subtitle_file_" + index] = ele?.subtitle_file;
            subtitle["subtitle_id_" + index] = ele?.id;
          });
        episodeSubtitle.push(subtitle);
      });

      const episodeAudio = [];
      episodes?.data.map((value) => {
        console.log(value, "value");
        const Audio = {};
        value?.audio_file_list &&
          value?.audio_file_list?.map((ele, index) => {
            console.log(index, "idd");
            Audio["audio_language_" + index] = ele?.audio_language;
            Audio["audio_file_" + index] = ele?.audio_file;
            Audio["audio_id_" + index] = ele?.id;
            console.log(index, ele?.id, "12301230");
          });
        episodeAudio.push(Audio);
      });
      temp.tableBody = episodes?.data.map((ele, index) => ({
        ...ele,
        // ...episodeAdvertise[index],
        ...episodeSubtitle[index],
        ...episodeAudio[index],
        uploaded_by: ele?.series_name?.created_by?.firstName,
        series_ownership: ele?.series_name?.series_ownership,
        series_name: ele?.series_name?.series_name,
        series_cast: ele?.series_cast.map((cast) => cast.cast_name),
        season: ele?.season_name,
      }));
      setTableData({ ...temp });
    }
  }, [episodes]);

  // const handleClick = () => {
  //   if (formStructure[4].length < 35) {
  //     const temp = formStructure;
  //     const index = temp[4].length - 3;
  //     const randomName = formStructure[4].length / 7;
  //     temp[4].push(
  //       {
  //         id: "21",
  //         type: "select",
  //         size: "3",
  //         title: "Select Advertise",
  //         name: "advertise" + randomName,
  //         options: temp[4][1]["options"],
  //       },
  //       {
  //         id: "24",
  //         type: "inputBox",
  //         size: "1.5",
  //         title: "Ad Duration",
  //         name: "duration" + randomName,
  //         disabled: true,
  //       },
  //       {
  //         id: "24",
  //         type: "duration",

  //         size: "2",
  //         title: "Add Duration",
  //         name: "time" + randomName,
  //       },

  //       {
  //         id: "24",
  //         type: "inputBox",
  //         size: "2",
  //         title: "Company Name",
  //         name: "company_name" + randomName,
  //         disabled: true,
  //       },
  //       {
  //         id: "24",
  //         type: "inputBox",
  //         size: "1.5",
  //         title: "View Required",
  //         name: "views_required" + randomName,
  //         disabled: true,
  //       },
  //       {
  //         id: "24",
  //         type: "inputBox",
  //         size: "1.5",
  //         title: "Avbl. views",
  //         name: "available_views" + randomName,
  //         disabled: true,
  //       },
  //       {
  //         id: "23",
  //         type: "button",
  //         title: <RemoveIcon sx={{ color: "#fff !important" }} />,
  //         align: "right",
  //         size: ".5",
  //         padding: "2px 2px",
  //         position: randomName,
  //         forceShow: true,
  //         handleClick: () => {
  //           setOpen(true);
  //           setAdPosition(randomName);
  //           setPopupContent(
  //             "Do you want to remove this advertisement from this episode?"
  //           );
  //         },
  //       }
  //     );

  //     setFormStructure([...temp]);
  //   } else {
  //     setOpenAdError(true);
  //     setPopupContent("You can add max 5 advertisement in a episode");
  //   }
  // };
  const handleAddSubtitle = () => {
    const temp = formStructure;
    if (formStructure[3].length - 4 < language?.data.length * 3) {
      // setForm({...form,subtitle_count:form?.subtitle_count+1})
      const randomName = (formStructure[3].length - 4) / 3;
      temp[3].push(
        {
          id: "21",
          type: "select",
          title: "Select Language",
          size: "6",
          name: "subtitle_language_" + randomName,
          options: temp[3][5]["options"],
        },
        {
          id: "28",
          type: isEdit ? "lockedInput" : "inputBox",
          subtitle: "*",
          title: "Browse Subtitle file Link",
          name: "subtitle_file_" + randomName,
          size: "5.5",
          endsWith: ".srt",
          endsWith1: ".asc",
          // display: "none",
          errorText: "File extension should be .srt or .asc only",
          required: true,
        },

        {
          id: "23",
          type: "button",
          title: <RemoveIcon sx={{ color: "#fff !important" }} />,
          align: "right",
          size: ".5",
          padding: "2px 2px",
          position: randomName,
          forceShow: true,
          handleClick: () => {
            setOpen(true);
            setSubtitlePosition(randomName);
            setsubTitleID(form["subtitle_id_" + randomName]);
            setPopupContent(
              "Do you want to remove this subtitle from this movie?"
            );
          },
        }
      );

      setFormStructure([...temp]);
    } else {
      setOpenAdError(true);
      setPopupContent(
        "You can add max " + language?.data.length + " subtitles in a movie"
      );
    }
  };
  const handleAddAudioFile = (e) => {
    // console.log(e.currentTarget,"1111");
    const temp = formStructure;

    if (formStructure[2].length - 2 < language?.data.length * 3) {
      const randomName = (formStructure[2].length - 2) / 3;

      temp[2].push(
        {
          id: "21",
          type: "select",
          title: "Select Language",
          size: "6",
          name: "audio_language_" + randomName,
          options: temp[2][3]["options"],
        },
        {
          id: "19",
          type: isEdit ? "lockedInput" : "inputBox",
          subtitle: "*",
          title: "Browse mp3 file(.mp3)",
          name: "audio_file_" + randomName,
          size: "5.5",
          endsWith: ".mp3",
          endsWith1: ".wav",
          errorText: "File extension should be .mp3 or .wav only",
          // display: "none",
          required: true,
        },

        {
          id: "23",
          type: "button",
          title: <RemoveIcon sx={{ color: "#fff !important" }} />,
          align: "right",
          size: ".5",
          padding: "2px 2px",
          position: randomName,
          forceShow: true,
          handleClick: () => {
            setOpen(true);
            setAudioPosition(randomName);
            setAudioFileId(form["audio_id_" + randomName]);
            setPopupContent(
              "Do you want to remove this audio file from this movie?"
            );
          },
        }
      );

      setFormStructure([...temp]);
    } else {
      setOpenAdError(true);
      setPopupContent(
        "You can add max " + language?.data.length + " audio file in a movie"
      );
    }
  };
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
      // if (form?.advertise?.length > formStructure?.[4]?.length / 7)
      //   form?.advertise.map(
      //     (ele) =>
      //       form?.advertise.length > formStructure?.[4]?.length / 7 &&
      //       handleClick()
      //   );
      if (form?.subtitle_list?.length > (formStructure?.[3]?.length - 4) / 3)
        form?.subtitle_list.map(
          (ele) =>
            form?.subtitle_list?.length > formStructure?.[3]?.length - 4 - 3 &&
            handleAddSubtitle()
        );
      if (form?.audio_file_list?.length > (formStructure?.[2]?.length - 2) / 3)
        form?.audio_file_list.map(
          (ele) =>
            form?.audio_file_list?.length >
              (formStructure?.[2]?.length - 2) / 3 && handleAddAudioFile()
        );
    } else {
      setView("view_all");

      setForm({
        subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        audio_language: "Inactive",
      });
    }
    const temp = formStructure;
    tempFormStruct.map((arr, i) =>
      arr.map(
        (ele, j) =>
          !["select", "select_multiple"].includes(ele.type) &&
          (temp[i][j] = ele)
      )
    );
    // temp[4][0]["handleClick"] = handleClick;
    temp[3][4]["handleClick"] = handleAddSubtitle;
    temp[2][2]["handleClick"] = handleAddAudioFile;
    setFormStructure([...temp]);
  }, [isEdit, role, location]);

  const casts = useSelector((state) => state.masters.casts);
  useMemo(() => {
    if (series?.statuscode == 200) {
      const temp = formStructure;
      temp[0][0]["options"] = series?.data.map((series) => series?.series_name);

      setFormStructure([...temp]);
    }
    if (series?.statuscode == 200) {
      const tempTableData = tableData;
      tempTableData["filterColumn"][0]["options"] = series?.data.map(
        (series) => series?.series_name
      );

      setTableData({ ...tempTableData });
    }
  }, [series]);
  // console.log(series,"neeee")
  useMemo(() => {
    if (seasons?.statuscode == 200) {
      const temp = formStructure;
      temp[0][2]["options"] = seasons?.data
        ?.map(
          (season) => season?.series_name == form?.series_name && season?.season
        )
        .filter((e) => e);

      setFormStructure([...temp]);
    }
    if (seasons?.statuscode == 200) {
      const tempTableData = tableData;
      tempTableData["filterColumn"][1]["options"] = seasons?.data;
      setTableData({ ...tempTableData });
    }
  }, [seasons, form?.series_name]);
  console.log(seasons, "858585");
  useMemo(() => {
    if (casts?.statuscode == 200) {
      const temp = formStructure;
      temp[0][8]["options"] = casts?.data.map((cast) => cast?.cast_name);

      setFormStructure([...temp]);
    }
  }, [casts]);
  useMemo(() => {
    if (episodes?.statuscode == 200) {
      const temp = formStructure;
      temp[1][9]["options"] = episodes.data.map(
        (episode) => episode?.episode_title
      );

      setFormStructure([...temp]);
    }
  }, [episodes]);
  useMemo(() => {
    if (language?.statuscode == 200) {
      const temp = formStructure;

      temp[3][5]["options"] = language?.data.map(
        (language) => language?.language_name
      );
      temp[2][3]["options"] = language?.data.map(
        (language) => language?.language_name
      );
      setFormStructure([...temp]);
    }
  }, [language]);
  // useMemo(() => {
  //   if (advertisements?.statuscode == 200) {
  //     const temp = formStructure;
  //     const currentDate = new Date();
  //     temp[4][1]["options"] = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.status == "Active" && advertisement?.product_name
  //       )
  //       .filter((e) => e);

  //     setFormStructure([...temp]);
  //   }
  // }, [advertisements]);
  useMemo(() => {
    if (form?.subtitles == "Active") {
      const temp = formStructure;
      temp[3].map((ele, index) => index >= 4 && (ele["display"] = "block"));
      setForm({ ...form, subtitle_count: 1 });
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[3].map((ele, index) => index >= 4 && (ele["display"] = "none"));
      setFormStructure([...temp]);
    }
  }, [form?.subtitles]);
  useMemo(() => {
    if (form?.audio_language == "Active") {
      const temp = formStructure;
      temp[2].map((ele, index) => index >= 2 && (ele["display"] = "block"));
      setForm({ ...form });
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[2].map((ele, index) => index >= 2 && (ele["display"] = "none"));
      setFormStructure([...temp]);
    }
  }, [form?.audio_language]);
  useMemo(() => {
    if (form?.publish_type == "Later") {
      const temp = formStructure;
      temp[0][6]["display"] = "block";
      temp[0][7]["display"] = "block";

      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][6]["display"] = "none";
      temp[0][7]["display"] = "none";
      setFormStructure([...temp]);
    }
  }, [form?.publish_type]);

  const message = useSelector((state) => state.layout.message);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(form, "6565");
    // const advertise = [];
    // for (var i = 0; i < formStructure[4].length / 7; i++) {
    //   advertise.push({
    //     advertise: advertisements?.data
    //       .map(
    //         (advertisement) =>
    //           advertisement?.product_name == form?.["advertise" + i] &&
    //           advertisement?.id
    //       )
    //       .filter((e) => e)[0],
    //     time: form?.["time" + i],
    //   });
    // }
    const tempForm = form;
    tempForm["tempseries_name"] = form?.["series_name"];
    tempForm["tempseries_cast"] = form?.["series_cast"];
    tempForm["tempseason"] = form?.["season"];
    // tempForm["tempepisode_suggestion"] = form?.["episode_suggestion"];
    // tempForm["episode_suggestion"] = episodes?.data
    //   .map(
    //     (option) =>
    //       form?.["episode_suggestion"]?.includes(option?.episode_title) &&
    //       option.id
    //   )
    //   .filter((e) => e);
    tempForm["series_name"] = series?.data
      .map(
        (option) =>
          form?.["series_name"]?.includes(option.series_name) && option.id
      )
      .filter((e) => e);
    tempForm["series_cast"] = casts?.data
      .map(
        (option) =>
          form?.["series_cast"]?.includes(option.cast_name) && option.id
      )
      .filter((e) => e);
    tempForm["season"] = seasons?.data
      ?.map((option) =>
        form?.["series_name"] == option?.series
          ? form?.["season"]?.includes(option?.season) && option?.id
          : null
      )
      .filter((e) => e);

    tempForm["uploaded_by"] = user?.id;
    // tempForm["advertise"] = JSON.stringify(advertise);
    setForm({
      ...tempForm,
    });

    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    formStructure.map((structure) =>
      structure.map((element) => {
        if (
          element.type == "image" &&
          form?.[element.name] &&
          typeof form?.[element.name] != "string"
        ) {
          const temp = form;
          temp["temp" + element.name] = form?.[element.name];
          temp[element.name] = URL.createObjectURL(form?.[element.name]);

          setForm({
            ...temp,
          });
        }
      })
    );
    const temp = tableData;
    if (isEdit) {
      dispatch(episode_update(data));
    } else {
      dispatch(episode_create(data));
    }
    setFlag(true);
  };
  console.log(episodes, "654654");
  useMemo(() => {
    if (message?.statuscode == 200 && flag) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });

      setIsEdit(false);
      setFormStructure([...tempFormStruct]);
      setForm({
        subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        audio_language: "Inactive",
      });
      setFlag(false);

      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);
        dispatch(all_episode_list(data));
        navigate("/Episode/Episode/", { state: { view: "view_all" } });
        setView("view_all");
      }, 1000);
      setView("view_all");
    } else {
      const tempForm = form;
      tempForm["series_name"] = form?.tempseries_name;
      tempForm["series_cast"] = form?.tempseries_cast;
      tempForm["season"] = form?.tempseason;
      tempForm["episode_suggestion"] = form?.episode_suggestion
      tempForm["episode_thumbnail"] = form?.tempepisode_thumbnail;
      tempForm["episode_poster"] = form?.tempepisode_poster;
      setForm({ ...tempForm });
    }
  }, [message]);

  const videoEl = useRef(null);
  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    var d = Number(video.duration);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = (h > 9 ? h : "0" + h) + ":";
    var mDisplay = (m > 9 ? m : "0" + m) + ":";
    var sDisplay = s > 9 ? s : "0" + s;

    setForm({ ...form, episode_duration: hDisplay + mDisplay + sDisplay });
  };

  useMemo(() => {
    if (result) {
      const temp = formStructure;
      // if (adPosition != undefined) {
      //   temp[4].map((value, index) => {
      //     if (value.position == adPosition) {
      //       const tempForm = form;
      //       tempForm["advertise" + value.position] = "";
      //       tempForm["duration" + value.position] = "";
      //       tempForm["time" + value.position] = "";
      //       tempForm["company_name" + value.position] = "";
      //       tempForm["views_required" + value.position] = "";
      //       tempForm["available_views" + value.position] = "";
      //       setForm({ ...tempForm });
      //       temp[4].splice(index - 6, 7);
      //     }
      //   });
      //   setFormStructure([...temp]);
      //   setAdPosition(undefined);
      // } else
      if (subtitlePosition != undefined) {
        temp[3].map((value, index) => {
          if (value.position == subtitlePosition) {
            const tempForm = form;
            tempForm["subtitle_language" + value.position] = "";
            tempForm["subtitle_file" + value.position] = "";
            setForm({ ...tempForm });
            temp[3].splice(index - 2, 3);
          }
        });
        setFormStructure([...temp]);
        setSubtitlePosition(undefined);
        dispatch(episode_subtitle_delete({ id: subTitleID }));
      } else if (audioPosition != undefined) {
        temp[2].map((value, index) => {
          if (value.position == audioPosition) {
            console.log(value.position, "8888");
            const tempForm = form;
            tempForm["audio_file_" + value.position] = "";
            tempForm["audio_language_" + value.position] = "";
            setForm({ ...tempForm });
            temp[2].splice(index - 2, 3);
          }
        });
        setFormStructure([...temp]);
        setSubtitlePosition(undefined);
        dispatch(episode_audio_file_delete({ id: audioFileId }));
      }
    }
    setResult(undefined);
  }, [result]);
  useEffect(() => {
    const temp = formStructure;
    // temp[4][0]["handleClick"] = handleClick;
    temp[3][4]["handleClick"] = handleAddSubtitle;
    temp[2][2]["handleClick"] = handleAddAudioFile;
    setFormStructure([...temp]);
  }, []);
  // useMemo(() => {
  //   if (form?.advertise0 != undefined) {
  //     const temp = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.product_name == form?.advertise0 && advertisement
  //       )
  //       .filter((e) => e)[0];

  //     setForm({
  //       ...form,
  //       duration0: temp?.duration,
  //       company_name0: temp?.advertiser?.company_name,
  //       views_required0: temp?.views_required,
  //       available_views0: temp?.views_required - temp?.no_of_views,
  //     });
  //   }
  // }, [form?.advertise0]);
  // useMemo(() => {
  //   if (form?.advertise1 != undefined) {
  //     const temp = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.product_name == form?.advertise1 && advertisement
  //       )
  //       .filter((e) => e)[0];

  //     setForm({
  //       ...form,
  //       duration1: temp?.duration,
  //       company_name1: temp?.advertiser?.company_name,
  //       views_required1: temp?.views_required,
  //       available_views1: temp?.views_required - temp?.no_of_views,
  //     });
  //   }
  // }, [form?.advertise1]);
  // useMemo(() => {
  //   if (form?.advertise2 != undefined) {
  //     const temp = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.product_name == form?.advertise2 && advertisement
  //       )
  //       .filter((e) => e)[0];

  //     setForm({
  //       ...form,
  //       duration2: temp?.duration,
  //       company_name2: temp?.advertiser?.company_name,
  //       views_required2: temp?.views_required,
  //       available_views2: temp?.views_required - temp?.no_of_views,
  //     });
  //   }
  // }, [form?.advertise2]);
  // useMemo(() => {
  //   if (form?.advertise3 != undefined) {
  //     const temp = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.product_name == form?.advertise3 && advertisement
  //       )
  //       .filter((e) => e)[0];

  //     setForm({
  //       ...form,
  //       duration3: temp?.duration,
  //       company_name3: temp?.advertiser?.company_name,
  //       views_required3: temp?.views_required,
  //       available_views3: temp?.views_required - temp?.no_of_views,
  //     });
  //   }
  // }, [form?.advertise3]);
  // useMemo(() => {
  //   if (form?.advertise4 != undefined) {
  //     const temp = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.product_name == form?.advertise4 && advertisement
  //       )
  //       .filter((e) => e)[0];

  //     setForm({
  //       ...form,
  //       duration4: temp?.duration,
  //       company_name4: temp?.advertiser?.company_name,
  //       views_required4: temp?.views_required,
  //       available_views4: temp?.views_required - temp?.no_of_views,
  //     });
  //   }
  // }, [form?.advertise4]);

  const handleClose = () => {
    setOpenAdError(false);
  };
  // useMemo(() => {
  //   const times = [
  //     form?.time0,
  //     form?.time1,
  //     form?.time2,
  //     form?.time3,
  //     form?.time4,
  //   ];
  //   const temp = times.map((ele1, index1) => {
  //     for (var i = 0; i < times.length; i++) {
  //       if (index1 != i && ele1 == times[i]) {
  //         return undefined;
  //       }
  //     }

  //     return ele1;
  //   });
  //   if (JSON.stringify(times) != JSON.stringify(temp)) {
  //     setOpenAdError(true);
  //     setPopupContent(
  //       "You have already added the advertisement for the same duration"
  //     );
  //   }
  //   setForm({
  //     ...form,
  //     time0: temp[0],
  //     time1: temp[1],
  //     time2: temp[2],
  //     time3: temp[3],
  //     time4: temp[4],
  //   });
  // }, [form?.time0, form?.time1, form?.time2, form?.time3, form?.time4]);

  useMemo(() => {
    var temp = form;

    if (isEdit) {
      var flag = true;
      for (var i = 0; i < episodes?.data.length; i++) {
        if (episodes?.data[i]?.id == form?.id) {
          if (
            episodes?.data[i]?.series_name?.series_name == form?.series_name
          ) {
            flag = false;
          }
          break;
        }
      }

      if (flag) {
        temp = {
          subtitles: "Inactive",
          episode_is_for18Plus: "No",
          allow_download: "Yes",
          audio_language: "Inactive",
        };
        temp["id"] = form?.id;
        temp["series_name"] = form?.series_name;
      }
    } else {
      temp = {
        subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        audio_language: "Inactive",
      };
      temp["series_name"] = form?.series_name;
      // temp["season"]=form?.season
    }

    series?.data.filter((series) => {
      if (form?.series_name == series?.series_name)
        temp["series_type"] = series?.series_type;
    });

    setForm({ ...temp });
  }, [form?.series_name]);
  const subtitles = language?.data?.map((ele, index) =>
    eval("form?.subtitle_language_" + index)
  );
  const audioFile = language?.data?.map((ele, index) =>
    eval("form?.audio_language_" + index)
  );
  useMemo(() => {
    const newLanguageList = language?.data
      ?.map(
        (ele, index) =>
          !subtitles.includes(ele?.language_name) && ele?.language_name
      )
      .filter((e) => e);
    const newAudioList = language?.data
      ?.map(
        (ele, index) =>
          !audioFile.includes(ele?.language_name) && ele?.language_name
      )
      .filter((e) => e);
    const temp = formStructure;
    temp[3].map(
      (ele, index) =>
        ele?.type == "select" && (temp[3][index]["options"] = newLanguageList)
    );
    temp[2].map(
      (ele, index) =>
        ele?.type == "select" && (temp[2][index]["options"] = newAudioList)
    );
    setFormStructure([...temp]);
  }, [form]);
  return (
    <>
      <video
        style={{ display: "none" }}
        id="myvid"
        src={form?.episodeMp4_URL}
        ref={videoEl}
        type="video/mp4"
        onLoadedMetadata={handleLoadedMetadata}
      ></video>
      <Popup
        open={open}
        setOpen={setOpen}
        content={content}
        setResult={setResult}
      />
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Episode/CreateEpisode/"}
        view_all={"/Episode/Episode/"}
        form={form}
        access={rights?.["TV Shows"]?.["create"] == "true"}
        export_excel={
          <Export
            fileName={"TV Shows"}
            access={rights?.["TV Shows"]?.["export_excel"] == "true"}
            isClubed={true}
            exportData={tableData?.exportData || tableData?.tableBody}
            headings={tableData.tableHead.map((value) => value.label)}
          />
        }
        reload={<Reload isClubed={true} />}
      />

      {view == "create_new" && (
        <MultiStepForm
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Episode/EditEpisode/"}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
    </>
  );
}
