import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import BankDetails from "../components/BankDetails/BankDetails";

function BankDetailsModule() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/BankDetails/"
          element={<Layout children={BankDetails()} />}
        />
      </Routes>
    </div>
  );
}

export default BankDetailsModule;
