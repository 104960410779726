import * as React from "react";
import { useEffect,useMemo } from "react";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { all_module_listing } from '../../../actions/Masters/subadmin';
import { useDispatch,useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';


export default function RightsTable({form,setForm}) {
 
    const dispatch=useDispatch()
    const modules = useSelector((state) => state.masters.modules)||[]
    useEffect(()=>{
      dispatch(all_module_listing())
    },[])
    
    useMemo(()=>{
        if(modules?.statuscode==200){
            if(form?.rights==undefined||form?.rights.length<=0)
       { const temp=modules?.data.map((ele)=>({module_id:ele.id,view:"true"}))
       
        setForm({...form,rights:temp})}
        }
      },[modules])
      
    const handleChange = (event,index) => {
        var temp=form?.rights || modules?.data.map((ele)=>({module_id:ele.id,view:"true"}))
        temp[index][event.target.name]=event.target.checked?"true":"false"
        
        setForm({...form,rights:temp})
        console.log(form , event.target.checked , "newConsole")
      };
  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          borderRadius: "10px",
          border:"thin solid grey",
          p: "25px 25px 10px",
          mb: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "10px",
          }}
        >
          <Typography
            as="h3"
            sx={{
              fontSize: 18,
              fontWeight: 500,
            }}
          >
            Rights
          </Typography>
          
        </Box>

        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
          }}
        >
          <Table 
            sx={{ minWidth: 950 }} 
            aria-label="custom pagination table"
            className="dark-table"
          >
            <TableHead sx={{ background: "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)" }}>
              <TableRow>
                <TableCell
                  sx={{
                    borderBottom: "1px solid #F7FAFF",
                    fontSize: "13.5px",
                    padding: "15px 10px",
                    width:"20%"
                  }}
                >
                  Module
                </TableCell>

                <TableCell
                align="center"
                  sx={{
                    borderBottom: "1px solid #F7FAFF",
                    fontSize: "13.5px",
                    padding: "15px 10px",
                  }}
                >
                  View
                </TableCell>

                <TableCell
                align="center"
                  sx={{
                    borderBottom: "1px solid #F7FAFF",
                    fontSize: "13.5px",
                    padding: "15px 10px",
                  }}
                >
                  Create
                </TableCell>

                <TableCell
                  align="center"
                  sx={{
                    borderBottom: "1px solid #F7FAFF",
                    fontSize: "13.5px",
                    padding: "15px 10px",
                  }}
                >
                  Update
                </TableCell>

                <TableCell
                  align="center"
                  sx={{
                    borderBottom: "1px solid #F7FAFF",
                    fontSize: "13.5px",
                    padding: "15px 10px",
                  }}
                >
                  Delete
                </TableCell>

                <TableCell
                  align="center"
                  sx={{
                    borderBottom: "1px solid #F7FAFF",
                    fontSize: "13.5px",
                    padding: "15px 10px",
                  }}
                >
                  Import
                </TableCell>

                <TableCell
                  align="center"
                  sx={{
                    borderBottom: "1px solid #F7FAFF",
                    fontSize: "13.5px",
                    padding: "15px 10px",
                  }}
                >
                  Export
                </TableCell>

                
              </TableRow>
            </TableHead>

            <TableBody>
              {modules?.data?.map((row,index) => (
                <TableRow key={row?.id}>
                  <TableCell
                    sx={{
                      width: 100,
                      fontWeight: "500",
                      borderBottom: "1px solid #F7FAFF",
                      padding: "4px 10px",
                      fontSize: "13px"
                    }}
                  >
                    {row?.module_name}
                  </TableCell>

                 

                  <TableCell
                  align="center"
                    sx={{
                      borderBottom: "1px solid #F7FAFF",
                      padding: "4px 10px",
                      fontSize: "13px"
                    }}
                  >
                   <Checkbox inputProps={{ 'aria-label': 'controlled' }} name="view" checked={form?.rights?.[index]?.["view"]=="true"} onChange={(event)=>handleChange(event,index)} />
                  </TableCell>
                  <TableCell
                  align="center"
                    sx={{
                      borderBottom: "1px solid #F7FAFF",
                      padding: "4px 10px",
                      fontSize: "13px"
                    }}
                  >
                   <Checkbox inputProps={{ 'aria-label': 'controlled' }} name="create" checked={form?.rights?.[index]?.["create"]=="true"} onChange={(event)=>handleChange(event,index)} />
                  </TableCell>
                  <TableCell
                  align="center"
                    sx={{
                      borderBottom: "1px solid #F7FAFF",
                      padding: "4px 10px",
                      fontSize: "13px"
                    }}
                  >
                   <Checkbox inputProps={{ 'aria-label': 'controlled' }} name="edit" checked={form?.rights?.[index]?.["edit"]=="true"} onChange={(event)=>handleChange(event,index)} />
                  </TableCell>
                  <TableCell
                  align="center"
                    sx={{
                      borderBottom: "1px solid #F7FAFF",
                      padding: "4px 10px",
                      fontSize: "13px"
                    }}
                  >
                   <Checkbox inputProps={{ 'aria-label': 'controlled' }} name="delete" checked={form?.rights?.[index]?.["delete"]=="true"} onChange={(event)=>handleChange(event,index)} />
                  </TableCell>
                  <TableCell
                  align="center"
                    sx={{
                      borderBottom: "1px solid #F7FAFF",
                      padding: "4px 10px",
                      fontSize: "13px"
                    }}
                  >
                   <Checkbox inputProps={{ 'aria-label': 'controlled' }} name="import_excel" checked={form?.rights?.[index]?.["import_excel"]=="true"} onChange={(event)=>handleChange(event,index)} />
                  </TableCell>
                  <TableCell
                  align="center"
                    sx={{
                      borderBottom: "1px solid #F7FAFF",
                      padding: "4px 10px",
                      fontSize: "13px"
                    }}
                  >
                   <Checkbox inputProps={{ 'aria-label': 'controlled' }} name="export_excel" checked={form?.rights?.[index]?.["export_excel"]=="true"} onChange={(event)=>handleChange(event,index)} />
                  </TableCell>
                 

                </TableRow>
              ))}

             
            </TableBody>

           
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}
