import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
	upcoming_movie_create,
	upcoming_movie_delete,
	upcoming_movie_update,
	all_upcoming_movie_list,
} from "../../../actions/Movie/upcoming_movie";
import { useDispatch, useSelector } from "react-redux";
import { all_category_list } from "./../../../actions/Masters/category";
import { all_subcategory_list } from "../../../actions/Masters/subcategory";
import { all_cast_list } from "../../../actions/Masters/cast";
import { all_genre_list } from "../../../actions/Masters/genre";
import { all_language_list } from "../../../actions/Masters/language";
import { useLocation, useNavigate } from "react-router-dom";
import Reload from "../../utils/Reload";
export default function UpcomingMovie() {
	const user = useSelector((state) => state.layout.profile);
	const dispatch = useDispatch();
	const rights = useSelector((state) => state.layout.rights)
	const location = useLocation();
	const navigate = useNavigate()
	const [view, setView] = useState(location?.state?.view || "view_all");
	const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
	const [isEdit, setIsEdit] = useState(false);
	const [flag, setFlag] = useState(false)

	const tempFormStruct = FormStructure(isEdit)
	const [formStructure, setFormStructure] = useState([...tempFormStruct]);
	const path = location?.pathname.split("/")[2]
	useEffect(() => {
		setView(path != "UpcomingMovie" ? "create_new" : "view_all");
		setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
		setIsEdit(path == "EditUpcomingMovie");
	}, [location]);
	const tempTableData = {
		...TableData(),
		deleteRecord: upcoming_movie_delete,
		updateRecord: upcoming_movie_update,
		onDeleteText: "Are you sure want to delete?",
		onUpdateText: "Are you Sure?",
	}
	const [tableData, setTableData] = useState({ ...tempTableData });
	useMemo(() => {
		setTableData({ ...tempTableData })
	}, [rights])
	useMemo(() => {
		if (isEdit) {
			setView("create_new");
		} else {
			setView("view_all");
			setForm({});
		}
		setFormStructure([...tempFormStruct])
	}, [isEdit]);
	const formTitle = "Upcoming Movies";

	const upcoming_movies = useSelector((state) => state.movies.upcoming_movies);
	// console.log(upcoming_movies, "pppp")
	useEffect(() => {
		dispatch(all_upcoming_movie_list());
	}, []);
	useMemo(() => {
		if (upcoming_movies?.statuscode == 200) {
			const temp = tableData;
			temp.tableBody = upcoming_movies?.data.map((ele) => ({
				...ele,
				movie_category: ele?.movie_category?.category_name,
				movie_subcategory: ele?.movie_subcategory?.subcategory_name,
				movie_language: ele?.movie_language.language_name,
				movie_genre: ele?.movie_genre.map((value) => value?.genre_title),
				movie_cast: ele?.movie_cast.map((value) => value?.cast_name),
				release_date1:
					new Date(ele?.release_date) > new Date() ? (
						<p>{ele?.release_date}</p>
					) : (
						<p style={{ color: "green" }}>RELEASED</p>
					),
			}));
			setTableData({ ...temp });

		}
	}, [upcoming_movies]);

	useEffect(() => {
		dispatch(all_subcategory_list());
		dispatch(all_category_list());
		dispatch(all_cast_list());
		dispatch(all_genre_list());

		dispatch(all_language_list());
	}, []);
	const categories = useSelector((state) => state.masters.categories);
	const subcategories = useSelector((state) => state.masters.subcategories);
	const casts = useSelector((state) => state.masters.casts);
	const genre = useSelector((state) => state.masters.genre);
	const language = useSelector((state) => state.masters.languages);
	// useMemo(() => {
	// 	if (categories?.statuscode == 200) {
	// 		const tempTableData = tableData;
	// 		tempTableData.filterColumn[1]["options"] = categories?.data.map(
	// 			(category) => category?.category_name
	// 		);

	// 		setTableData({ ...tempTableData });
	// 	}
	// 	if (categories?.statuscode == 200) {
	// 		const temp = formStructure;
	// 		temp[0]["options"] = categories?.data.map(
	// 			(category) => category?.category_name
	// 		);

	// 		setFormStructure([...temp]);
	// 	}
	// }, [categories]);

	useMemo(() => {
		if (casts?.statuscode == 200) {
			const temp = formStructure;
			temp[4]["options"] = casts?.data.map((cast) => cast?.cast_name);

			setFormStructure([...temp]);
		}
	}, [casts ,isEdit]);
	useMemo(() => {
		if (genre?.statuscode == 200) {
			const temp = formStructure;
			temp[2]["options"] = genre?.data.map((genre) => genre?.genre_title);

			setFormStructure([...temp]);
		}
	}, [genre ,isEdit]);
	useMemo(() => {
		if (language?.statuscode == 200) {
			const tempTableData = tableData;
			tempTableData.filterColumn[0]["options"] = language?.data.map(
				(language) => language?.language_name
			);

			setTableData({ ...tempTableData });
			const temp = formStructure;
			temp[1]["options"] = language?.data.map(
				(language) => language?.language_name
			);

			setFormStructure([...temp]);
		}
	}, [language ,isEdit]);
	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const temp_movie_category = form;
		temp_movie_category["tempmovie_subcategory"] = form?.["movie_subcategory"];
		temp_movie_category["tempmovie_category"] = form?.["movie_category"];
		temp_movie_category["tempmovie_cast"] = form?.["movie_cast"];
		temp_movie_category["tempmovie_genre"] = form?.["movie_genre"];
		temp_movie_category["tempmovie_language"] = form?.["movie_language"];
		temp_movie_category["movie_subcategory"] = subcategories?.data
			.map(
				(option) =>
					form?.["movie_subcategory"]?.includes(option.subcategory_name) &&
					option.id
			)
			.filter((e) => e);
		temp_movie_category["movie_category"] = categories?.data
			.map(
				(option) =>
					form?.["movie_category"]?.includes(option.category_name) && option.id
			)
			.filter((e) => e);
		temp_movie_category["movie_cast"] = casts?.data
			.map(
				(option) =>
					form?.["movie_cast"]?.includes(option.cast_name) && option.id
			)
			.filter((e) => e);
		temp_movie_category["movie_genre"] = genre?.data
			.map(
				(option) =>
					form?.["movie_genre"]?.includes(option.genre_title) && option.id
			)
			.filter((e) => e);
		temp_movie_category["movie_language"] = language?.data
			.map(
				(option) =>
					form?.["movie_language"]?.includes(option.language_name) && option.id
			)
			.filter((e) => e);


		temp_movie_category["created_by"] = user?.id;
		setForm({
			...temp_movie_category,
		});

		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		formStructure.map((element) => {
			if (
				element.type == "image" &&
				form?.[element.name] &&
				typeof form?.[element.name] != "string"
			) {
				const temp = form;
				temp["temp" + element.name] = URL.createObjectURL(form?.[element.name]);
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		const temp = tableData;
		if (isEdit) {
			dispatch(upcoming_movie_update(data));
		} else {
			dispatch(upcoming_movie_create(data));
		}
		setFlag(true)
	};
	// console.log(upcoming_movies,"456456")
	useMemo(() => {
		if (message?.statuscode == 200 && flag) {
			const temp = tableData;
			if (isEdit) {
				temp.tableBody.map(
					(value, index) =>
						value.id == form.id && (temp.tableBody[index] = { ...form })
				);
			} else {
				temp.tableBody[temp.tableBody.length] = {
					id: temp.tableBody.length,
					...form,
					edit: temp.tableBody.length,
				};
			}
			setTableData({ ...temp });

			setIsEdit(false);
			setForm({});
			setFlag(false)
			setTimeout(() => {
				dispatch(all_upcoming_movie_list());
				navigate("/Movie/UpcomingMovie/", { state: { view: "view_all" } })
			}, 1000);
			setView("view_all");
		} else {
			const tempForm = form;

			tempForm["movie_subcategory"] = form?.tempmovie_subcategory;
			tempForm["movie_category"] = form?.tempmovie_category;
			tempForm["movie_cast"] = form?.tempmovie_cast;
			tempForm["movie_genre"] = form?.tempmovie_genre;
			tempForm["movie_language"] = form?.tempmovie_language;
			tempForm["movie_thumbnail"] = form?.tempmovie_thumbnail;
			tempForm["movie_poster"] = form?.tempmovie_poster;
			setForm({ ...tempForm });
		}
	}, [message]);
	return (
		<>
			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
				create_new={"/Movie/CreateUpcomingMovie"}
				view_all={"/Movie/UpcomingMovie"}
				form={form}
				reload={<Reload isClubed={true} />}
				access={rights?.["Upcoming Movie"]?.["create"] == "true"}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					create_new={"/Movie/EditUpcomingMovie"}
				/>
			)}
		</>
	);
}
