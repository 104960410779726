import React from "react";
import { Stack, Box, Typography, Link } from "@mui/material";

const Footer = () => {
  return (
    <>
      <Stack
        sx={{

          // backgroundColor: "#fff",
          p: "25px",
          borderRadius: "10px 10px 0 0",
          textAlign: "center",
          mt: "15px"
        }}
        className="footer"
      >
        <Box >
          <Typography>
          Designed and Developed by < span style={{color:"#e35466" , marginLeft:"5px" , marginRight:"5px"}} target="_blank">Imagine OTT.</span>  ( AI Recruiter Inc ) ,All rights reserved 2024
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

export default Footer;
