import React from "react";

export const FormStructure = (isEdit) => {
  return [
    {
      id: "3",
      type: "inputBox",
      title: "Product Name",
      name: "product_name",
      maxLength: 30,
      regex: /^[a-zA-Z0-9\s\&]+$/,
      required: true,
    },

    {
      id: "4",
      type: "inputBox",
      title: "Add Advertisement Video URL (.mp4)",
      endsWith: ".mp4",
      errorText: "File extension should be .mp4 only",
      name: "advertise_url",
      required: true,
    },
    {
      id: "5",
      type: "select",
      title: "Subscription Type",
      name: "subscription_type",
      options: ["Recharge Based", "Pay Per View"],
      required: true,
    },
    {
      id: "2",
      type: "select",
      title: "Pay per view (%)",
      name: "tvod_amount",
      options: [
        "0.10",
        "0.20",
        "0.30",
        "0.40",
        "0.50",
        "0.60",
        "0.70",
        "0.80",
        "0.90",
        "1.0",
      ],
      required: true,
    },

    {
      id: "5",
      type: "inputBox",
      title: "Recharge amount",
      name: "recharge_amount",
      // variant: "number",
      maxLength: "6",
      regex: /^[0-9\s\&]+/,

      // max: "999000",
      // min: "0.00",
      step: ".01",
      display: "none",
    },
    {
      id: "7",
      type: "inputBox",
      // variant: "number",
      regex: /^[0-9\s\&]+/,
      title: "No of Views Required",
      name: "views_required",
      maxLength: "6",
      required: true,
    },

    {
      id: "5",
      type: "inputBox",
      title: "Payable amount",
      name: "recharge_amount",
      regex: /^[0-9\s\&]+/,

      // variant: "number",
      // max: "999000",
      // min: "0.00",
      maxLength: "6",
      step: ".01",
    },
    {
      id: "23",
      type: "toggle",
      title: "Admin's Approval",
      name: "status",
      // default: "Inactive",
      size: "6",
      display: "none",
      options: [
        { value: "Approved", color: "success" },
        { value: "Rejected", color: "danger" },
      ],
    },

    {
      id: "5",
      type: "inputBox",
      title: "Reject Reason",
      name: "reject_message",
      required: true,
      display: "none",
    },
    {
      id: "5",
      type: "image",
      title: "Upload Product Image",
      name: "thumbnail",
      subtitle: "(Resolution : 512px x 512px) *",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      size: "4",
      required: true,
    },
    {
      id: "9",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ];
};
