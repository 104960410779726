import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Export from "../utils/Export";
import {
  customer_delete,
  customer_update,
  all_customer_list,
} from "./../../actions/customer";
import { all_subscription_list } from "../../actions/subscription";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import notification_icon from "../../images/notification_icon.png"


export default function Customer() {
  const dispatch = useDispatch();
  const rights = useSelector((state) => state.layout.rights);
  console.log(rights);
  const [tableData, setTableData] = useState({
    tableTitle: "Customers",
    deleteRecord: customer_delete,
    updateRecord: customer_update,
    deleteAccess: true,
    onDeleteText: "Are you sure want to delete this customer?",
    customisedStatusUpdateMessage: true,
    onActiveText: "Are you sure you want to activate this customer?",
    onInactiveText: [
      "Are you sure you want to inactivate this customer? ",
      "The customer will no longer be logged into the system once you inactivate it.",
    ],
    tableHead: [
      {
        id: "name",
        label: "Name",
        link: "/Customer/CustomerDetail/CustomerDetail",
        color: "var(--gradientColor2)",
      },
      {
        id: "email",
        label: "Customer Info",
        subText: "mobile",
      },
      {
        id: "socialType1",
        label: "Login By",
      },
      {
        id: "subscription",
        label: "Subscription",
      },
      {
        id: "deviceType",
        label: "Device",
        subText: "deviceId",
      },
      {
        id: "userLocation",
        label: "Location",
      },
      {
        id: "created_at",
        label: "Registered On",
      },
      {
        id: "notification",
        label: "Notification",
        isSpecial: true,
        align: "center",
      },
      // {
      //   id: "status",
      //   label: "Status",
      // },
    ],
    tableBody: [
      {
        id: 0,
        name: "Movie",
        email: "Landscape",
        mobile_no: "2",
        plan: "Active",
        login_by: "Active",
        device_type: "Active",
        registered_on: "Active",
        status: "Active",
        edit: 0,
      },
    ],
    filterColumn: [
      {
        id: "1",
        title: "Plan Type",
        name: "subscription",
        options: ["FREE", "TVOD", "SVOD"],
      },
      {
        id: "1",
        title: "Login By",
        name: "socialType1",
        options: ["Gmail", "AppleID", "Mobile", "WhatsApp"],
      },
      {
        id: "1",
        title: "Device Type",
        name: "deviceType",
        options: ["IOS", "Android"],
      },
    ],
    isDateRangeFilter: "created_at",
  });
  const [form, setForm] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const customers = useSelector((state) => state.customers.customers);
  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );

  useEffect(() => {
    dispatch(all_subscription_list());
    dispatch(all_customer_list());
  }, []);
  useMemo(() => {
    if (customers?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = customers?.data.map((ele) => ({
        ...ele,
        name: ele?.firstName + " " + ele?.lastName,
        subscription: ele?.subscription?.plan_name||"Free",
        mobile: ele?.mobileNumber !== null ? ele?.mobileNumber : " - ",
        socialType1:
          ele?.socialType == "Google"
            ? "Gmail"
            : ele?.socialType == "AppleID"
            ? "AppleID"
            : ele?.socialType == null
            ? "Mobile"
            : "WhatsApp",
			notification: <Link to='/Notifications/CreateNotifications/' state={{customer: ele?.id,send :"nofitication"}}><img src={notification_icon} alt="Notifications" height={"25px"} style={{marginRight:"5px"}} /></Link>

      }));
      setTableData({ ...temp });
    }
  }, [customers]);
  console.log(customers, "8585");
  useMemo(() => {
    if (subscriptions?.statuscode == 200) {
      const temp = tableData;
      temp.filterColumn[0]["options"] = subscriptions?.data.map(
        (ele) => ele?.plan_name
      );
      setTableData({ ...temp });
    }
  }, [subscriptions]);

  return (
    <>
      <Export
        fileName={"Customers"}
        access={rights?.["Customers"]?.["export_excel"] == "true"}
        exportData={tableData?.exportData || tableData?.tableBody}
        headings={tableData.tableHead.map((value) => value.label)}
      />

      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
      />
    </>
  );
}
