import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import { genre_create, genre_delete, genre_update, all_genre_list } from "../../../actions/Masters/genre";
import { useDispatch, useSelector } from 'react-redux';

import { useLocation ,useNavigate } from "react-router-dom";
import Reload from "../../utils/Reload";
export default function Genre() {
	const user = useSelector((state) => state.layout.profile)
	const dispatch = useDispatch()
	const rights = useSelector((state) => state.layout.rights)
	const location = useLocation();
	const navigate = useNavigate()
	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
	const [view, setView] = useState(location?.state?.view || "view_all");
	useMemo(() => {
		if (isEdit) { setView("create_new") }
		else {
			setView("view_all")
			setForm({});
		}
	}, [isEdit]);
	const path = location?.pathname.split("/")[3]
	useEffect(() => {
		setView(path != "Genre" ? "create_new" : "view_all");
		setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
		setIsEdit(path == "EditGenre");
	}, [location]);
	const tempTableData = {
		tableTitle: "Genre", deleteRecord: genre_delete,
		updateRecord: genre_update,
		deleteAccess: rights?.["Masters"]?.["delete"] == "true",
		onDeleteText: "Are you sure want to delete?",
		onUpdateText: "Are you Sure?",
		tableHead: [
			{
				id: "genre_title",
				numeric: false,
				disablePadding: true,
				label: "Genre",
			},
			{
				id: "genre_image",
				label: "Image",
				isImage: true
			},

			{
				id: "edit",
				label: "Update",
				access: rights?.["Masters"]?.["edit"] == "true"
			},
		],
		tableBody: [
			{
				id: 0,
				genre: "Movie",
				image: "Landscape",
				status: "Active",
				edit: 0,
			},
		],
	}
	const [tableData, setTableData] = useState({ ...tempTableData });
	useMemo(() => {
		setTableData({ ...tempTableData })
	}, [rights])
	const formStructure = [
		{
			id: "1",
			type: "inputBox",
			title: "Add Genre",
			name: "genre_title",
			maxLength: 15,
			regex: /^[a-zA-Z\s/&]+$/,
			required: true
		},

		{ type: "undefined" },
		{
			id: "3",
			type: "image",
			title: "Image",
			name: "genre_image",
			subtitle: "(Resolution : 512px x 512px) *",
			subsubsubtitle: "Support only JPG,PNG,JPEG",
			subsubtitle: "Max File Size 1MB",
		},

		{
			id: "5",
			type: "button",
			title: isEdit ? "Edit" : "Create",
		},
	];

	const genre = useSelector((state) => state.masters.genre);
	useEffect(() => {
		dispatch(all_genre_list())
	}, [])
	useMemo(() => {

		if (genre?.statuscode == 200) {
			const temp = tableData
			temp.tableBody = genre?.data
			setTableData({ ...temp })
		}
	}, [genre])


	const formTitle = isEdit ? "Edit Genre" : "Create Genre";


	const message = useSelector((state) => state.layout.message);
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		data.append("user", user?.id);
		formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});

		const temp = tableData;
		if (isEdit) {

			dispatch(genre_update(data))
		}
		else {
			dispatch(genre_create(data))
		}

	};
	useMemo(() => {
		if (message?.statuscode == 200) {
			const temp = tableData;
			if (isEdit) {
				temp.tableBody.map(
					(value, index) =>
						value.id == form.id && (temp.tableBody[index] = { ...form })
				);

			} else {
				temp.tableBody[temp.tableBody.length] = {
					id: temp.tableBody.length,
					...form,
					edit: temp.tableBody.length,
				};

			}
			setTableData({ ...temp });
			setIsEdit(false);
			setForm({});
			setTimeout(() => {
				dispatch(all_genre_list());
				navigate("/Masters/Genre/Genre/", { state: { view: "view_all" } })
				setView("view_all");
			}, 1000);
		}
		else {

			setForm({ ...form, genre_image: form?.tempgenre_image });
		}
	}, [message])
	return (
		<>

			<ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
				create_new={"/Masters/Genre/CreateGenre/"}
				view_all={"/Masters/Genre/Genre/"}
				form={form}
				reload={<Reload isClubed={true} />}
				access={rights?.["Masters"]?.["create"] == "true"}
			/>

			{view == "create_new" && (
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					tableData={tableData}
					setTableData={setTableData}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
				/>
			)}
			{view == "view_all" && (
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
					create_new={"/Masters/Genre/EditGenre/"}
				/>
			)}
		</>
	);
}
