

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import SetSeriesAdvertisement from '../components/SetSeriesAdvertisement/SetSeriesAdvertisement'

function SetSeriesAdvertisementModule() {
  return (
    <div className="App">

      <Routes>
        < Route path='/SetSeriesAdvertisement' element={<Layout children={SetSeriesAdvertisement()} />} />
        < Route path='/EditSetSeriesAdvertisement' element={<Layout children={SetSeriesAdvertisement()} />} />
        < Route path='/CreateSetSeriesAdvertisement' element={<Layout children={SetSeriesAdvertisement()} />} />

    
      </Routes>
    </div>
  );
}

export default SetSeriesAdvertisementModule;
