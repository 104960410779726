import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import Promotion from "../components/Promotion/Promotion";

function PromotionModule() {
  return (
    <div className="App">
      <Routes>
        <Route path="/Promotion" element={<Layout children={Promotion()} />} />
        <Route
          path="/CreatePromotion"
          element={<Layout children={Promotion()} />}
        />
        <Route
          path="/EditPromotion"
          element={<Layout children={Promotion()} />}
        />
      </Routes>
    </div>
  );
}

export default PromotionModule;
