import React from "react";
import { useState,useEffect,useMemo } from "react";
import Export from "../../utils/Export";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "./../../utils/Table";
import { all_subscribed_customer_list } from '../../../actions/Report/subscribed_customer';
import { useDispatch,useSelector } from 'react-redux';

export default function SubscribedCustomer() {
	const dispatch=useDispatch()
	const [tableData,setTableData]= useState({

		tableTitle: "Subscribed Customers :03",
		disableDelete:true,
		tableHead: [
			{
				id: "user_name",
				label: "User Name",
			},
			{
				id: "email_id",
				label: "Email Id",
			},
			{
				id: "mobile_no",
				label: "Mobile No",
			},
			{
				id: "plan_type",
				label: "Plan Type",
			},
			
			{
				id: "payment_date",
				label: "Payment Date",
			},
			{
				id: "expire_on",
				label: "Expire On",
			},
			{
				id: "device_type",
				label: "Device Type",
			}
		],
		tableBody: [
			{
				id: 0,
				user_name: "Movie",
				email_id: "Landscape",
				mobile_no: "2",
				plan_type: "Active",
				payment_date: "Active",
				expire_on: "Active",
				device_type: 0,
			},
		],
	});

	
    const subscribed_customers = useSelector((state) => state.masters.subscribed_customers);
    useEffect(()=>{
      dispatch(all_subscribed_customer_list())
    },[])
    useMemo(()=>{
      
      if(subscribed_customers?.statuscode==200){const temp=tableData
      temp.tableBody=subscribed_customers?.data
      setTableData({...temp,tableTitle:"Subscribed Customers : "+temp.tableBody.length})}
    },[subscribed_customers])
	

	
	
	return (
		<>
			
			
			<Export fileName={"Registered Customers"} exportData={tableData?.exportData||tableData?.tableBody} headings={tableData.tableHead.map((value)=>value.label)}/>
	
		
	
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setTableData={setTableData}
					
				/>
		
		</>
	);
}
