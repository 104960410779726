
import { MESSAGE,COUPONS,COUPONHISTORY } from '../constants/actionTypes';
import * as api from '../api/index.js';


export const coupon_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.coupon_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const coupon_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.coupon_update(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const coupon_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.coupon_delete(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const all_coupon_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_coupon_list(formData);
      
      dispatch({ type: COUPONS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const coupon_details = (formData) => async (dispatch) => {
    try {
      const { data } = await api.coupon_details(formData);
      console.log(data,"actions")
      dispatch({ type: COUPONHISTORY, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  

  