
import { WATCHHOURS } from './../constants/actionTypes';
import * as api from './../api/index.js';



  export const all_watchhour_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_watchhour_list(formData);
      
      dispatch({ type: WATCHHOURS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  