

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import SetMovieAdvertisement from '../components/SetMovieAdvertisement/SetMovieAdvertisement'

function SetMovieAdvertisementModule() {
  return (
    <div className="App">

      <Routes>
        < Route path='/SetMovieAdvertisement' element={<Layout children={SetMovieAdvertisement()} />} />
        < Route path='/EditSetMovieAdvertisement' element={<Layout children={SetMovieAdvertisement()} />} />
        < Route path='/CreateSetMovieAdvertisement' element={<Layout children={SetMovieAdvertisement()} />} />

    
      </Routes>
    </div>
  );
}

export default SetMovieAdvertisementModule;
