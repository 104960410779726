
import { MESSAGE,EPISODES,EPISODE,EPISODE_WATCH_USER ,EPSUBTITLEDELETE ,EPAUDIOFILEDELETE} from '../../constants/actionTypes';
import * as api from '../../api/index.js';


export const episode_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.episode_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const episode_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.episode_update(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const episode_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.episode_delete(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const all_episode_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_episode_list(formData);
      
      dispatch({ type: EPISODES, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const episode_details = (formData) => async (dispatch) => {
    try {
      const { data } = await api.episode_details(formData);
      dispatch({ type: EPISODE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const episode_watch_user_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.episode_watch_user_list(formData);
      dispatch({ type: EPISODE_WATCH_USER, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const episode_audio_file_delete = (formData) => async (dispatch) =>{
    try{
      const {data} = await api.episode_audio_file_delete(formData);
      dispatch({type: EPAUDIOFILEDELETE, payload:data})
    }
    catch (error) {
      console.log(error);
    }
  }
  export const episode_subtitle_delete = (formData) => async (dispatch) =>{
    try{
      const {data} = await api.episode_subtitle_delete(formData);
      dispatch({type: EPSUBTITLEDELETE, payload:data})
    }
    catch (error) {
      console.log(error);
    }
  }
  