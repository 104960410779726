
import { MESSAGE,DISTRIBUTORS } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const distributor_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.distributor_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const distributor_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.distributor_update(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const distributor_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.distributor_delete(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const all_distributor_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_distributor_list(formData);
      
      dispatch({ type: DISTRIBUTORS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  