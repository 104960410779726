import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import DashboardIcon from "./../../../images/dashboard_SideMenu.png";
import episode from "./../../../images/episode_SideMenu.png";
import WatchhoursIcon from "./../../../images/watchhours_SideMenu.png";
import TransactionIcon from "./../../../images/Transactions_SideMenu.png";
import SubscriptionIcon from "./../../../images/subscription_SideMenu.png";
import Promotion from "./../../../images/Promotion_SideMenu.png";
import PromocodeIcon from "./../../../images/Promocode_SideMenu.png";
import MovieIcon from "./../../../images/Movie_SideMenu.png";
import MastersIcon from "./../../../images/Masters_SideMenu.png";
import LiveStreamingIcon from "./../../../images/LiveStreaming_SideMenu.png";
import SeriesIcon from "./../../../images/Series_SideMenu.png";
import SeasonIcon from "./../../../images/Season_SideMenu.png";
import AdvertisementIcon from "./../../../images/Advertisement_SideMenu.png";
import CustomersIcon from "./../../../images/customers_SideMenu.png";
import ComplaintIcon from "./../../../images/Complaint_SideMenu.png";
import SettingIcon from "./../../../images/Setting_SideMenu.png";
import SliderBannerIcon from "./../../../images/SliderBanner_SideMenu.png";
import DistributorsIcon from "./../../../images/Distributors_SideMenu.png";
import Movie_Submission from "./../../../images/Movie_Submission_SideMenu.png";

import { useSelector } from "react-redux";
export const SidebarData = () => {
  const rights = useSelector((state) => state.layout.rights);
  console.log(rights, "mmmmm");
  const role = useSelector((state) => state.layout.role);
  return [
    role != "Advertiser" && {
      title: "Dashboard",
      path: "/Dashboard/Dashboard",
      icon: <img src={DashboardIcon} height={"30px"} />,
      iconClosed: <KeyboardArrowRightIcon />,
      iconOpened: <KeyboardArrowDownIcon />,
      access: "true",
    },
  {
      title: "Analytics",
      path: "/Analytics/",
      icon: <img src={DashboardIcon} height={"30px"} />,
      iconClosed: <KeyboardArrowRightIcon />,
      iconOpened: <KeyboardArrowDownIcon />,
      access: "true",
    },
    role == "Producer" && {
      title: "Movies",
      path: "/Movie/Movie",
      icon: <img src={MovieIcon} height={"30px"} />,
      iconClosed: <KeyboardArrowRightIcon />,
      iconOpened: <KeyboardArrowDownIcon />,
      access: rights?.["Movie"]?.["view"],
    },
    role != "Advertiser" &&
      role != "Producer" && {
        title: "Movies",
        path: "/Movie/Movie/",
        icon: <img src={MovieIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Movie"]?.["view"],
        // subNav: [
        //   {

        //     title: "Movie",
        //     path: "/Movie/Movie/",
        //     access:rights?.["Movie"]?.["view"]
        //   },
        //  {
        //     title: "Upcoming Movies",
        //     path: "/Movie/UpcomingMovie/",
        //     access:rights?.["Upcoming Movie"]?.["view"]
        //   },

        // ],
      },
    role == "Producer" && {
      title: "Series",
      path: "/Series/Series",
      icon: <img src={SeriesIcon} height={"30px"} />,
      iconClosed: <KeyboardArrowRightIcon />,
      iconOpened: <KeyboardArrowDownIcon />,
      access: rights?.["TV Shows"]?.["view"],
    },

    role != "Producer" &&
      role != "Advertiser" && {
        title: "Web Series",
        path: "/Series/",
        icon: <img src={SeriesIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["TV Shows"]?.["view"],
        subNav: [
          {
            title: "Series",
            path: "/Series/Series",
            access: rights?.["TV Shows"]?.["view"],
          },
          {
            title: "Season",
            path: "/Season/Season/",
            access: rights?.["TV Shows"]?.["view"],
          },
          {
            title: "Episode",
            path: "/Episode/Episode/",
            access: rights?.["TV Shows"]?.["view"],
          },
        ].filter((e) => e),
      },
    // role!="Advertiser"&&role!="Producer"&&{
    //   title: "Season",
    //   path: "/Season/Season/",
    //   icon: <img src={SeasonIcon} height={"30px"}/>,
    //   iconClosed: <KeyboardArrowRightIcon />,
    //   iconOpened: <KeyboardArrowDownIcon />,
    //   access:rights?.["TV Shows"]?.["view"]
    // },
    // role!="Advertiser"&&role!="Producer"&&{
    //   title: "Episode",
    //   path: "/Episode/Episode/",
    //   icon: <img src={episode} height={"30px"}/>,
    //   iconClosed: <KeyboardArrowRightIcon />,
    //   iconOpened: <KeyboardArrowDownIcon />,
    //   access:rights?.["TV Shows"]?.["view"]
    // },
    role != "Advertiser" &&
      role != "Producer" && {
        title: "Real time Movie Tracking",
        path: "/LiveMovieView/LiveMovieView/",
        icon: <img src={episode} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: "true",
      },

    role != "Producer" &&
      role != "Advertiser" && {
        title: "Slider Banner",
        path: "/SliderBanner/SliderBanner",
        icon: <img src={SliderBannerIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Slider Banner"]?.["view"],
      },
    role != "Producer" &&
      role != "Advertiser" && {
        title: "Customers",
        path: "/Customer/Customer",
        icon: <img src={CustomersIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Customers"]?.["view"],
      },
    role != "Producer" &&
      role != "Advertiser" && {
        title: "Subscriptions",
        path: "/Subscriptions/Subscriptions/",
        icon: <img src={SubscriptionIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Subscription"]?.["view"],
      },
    role != "Producer" &&
      role != "Advertiser" && {
        title: "All Transactions",
        path: "/Transactions/Transactions/",
        icon: <img src={TransactionIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Transactions"]?.["view"],
      },
    role != "Producer" &&
      role != "Advertiser" && {
        title: "Promotions",
        path: "/Promotion/Promotion/",
        icon: <img src={Promotion} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Promotions"]?.["view"],
      },
    role != "Producer" &&
      role != "Advertiser" && {
        title: "Complaints",
        path: "/Complaint/Complaint/",
        icon: <img src={ComplaintIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Complaints"]?.["view"],
      },

    role != "Producer" &&
      role != "Advertiser" && {
        title: "Collaborators",
        path: "/Distributors/Distributors/",
        icon: <img src={DistributorsIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Collaborators"]?.["view"],
      },
    // role=="Producer"&&{
    //   title: "Producers",
    //   path: "/Producers/Producers/",
    //   icon:<img src={DistributorsIcon} height={"30px"}/>,
    //   iconClosed: <KeyboardArrowRightIcon />,
    //   iconOpened: <KeyboardArrowDownIcon />,
    //   access:true

    // },
    role != "Advertiser" && {
      title: "Movie Submission",
      path: "/CollaboratorForm/CollaboratorForm",
      icon: <img src={Movie_Submission} height={"30px"} />,
      iconClosed: <KeyboardArrowRightIcon />,
      iconOpened: <KeyboardArrowDownIcon />,
      access: rights?.["Movie Submission"]?.["view"],
    },
    role != "Producer" &&
      role == "Advertiser" && {
        title: "Ad Submission",
        path: "/AdForm/AdForm/",
        icon: <img src={Movie_Submission} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Ad Submission"]?.["view"],
      },
    role != "Producer" && role == "Advertiser" && {
      title: "Set Movie Advertisement",
      path: "/SetMovieAdvertisement/SetMovieAdvertisement/",
      icon: <img src={Movie_Submission} height={"30px"} />,
      iconClosed: <KeyboardArrowRightIcon />,
      iconOpened: <KeyboardArrowDownIcon />,
      access: rights?.["Set Movie Advertisement"]?.["view"],
    },
    role != "Producer" &&  role == "Advertiser" &&{
      title: "Set Series Advertisement",
      path: "/SetSeriesAdvertisement/SetSeriesAdvertisement/",
      icon: <img src={Movie_Submission} height={"30px"} />,
      iconClosed: <KeyboardArrowRightIcon />,
      iconOpened: <KeyboardArrowDownIcon />,
      access: rights?.["Set Series Advertisement"]?.["view"],
    },

    role != "Advertiser" &&
      role != "Producer" && {
        title: "Reports",
        path: "/reports/reports/",
        icon: <img src={AdvertisementIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: "true",
      },
    // role!="Producer"&&role!="Advertiser"&&{
    //   title: "Watch-hours",
    //   path: "/WatchHours/WatchHours/",
    //   icon:<img src={WatchhoursIcon} height={"30px"}/>,
    //   iconClosed: <KeyboardArrowRightIcon />,
    //   iconOpened: <KeyboardArrowDownIcon />,
    //   access:rights?.["Watch Hours"]?.["view"]

    // },

    //  {
    //     title: "Video Collections",
    //     path: "/DistributorsFormData/DistributorsFormData",
    //     icon: <img src={SubscriptionIcon} height={"30px"} width={"30px"} style={{padding:"2px"}}/>,
    //     iconClosed: <KeyboardArrowRightIcon />,
    //     iconOpened: <KeyboardArrowDownIcon />,
    //     access:rights?.["Movie"]?.["view"],

    //   },

    role != "Advertiser" &&
      role != "Producer" && {
        title: "Promocode",
        path: "/Coupon/Coupon",
        icon: <img src={PromocodeIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Coupon"]?.["view"],
      },
    role != "Producer" &&
      role != "Advertiser" && {
        title: "Ad Master",
        path: "/AdForm/",
        icon: <img src={AdvertisementIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Advertisement"]?.["view"],
        subNav: [
          {
            title: "Ad Submission",
            path: "/AdForm/AdForm/",
            access: rights?.["Ad Submission"]?.["view"],
          },

          role != "Advertiser" && {
            title: "Advertisers",
            path: "/Advertisers/Advertisers/",
            access: rights?.["Advertisement"]?.["view"],
          },
          {
            title: "Create Ad",
            path: "/Advertisers/Advertisement/",
            access: rights?.["Advertisement"]?.["view"],
          },
          {
            title: "Set Ads ( Movie )",
            path: "/SetMovieAdvertisement/SetMovieAdvertisement/",
            access: rights?.["Set Movie Advertisement"]?.["view"],
          },
          {
            title: "Set Ads ( Series )",
            path: "/SetSeriesAdvertisement/SetSeriesAdvertisement/",
            access: rights?.["Set Series Advertisement"]?.["view"],
          },
        ].filter((e) => e),
      },
    role == "Advertiser" && {
      title: "Advertisement",
      path: "/Advertisers/Advertisement/",
      icon: <img src={AdvertisementIcon} height={"30px"} />,
      iconClosed: <KeyboardArrowRightIcon />,
      iconOpened: <KeyboardArrowDownIcon />,
      access: rights?.["Advertisement"]?.["view"],
    },
    role == "Advertiser" && {
      title: "Ad Transaction",
      path: "/AdTransaction/AdTransaction/",
      icon: <img src={TransactionIcon} height={"30px"} />,
      iconClosed: <KeyboardArrowRightIcon />,
      iconOpened: <KeyboardArrowDownIcon />,
      access: rights?.["Advertisement"]?.["view"],
    },

    // {
    //   title: "Notifications",
    //   path: "/Notifications/Notifications",
    //   icon: <NotificationsNoneIcon />,
    //   iconClosed: <KeyboardArrowRightIcon />,
    //   iconOpened: <KeyboardArrowDownIcon />,

    // },

    // {
    //   title: "Reports",
    //   path: "/Report/",
    //   icon: <SettingsIcon />,
    //   iconClosed: <KeyboardArrowRightIcon />,
    //   iconOpened: <KeyboardArrowDownIcon />,

    //   subNav: [
    //     {
    //       title: "Registered Customers",
    //       path: "/Report/RegisteredCustomer/",
    //     },
    //     {
    //       title: "Subscribed Customers",
    //       path: "/Report/SubscribedCustomer/",
    //     },
    //     {
    //       title: "PPV Customers",
    //       path: "/Report/PPVCustomer/",
    //     },

    //   ],
    // },

    role != "Producer" &&
      role != "Advertiser" && {
        title: "Live Streaming",
        path: "/LiveStreaming/",
        icon: <img src={LiveStreamingIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Live Streaming"]?.["view"],
        subNav: [
          {
            title: "Streaming Category",
            path: "/LiveStreaming/TVCategory",
            access: rights?.["Live Streaming"]?.["view"],
          },
          {
            title: "Channel / Event",
            path: "/LiveStreaming/TVChannel",
            access: rights?.["Live Streaming"]?.["view"],
          },
        ].filter((e) => e),
      },
    role != "Advertiser" &&
      role != "Producer" && {
        title: "Masters",
        path: "/Masters/",
        onClick:  "true",
        icon: <img src={MastersIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        access: rights?.["Masters"]?.["view"],
        subNav: [
          role != "Producer" && {
            title: "Avatar",
            path: "/Masters/Avatar/Avatar/",
            access: rights?.["Masters"]?.["view"],
          },
          role != "Producer" && {
            title: "Category",
            path: "/Masters/Category/Category/",
            access: rights?.["Masters"]?.["view"],
          },
          role != "Producer" && {
            title: "Sub Category",
            path: "/Masters/SubCategory/SubCategory/",
            access: rights?.["Masters"]?.["view"],
          },
          role != "Producer" && {
            title: "Language",
            path: "/Masters/Language/Language/",
            access: rights?.["Masters"]?.["view"],
          },
          role != "Producer" && {
            title: "Genre",
            path: "/Masters/Genre/Genre/",
            access: rights?.["Masters"]?.["view"],
          },
          {
            title: "Cast",
            path: "/Masters/Cast/Cast/",
            access: rights?.["Masters"]?.["view"],
          },
          {
            title: "Country",
            path: "/Masters/Country/Country/",
            access: rights?.["Masters"]?.["view"],
          },
          {
            title: "Content Advisory",
            path: "/Masters/ContentAdvisory/ContentAdvisory/",
            access: rights?.["Masters"]?.["view"],
          },
          // {
          //   title: "Subscriptions",
          //   path: "/Masters/Subscriptions/",
          // },
          role != "Producer" && {
            title: "Sub Admin",
            path: "/Masters/SubAdmin/SubAdmin/",
            access: rights?.["Sub Admin"]?.["view"],
          },
        ].filter((e) => e),
      },
    role != "Producer" &&
      role != "Advertiser" && {
        title: "Settings",
        path: "/Settings/",
        icon: <img src={SettingIcon} height={"30px"} />,
        iconClosed: <KeyboardArrowRightIcon />,
        iconOpened: <KeyboardArrowDownIcon />,
        onClick:  "true",
        access: rights?.["Setting"]?.["view"],
        subNav: [
          {
            title: "About Us",
            path: "/Settings/AboutUs/",
            access: rights?.["Setting"]?.["view"],
          },

          {
            title: "Privacy Policy",
            path: "/Settings/PrivacyPolicy/",
            access: rights?.["Setting"]?.["view"],
          },
          {
            title: "Terms & Conditions",
            path: "/Settings/TermsConditions/",
            access: rights?.["Setting"]?.["view"],
          },
          {
            title: "Refund Policy",
            path: "/Settings/RefundPolicy/",
            access: rights?.["Setting"]?.["view"],
          },

          {
            title: "App Setting",
            path: "/Settings/AppSetting/",
            access: rights?.["Setting"]?.["view"],
          },
          {
            title: "User Logs",
            path: "/Settings/UserLogs/UserLogs",
            access: rights?.["Setting"]?.["view"],
          },
        ].filter((e) => e),
      },
  ].filter((e) => e);
};
