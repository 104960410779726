

import {  Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';

import AdForm from '../components/AdForm/AdForm';

function AdFormModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/AdForm' element={<Layout children={AdForm()}/>}/>
< Route path='/CreateAdForm' element={<Layout children={AdForm()}/>}/>
< Route path='/EditAdForm' element={<Layout children={AdForm()}/>}/>



      </Routes>
    </div>
  );
}

export default  AdFormModule;
