import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import users from "./../../images/users-icon.png"
import graph from "./../../images/graph-icon.png"
import work from "./../../images/work-icon.png"
import { Link } from "react-router-dom";


const Features = ({data}) => {
  const FeaturesData = [
    {
      id: "1",
      subTitle: "Movies Downloaded",
      title: data?.download_count,
      image: users,
      link:"/Customer/CustomerDetail/MoviesDownloaded",
      data:data?.download
    },
    {
      id: "2",
      subTitle: "Movies Watched",
      title: data?.watch_count,
      image: graph,
      link:"/Customer/CustomerDetail/MoviesWatched",
      data:data?.movie_watch_list
    },
    {
      id: "3",
      subTitle: "Rented Movies",
      title: data?.rental_movie_count,
      image: work,
      link:"/Customer/CustomerDetail/MoviesRented",
      data:data?.rental_movie_list
    }
  ];
  return (
    <>
      <Grid
        container
        justifyContent="center"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      >
        {FeaturesData.map((feature) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={feature.id}>
            <Link to={feature.link} state={{data:feature.data}}>
            <Card
              sx={{
                boxShadow: "var(--gradientColorLighter2) 0px 6px 12px -2px, var(--gradientColorLighter1) 0px 3px 7px -3px",
                borderRadius: "10px",
                p: "30px 20px 20px",
                mb: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "55px",
                    height: "55px",
                    lineHeight: "85px",
                    background: "rgba(85, 112, 241, 0.12)",
                    borderRadius: "8px",
                    textAlign: "center",
                  }}
                  className='mr-15px'
                >
                  <img src={feature.image} alt="Icon" style={{verticalAlign:"baseline"}} height={"45px"} />
                </Box>

                <Box>
                  <Typography variant="p" sx={{ fontSize: '13px' }}>
                    {feature.subTitle}
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{ fontSize: 28, fontWeight: 700, mt: "5px" }}
                  >
                    <span style={{color:"#ff3636"}}>{feature.title}</span>
                  </Typography>
                </Box>
              </Box>
            </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Features;
