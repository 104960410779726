

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Distributors from '../components/Distributors/Distributors';
import Layout from '../components/Layout/Layout';
import DistributorProducts from '../components/Distributors/DistributorProducts';
function DistributorsModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Distributors' element={<Layout children={Distributors()}/>}/>
< Route path='/CreateDistributors' element={<Layout children={Distributors()}/>}/>
< Route path='/EditDistributors' element={<Layout children={Distributors()}/>}/>

< Route path='/DistributerProducts' element={<Layout children={DistributorProducts()}/>}/>
< Route path='/CreateDistributerProducts' element={<Layout children={DistributorProducts()}/>}/>
< Route path='/EditDistributerProducts' element={<Layout children={DistributorProducts()}/>}/>



      </Routes>
    </div>
  );
}

export default DistributorsModule;