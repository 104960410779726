import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Country, State } from "country-state-city";
import Form from "../utils/Form";
import { distributor_update } from "../../actions/distributor";
import {
  advertiser_update,
  all_advertiser_list,
} from "../../actions/Advertiser/advertiser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
export default function Profile() {
  const user = useSelector((state) => state.layout.profile);
  // console.log(user, "uu")

  const country_list = {};
  Country.getAllCountries().map(
    (value) => (country_list[JSON.stringify(value?.name)] = value?.isoCode)
  );

  const role = useSelector((state) => state.layout.role);

  const advertiser = useSelector((state) => state.layout.advertiser);
  // console.log(advertiser,"ads")
  const distributor = useSelector((state) => state.layout.distributor);

  const subAdmin = useSelector((state) => state);
  // console.log(subAdmin,"subadmin")

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState({});
  useEffect(() => {
    if (role == "Advertiser") setForm({ ...advertiser });
    else if (role == "Producer") setForm({ ...distributor });
  }, [advertiser, distributor]);
  // const aboutus=useSelector((state)=>state.setting?.aboutus)
  // useMemo(()=>{
  // 	setForm({...aboutus})
  // },[aboutus])

  useEffect(() => {
    dispatch(all_advertiser_list());
  }, [user]);

  const [formStructure, setFormStructure] = useState([
    {
      id: "5",
      type: "inputBox",
      title: "Company Name",
      name: "company_name",
      required: true,
      disabled: true,
    },
    {
      id: "5",
      type: "inputBox",
      title: role == "Advertiser" ? "Advertisers Name" : "Producer Name",
      name: "name",
      required: true,
      disabled: true,
    },
    {
      id: "5",
      type: "inputBox",
      title: "Email ID",
      variant: "email",
      disabled: true,

      name: "email",
      required: true,
    },
    {
      id: "4",
      type: "phone",
      title: "Mobile Number",
      maxLength: 12,
      name: "mobileNumber",

      required: true,
    },
    {
      id: "1",
      type: "inputBox",
      name: "GST",
      title: "GST No",
      required: true,
      disabled: true,
    },

    {
      id: "2",
      type: "inputBox",
      title: "Address",
      name: "address",
    },
    {
      id: "2",
      type: "inputBox",
      title: "Pay per view price (Paisa)",
      name: "commission",
    //   options: ["10", "20", "30", "40", "50", "60", "70", "80", "90"],
      required: true,
      disabled: true,
    },

    {
      id: "4",
      type: "hr",
    },
    {
      id: "1",
      type: "headind_ad",
      title: "Contact Person Details ",
    },
    {
      id: "4",
      type: "hr2",
    },

    {
      id: "4",
      type: "inputBox",
      title: "Contact Person Name",
      name: "contact_person_name",
      required: true,
    },
    {
      id: "4",
      type: "phone2",
      title: "Contact Person Number",
      maxLength: 12,
      name: "contact_person_number",
      required: true,
    },

    {
      id: "2",
      type: "image",
      title: "Company Registration Certificate",
      name: "company_register_certificate",
      accept: ".pdf",
    },
    {
      id: "2",
      type: "image",
      title: "Company Logo",
      name: "company_logo",
    },
    {
      id: "2",
      type: "image",
      title: "Contract Agreement",
      name: "contract_agreement",
      accept: ".pdf",
    },

    {
      id: "8",
      type: "button",
      title: "Update",
    },
  ]);

  useMemo(() => {
    if (role === "Advertiser") {
      const temp = formStructure;
      temp[6]["display"] = "none";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[6]["display"] = "block";
      setFormStructure([...temp]);
    }
  }, [role]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();

    Object.keys(form).map((key) => data.append(key, form?.[key]));
    // console.log(form, "test")

    data.append("user", user?.id);
    // console.log(data, "pppp")
    if (role == "Advertiser") {
      dispatch(advertiser_update(data));
      setTimeout(() => {
        navigate("/Advertisers/Advertisement/");
      }, 1000);
    } else if (role == "Producer") {
      dispatch(distributor_update(data));
      setTimeout(() => {
        navigate("/Dashboard");
      }, 1000);
    }
  };
  // console.log(data)

  return (
    <>
      <Form
        formStructure={formStructure}
        handleSubmit={handleSubmit}
        formTitle={"Profile"}
        key={"Form"}
        setForm={setForm}
        form={form}
      />
    </>
  );
}
