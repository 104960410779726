import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Avatar from '../components/Masters/Avatar/Avatar';

function AvatarModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateAvatar/' element={<Layout children={Avatar()} />} />
                < Route path='/EditAvatar/' element={<Layout children={Avatar()} />} />
                < Route path='/Avatar/' element={<Layout children={Avatar()} />} />
            </Routes>
        </div>
    )
}
export default AvatarModule