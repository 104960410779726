
import { MESSAGE,COUNTRIES } from '../../constants/actionTypes';
import * as api from '../../api/index.js';


  export const all_country_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_country_list(formData);
      
      dispatch({ type: COUNTRIES, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  