import React, { useEffect, useMemo, useState } from "react";
import MultiStepForm from "../utils/MultiStepForm";
import { FormStructure } from "./FormStructure";
// import { all_language_list } from "../../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import RemoveIcon from "@mui/icons-material/Remove";
import Alert from "@mui/material/Alert";
import { TableData } from "./TableData";
import ViewChange from "../utils/ViewChange";

import { useLocation, useNavigate } from "react-router-dom";
import { all_genre_list } from "../../actions/Masters/genre";
import { all_language_list } from "../../actions/Masters/language";
import { all_producer_movie_form_list, producer_movie_form_create  ,producer_movie_form_update} from "../../actions/producer";

import Popup from "../utils/Popup";
import ListTable from "../utils/Table";
import Reload from "../utils/Reload";

const CollectionForm = () => {
  const tempFormStruct = FormStructure();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation()
	const [form, setForm] = useState((location?.state?.form && JSON.parse(location?.state?.form)) || {});
	const [view, setView] = useState(location?.state?.view || "view_all");
  const [openAdError, setOpenAdError] = useState(false);
  const user = useSelector((state) => state?.layout?.profile);
  // console.log(user,"ppooi")
  const [open, setOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

  const [content, setPopupContent] = useState("");
  const [result, setResult] = useState(undefined);
  const [errorFields, setErrorFields] = useState({});
  const [subTitleID, setsubTitleID] = useState("");
  const [audioFileId, setAudioFileId] = useState("");
  const [subtitlePosition, setSubtitlePosition] = useState();
  const [audioPosition, setAudioPosition] = useState();
	const path = location?.pathname.split("/")[2]
  const rights = useSelector((state) => state.layout.rights)
  const role = useSelector((state) => state.layout.role)

  useMemo(() => {
		if (isEdit) { setView("create_new") }
		else {
			setView("view_all");
			setForm({});
		}
	}, [isEdit]);

  const tempTableData={
    ...TableData(),
		tableTitle: "Video Collections",
		disableDelete: true,
    updateRecord :producer_movie_form_update,
	}
  const [tableData, setTableData] = useState({...tempTableData});
  const producerformdata = useSelector((state) => state.producer.producerformdata);

console.log(tableData,"testing")


	useEffect(() => {
    const data = new FormData();
    data.append("email" , user?.email) ;
		dispatch(all_producer_movie_form_list(data));
	}, [user]);
  const [formTitle, setFormTitle] = useState(
    [
      "Movie Detail",
      "Movie Resolutions",
      "Audio Language",
      "Subtitle File",
    ].filter((e) => e)
  );
  useMemo(() => { setTableData({ ...tempTableData }); }, [rights])
  useEffect(() => {
    // setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    const newDataForm = (location?.state?.form && JSON.parse(location?.state?.form))
    console.log(location?.state?.form,"newDataaa")
    setForm((newDataForm) || {  email : user?.email});

    setView(path != "CollaboratorForm" ? "create_new" : "view_all");

  setIsEdit(path == "EditCollaboratorForm");
  console.log(producerformdata,"fddfsdfds");
}, [location])
		useMemo(() => {
			if (producerformdata?.statuscode == 200) {
				const temp = tableData;
        const movieSubtitle = [];
        producerformdata?.data.map((value) => {
          // console.log(value, "value")
          const subtitle = {};
          value?.subtitle_list &&
            value?.subtitle_list?.map((ele, index) => {
              // console.log(ele?.subtitle_file, ele?.subtitle_language, "idd")
              subtitle["subtitle_language_" + index] = ele?.subtitle_language;
              subtitle["subtitle_file_" + index] = ele?.subtitle_file
              subtitle["subtitle_id_" + index] = ele?.id
            });
          movieSubtitle.push(subtitle);
        });
  
        const movieAudio = [];
        producerformdata?.data.map((value) => {
          // console.log(value, "value")
          const Audio = {};
          value?.audio_file_list &&
            value?.audio_file_list?.map((ele, index) => {
              // console.log(index, "idd")
              Audio["audio_language_" + index] = ele?.audio_language;
              Audio["audio_file_" + index] = ele?.audio_file
              Audio["audio_id_" + index] = ele?.id
              // console.log(index, ele?.id, "12301230")
            });
          movieAudio.push(Audio);
        });
				temp.tableBody = producerformdata?.data.map((ele , index)=> ({
          ...ele,
          ...movieSubtitle[index],
          ...movieAudio[index],
					rent1 :parseFloat(ele.rent).toFixed(2) ,
					type_of_film1: ele?.category_type =="Web Series" ? ele?.type_of_series : ele?.type_of_film,
          edit:ele?.status == "Approved"?  "Approved":"",
         genre: ele?.genre?.map((genre) => genre?.genre_title)
				
				}));
				setTableData({ ...temp });
			setForm({ ...form});

			};
		}, [producerformdata])

console.log(producerformdata,"nneeww")
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);
  useEffect(() => {
    dispatch(all_genre_list());
    dispatch(all_language_list());
  }, []);
  const genre = useSelector((state) => state.masters.genre);
  const language = useSelector((state) => state.masters.languages);
  const message = useSelector((state) => state.layout.message);

  const handleAddSubtitle = (e) => {
    // console.log(e.currentTarget,"1111");
    const temp = formStructure;

    if (formStructure[3].length < language?.data.length * 3) {
      // console.log(form, "tr");

      const randomName = formStructure[3].length / 3;
      // console.log(randomName, "101010");

      temp[3].push(
        {
          id: "21",
          type: "select",
          title: "Select Language",
          size: "6",
          name: "subtitle_language_" + randomName,
          options: temp[3][1]["options"],
        },
        {
          id: "19",
          type:"inputBox",
          subtitle: "*",
          title: "Browse Subtitle file(.srt)",
          size: "5.5",
          name: "subtitle_file_" + randomName,
          endsWith: ".srt",
          errorText: "File extension should be .srt only",
          // required: true,
        },

        {
          id: "23",
          type: "button",
          title: <RemoveIcon sx={{ color: "#fff !important" }} />,
          align: "right",
          size: ".5",
          padding: "2px 2px",
          position: randomName,
          forceShow: true,
          handleClick: () => {
            setOpen(true);
            setSubtitlePosition(randomName);
            setsubTitleID(form["subtitle_id_" + randomName]);
            setPopupContent(
              "Do you want to remove this subtitle from this movie?"
            );
          },
        }
      );

      setFormStructure([...temp]);
    } else {
      setOpenAdError(true);
      setPopupContent(
        "You can add max " + language?.data.length + " subtitles in a movie"
      );
    }
  };
  const handleAddAudio = (e) => {
    // console.log(e.currentTarget,"1111");
    const temp = formStructure;

    if (formStructure[2].length < language?.data.length * 3) {
      // console.log(form, "tr");

      const randomName = formStructure[2].length / 3;
      // console.log(randomName, "11111");

      temp[2].push(
        {
          id: "21",
          type: "select",
          title: "Select Language",
          size: "6",
          name: "audio_language_" + randomName,
          options: temp[2][1]["options"],
        },
        {
          id: "19",
          type: "inputBox",
          subtitle: "*",
          title: "Browse mp3 file(.mp3)",
          name: "audio_file_" + randomName,
          size: "5.5",
          endsWith: ".wav" ,
          endswith1:".mp3",
          errorText: "File extension should be .mp3 or .wav only",
          // required: true,
        },

        {
          id: "23",
          type: "button",
          title: <RemoveIcon sx={{ color: "#fff !important" }} />,
          align: "right",
          size: ".5",
          padding: "2px 2px",
          position: randomName,
          forceShow: true,
          handleClick: () => {
            setOpen(true);
            setAudioPosition(randomName);
            setAudioFileId(form["audio_id_" + randomName]);
            setPopupContent(
              "Do you want to remove this audio file from this movie?"
            );
          },
        }
      );

      setFormStructure([...temp]);
    } else {
      setOpenAdError(true);
      setPopupContent(
        "You can add max " + language?.data.length + " audio file in a movie"
      );
    }
  };
  useMemo(() => {
    const temp = formStructure;
    temp[2][0]["handleClick"] = handleAddAudio;
    temp[3][0]["handleClick"] = handleAddSubtitle;
  }, [language]);
  useMemo(() => {
		if (isEdit) {
			setView("create_new");
			if (form?.subtitle_list.length > (formStructure?.[3].length ) / 3)
				form?.subtitle_list.map((ele) =>

					form?.subtitle_list.length > (formStructure?.[3].length ) / 3 && handleAddSubtitle())

			if (form?.audio_file_list.length > (formStructure?.[2].length ) / 3)
				form?.audio_file_list.map((ele) =>

					form?.audio_file_list.length > (formStructure?.[2].length) / 3 && handleAddAudio())

		} else {
			setView("view_all");
			// setForm({
			// 	set_position: tableData.tableBody.length + 1,
			// 	subtitles: "Inactive",

			// 	audio_language: "Inactive",
			// 	movie_is_for18Plus: "No",
			// 	allow_download: "Yes",
			// 	status: "Active",
			// 	movie_access: role == "Producer" ? "TVOD" : "",
			// 	movie_ownership: role == "Producer" ? "Producer" : "",
			// 	movie_distributor:
			// 		role == "Producer" ? distributor?.name : "",
			// });
		}
		const temp = formStructure
		tempFormStruct.map((arr, i) => arr.map((ele, j) => !["select", "select_multiple"].includes(ele.type) && (temp[i][j] = ele)))
    temp[2][0]["handleClick"] = handleAddAudio;
    temp[3][0]["handleClick"] = handleAddSubtitle;

		setFormStructure([...temp])

	}, [isEdit, role , language ,location]);
  useMemo(() => {
    if (language?.statuscode == 200) {
      const temp = formStructure;
      temp[0][9]["options"] = language?.data.map(
        (language) => language?.language_name
      );
      temp[3][1]["options"] = language?.data.map(
        (language) => language?.language_name
      );
      temp[2][1]["options"] = language?.data.map(
        (language) => language?.language_name
      );
      setFormStructure([...temp]);
      // const tempFilter = tableData;
      // tempFilter["filterColumn"][0]["options"] = language?.data.map(
      //   (language) => language?.language_name
      // );

      // setTableData({ ...tempFilter });
    }
  }, [language]);
  useMemo(() => {
    if (genre?.statuscode == 200) {
      const temp = formStructure;
      temp[0][10]["options"] = genre?.data.map((genre) => genre?.genre_title);
      setFormStructure([...temp])
      console.log(formStructure,"formSttt")
    }
  }, [genre]);
  useMemo(() => {
    const temp = formStructure;
    if (form?.category_type == "Web Series") {
      temp[0][5]["display"] = "block";
      temp[0][6]["display"] = "block";
      temp[0][7]["display"] = "block";
      temp[0][4]["display"] = "none";
      setFormStructure([...temp])

    } else {
      temp[0][5]["display"] = "none";
      temp[0][6]["display"] = "none";
      temp[0][7]["display"] = "none";
      temp[0][4]["display"] = "block";
      setFormStructure([...temp])

    }
  }, [form?.category_type]);
  useMemo(()=>{
    const temp = formStructure;
    if(form?.movie_released_status == "Released"){
      temp[1][7]["display"] = "block"
      temp[1][8]["display"] = "none"
      temp[1][9]["display"] = "none"

    } else{
      temp[1][7]["display"] = "none"
      temp[1][8]["display"] = "block"
      temp[1][9]["display"] = "block"
    }
  },[form?.movie_released_status])
  useMemo(()=>{
    const temp = form
    temp.email = user?.email
    setForm({...temp})
  },[form?.email])  
  const handleSubmit = (event) => {
    event.preventDefault();
    const temp_form = form
    temp_form["tempgenre"] = form?.["genre"];
    temp_form["templanguage"] = form?.["language"];

		temp_form["genre"] = genre?.data.map((option) => form?.["genre"]?.includes(option.genre_title) && option.id).filter(e => e)
		temp_form["language"] = language?.data.map((option) => form?.["language"]?.includes(option.language_name) && option.id).filter(e => e)

		setForm({
			...temp_form
		});
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("email" , user?.email)
    if (isEdit) {
			dispatch(producer_movie_form_update(data));
		} else {
			dispatch(producer_movie_form_create(data));
		}
  };
  useMemo(() => {
    if (message?.statuscode == 200 ) { 
			const temp = tableData;
      if (isEdit) {
				temp.tableBody.map(
					(value, index) =>
						value.id == form.id && (temp.tableBody[index] = { ...form })
				);
			} else {
				temp.tableBody[temp.tableBody.length] = {
					id: temp.tableBody.length,
					...form,
					edit: temp.tableBody.length,
				};
			}
			setTableData({ ...temp });

			setIsEdit(false); 
      setTimeout(() => {
        const data = new FormData();
				data.append("email", user?.email);
        dispatch(all_producer_movie_form_list(data))
        navigate("/CollaboratorForm/CollaboratorForm", { state: { view: "view_all" } });
				setView("view_all");

           }, 900);
     
  }else {
    const tempForm = form;
    tempForm["genre"] = form?.["tempgenre"];
    tempForm["language"] = form?.["templanguage"];

			setForm({ ...tempForm });

  }
  }, [message]);
  useMemo(() => {
    if (result) {
      const temp = formStructure;
      if (subtitlePosition != undefined) {
        temp[3].map((value, index) => {
          if (value.position == subtitlePosition) {
            const tempForm = form;
            tempForm["subtitle_language_" + value.position] = "";
            tempForm["subtitle_file_" + value.position] = "";

            setForm({ ...tempForm });
            temp[3].splice(index - 2, 3);
          }
        });
        setFormStructure([...temp]);
        setSubtitlePosition(undefined);

        // dispatch(subtitle_delete({ id: subTitleID, video_type: "Movie" }));
        // console.log(form, "idssss");
      } else if (audioPosition != undefined) {
        temp[2].map((value, index) => {
          if (value.position == audioPosition) {
            // console.log(value.position, "8888");
            const tempForm = form;

            tempForm["audio_file_" + value.position] = "";
            tempForm["audio_language_" + value.position] = "";

            setForm({ ...tempForm });
            temp[2].splice(index - 2, 3);
          }
        });
        setFormStructure([...temp]);
        setAudioPosition(undefined);
        // dispatch(audio_file_delete({ id: audioFileId, video_type: "Movie" }));
      }
    }
    setResult(undefined);
  }, [result]);

  const handleClose = () => {
    setOpenAdError(false);
  };
  useEffect(() => {
    const temp = formStructure;

    temp[3][0]["handleClick"] = handleAddSubtitle;
    temp[2][0]["handleClick"] = handleAddAudio;
    setFormStructure([...temp]);
  }, []);
  const subtitles = language?.data?.map((ele, index) =>
    eval("form?.subtitle_language_" + index)
  );
  const audio_language = language?.data?.map((ele, index) =>
    eval("form?.audio_language_" + index)
  );
  useMemo(() => {
    const newLanguageList = language?.data
      ?.map(
        (ele, index) =>
          !subtitles.includes(ele?.language_name) && ele?.language_name
      )
      .filter((e) => e);
    const newAudioLanguage = language?.data
      ?.map(
        (ele, index) =>
          !audio_language.includes(ele?.language_name) && ele?.language_name
      )
      .filter((e) => e);
    const temp = formStructure;
    temp[3].map(
      (ele, index) =>
        ele.type == "select" && (temp[3][index]["options"] = newLanguageList)
    );
    temp[2].map(
      (ele, index) =>
        ele.type == "select" && (temp[2][index]["options"] = newAudioLanguage)
    );

    setFormStructure([...temp]);
  }, [form]);
// console.log(role,"nreeee")
  return (
    <>
      <Popup
        open={open}
        setOpen={setOpen}
        content={content}
        setResult={setResult}
      />
    { role === "Producer" && <ViewChange
				setForm={setForm}
				setView={setView}
				setIsEdit={setIsEdit}
				view={view}
				isEdit={isEdit}
				create_new={"/CollaboratorForm/CreateCollaboratorForm/"}
				view_all={"/CollaboratorForm/CollaboratorForm/"}
				form={form}
        reload={<Reload isClubed={true} />}
				access={rights?.["Movie Submission"]?.["create"] == "true"}
			/>}
       {view == "create_new" && (<MultiStepForm
        formStructure={formStructure}
        handleSubmit={handleSubmit}
        formTitle={formTitle}
        key={"Form"}
        setForm={setForm}
        form={form}
        tableData={tableData}
        setTableData={setTableData}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />)}
    {view == "view_all" && (   <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
        create_new={"/CollaboratorForm/EditCollaboratorForm"}
    />
    )}
    
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CollectionForm;
