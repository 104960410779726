
import { useSelector } from "react-redux"

export const TableData = () => {
  const rights = useSelector((state) => state.layout.rights)
  return {
    tableTitle: "Episode",
    deleteAccess: rights?.["TV Shows"]?.["delete"] == "true",
    tableHead: [
      {
        id: 'episode_title',
        label: 'Episode Title',
        link: "/Episode/Episode/EpisodeDetails",
        subText:"episode_number",
        color: "var(--gradientColor2)"
      },
      {
        id: 'series_name',
        label: 'Show Name',
        subText: "season_name"
      },

      {
        id: 'episode_poster',
        label: 'Image',
        isImage: true
      },


      {
        id: 'release_date',
        label: 'Release Date',
        subText: "publish_time"
      },
      {
        id: 'uploaded_by',
        label: 'Uploaded By',
      },
      {
        id: 'series_ownership',
        label: 'Ownership',
      },
      {
        id: "episodeViews",
        label: "Viwes"
      }
      ,
      {
        id: 'status',
        label: 'Status',
        isButtonDisplay: true

      },
      // {
      //   id: 'edit',
      //   label: 'Update',
      //   access: rights?.["TV Shows"]?.["edit"] == "true"
      // },
    ],
    tableBody: [
      {
        id: 0,
        series_name: "Movie",
        poster: "Landscape",
        season_name: "2",
        episode_title: "Movie",
        release_date: "Landscape",


        status: "Active",
        edit: 0
      }
    ],
    filterColumn: [
      {
        id: "1",
        title: "Series",
        name: "series_name",
        options: ["Series 1", "Series 2", "Series 3"],
      },
      {
        id: "2",
        title: "Season",
        name: "season",
        // displayOn1:"series_name",
        displayOn: "series_name",
        dependentField: "series_name",
        requredField: "season",
        options: ["Season 1", "Season 2", "Season 3"],
      }
    ]
  }
}