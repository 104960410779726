import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Season from "../components/WebSeries/Season/Season";
import PageNotFound from "../components/PageNotFound/PageNotFound";

function SeasonModule() {
  return (
    <div className="App">
      <Routes>
        <Route path="/Season" element={<Layout children={Season()} />} />
        <Route path="/CreateSeason" element={<Layout children={Season()} />} />
        <Route path="/EditSeason" element={<Layout children={Season()} />} />
        {/* <Route path="*" element={<PageNotFound />} /> */}

      </Routes>
    </div>
  );
}

export default SeasonModule;
