

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Reports from '../components/Reports/Reports';
import Report_field from '../components/Reports/Report_field';

function ReportModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/reports' element={<Layout children={Report_field()}/>}/>
< Route path='/reports_download' element={<Layout children={Reports()}/>}/>


      </Routes>
    </div>
  );
}

export default ReportModule;
