import React,{useEffect} from 'react';
import Grid from "@mui/material/Grid";
import {Link} from "react-router-dom";
import styles from '../../styles/PageTitle.module.css'
import Features from "./elements/Features";
import MaxLoginDuration from './elements/MaxLoginDuration'
import RegisteredUsers from "./elements/RegisteredUsers";
import DailyRevenue from "./elements/DailyRevenue";
import TotalRevenue from "./elements/TotalRevenue";
import SubscribedUsers from "./elements/SubscribedUsers";
import HighestSubscription from "./elements/HighestSubscription";
import MostWatchedMovies from "./elements/MostWatchedMovies";
import MostWatchedSeries from "./elements/MostWatchedSeries";
import RecentlyAddedMovies from "./elements/RecentlyAddedMovies";
import ValueAddedCustomer from "./elements/ValueAddedCustomer";
import RecentSubscriber from "./elements/RecentSubscriber";
import Renewal from "./elements/Renewal";
import MonthlyRevenue from "./elements/MonthlyRevenue";
import RegionChart from "./elements/RegionChart";
import DistributorProducts from './elements/DistributorTables/DistributorProducts';
import ProducerProducts from './elements/ProducerTables/ProducerProducts';

import AdvertiserElements from './elements/AdvertiserTables/AdvertiserElements';
import { movies_data_dashboard,count_on_dashboard,total_subscribe_user,most_watch_tv_show_dashboard,recent_subscriber_dashboard } from '../../actions/dashboard';
import { useDispatch,useSelector } from 'react-redux';

export default function Dashboard() {
  const role=useSelector((state) => state.layout.role)
  const user=useSelector((state) => state.layout.profile)
  const dispatch=useDispatch()
  const data=new FormData()
		data.append('id',user?.id)
  useEffect(()=>{
		
    dispatch(movies_data_dashboard(data))
    dispatch(count_on_dashboard(data))
    dispatch(total_subscribe_user(data))
    dispatch(most_watch_tv_show_dashboard(data))
    dispatch(recent_subscriber_dashboard(data))
	},[user])
	const registeredUsers = useSelector((state) => state.dashboard.totalusers);
  const dashboardcount = useSelector((state) => state.dashboard.dashboardcount?.data);
  const recently_added_data = useSelector((state) => state.dashboard.dashboardmovies?.recently_added_data);
  const most_watch_movies = useSelector((state) => state.dashboard.dashboardmovies?.most_watch_movies);
  
  const monthlyrevenue = useSelector((state) => state.dashboard?.monthlyrevenue);
  const tv_show_dashboard = useSelector((state) => state.dashboard?.tv_show_dashboard);
  const recentSubscriberDashboard= useSelector((state) => state.dashboard?.recent_subscriber_dashboard);
  return (
    <>
   
   {role!="Producer"&&role!="Advertiser"&&<RegisteredUsers registeredUsers={registeredUsers}/>}
   
   
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
        {role!="Advertiser"&& role!="Producer"&&<Features dashboardcount={dashboardcount} role={role}/>}
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={8}>
   
        {/* {role!="Advertiser"&& role!="Producer"&&<Features dashboardcount={dashboardcount} role={role}/>} */}

          

          <Grid
            container
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Grid item xs={12} md={12}>
             {role!="Advertiser"&&<MonthlyRevenue monthlyrevenue={monthlyrevenue}/>}
            </Grid>

            {/* <Grid item xs={12} md={4}>
        
              <Impressions />

         
              <ActivityTimeline />
            </Grid> */}

            <Grid item xs={12} md={12}>
        
              {role!="Advertiser"&&<TotalRevenue />}
            </Grid>
            <Grid item xs={12} md={12}>
        
            {role!="Producer"&&role!="Advertiser"&&<MaxLoginDuration />}
      </Grid>
            <Grid item xs={12} md={12}>
        
            {role!="Producer"&&role!="Advertiser"&& <Renewal />}
      </Grid>
      <Grid item xs={12} md={12}>
        
      {role!="Producer"&&role!="Advertiser"&&<RecentSubscriber recentSubscriberDashboard={recentSubscriberDashboard}/>}
      </Grid>
      
     
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={4}>
        

        {role!="Producer"&&role!="Advertiser"&&<SubscribedUsers />}
         

        { role=="Producer"&&role!="Advertiser"&&<Features dashboardcount={dashboardcount} role={role}/>}
        {role!="Advertiser"&&<DailyRevenue />}
       
        {/* {role!="Producer"&&role!="Advertiser"&&  <HighestSubscription />} */}
        {role!="Producer"&&role!="Advertiser"&& <MostWatchedMovies most_watch_movies={most_watch_movies}/>}
        {role!="Producer"&&role!="Advertiser"&&  <MostWatchedSeries tv_show_dashboard={tv_show_dashboard}/>}
    
        </Grid>
      </Grid>
      
      {role=="Producer"&&<DistributorProducts/>}
      {role=="Producer1"&&<ProducerProducts/>}

      {/* {role=="Advertiser"&&<AdvertiserElements/>} */}
      {/* {role!="Producer"&&role!="Advertiser"&& <RegionChart />} */}
      
      {role!="Producer"&&role!="Advertiser"&& <RecentlyAddedMovies recently_added_data={recently_added_data}/>}
      {/* {role!="Producer"&&role!="Advertiser"&&  <ValueAddedCustomer />} */}
      {/* <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
      >
        <Grid item xs={12} md={12} lg={12} xl={8}>
   
          <MaxLoginDuration />
      
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={4}>
      
          <MostWatchedMovies title='Most Watched Movies'/>
     
        </Grid>
      </Grid> */}
    </>
  );
}
