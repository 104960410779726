import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Analytics from "./../components/Analytics/Analytics";
import Layout from "../components/Layout/Layout";

function AnalyticsModule() {
  return ( 
    <div className="App">
      <Routes>
        {/* Test */}
        <Route path="/" element={<Layout children={Analytics()} />} />

        
      </Routes>
    </div>
  );
}

export default AnalyticsModule;
