
import React from 'react'
import "./Thank.css"

const Thank = () => {
  return (
 
     <div className="testbox">
      <div className="banner">
        <p style={{ color: 'black' }}>
          Thank you for taking the time to fill out the content information form. We appreciate your time and work in filling out the form.<br /><br />
          We will carefully analyze the information you provided and, if necessary, contact you regarding any additional actions or revisions.<br /><br />
          If you have any queries or require further assistance, please get in touch with us at  <a href="tel: +91 9136836815"> +91 91368 36815</a> or via email at <a href="mailto:24sevenflix@gmail.com">24sevenflix@gmail.com</a> .<br /><br />
          Thank you again for your submission, and we look forward to welcoming you as a great distributor partner. <br /><br /><br />
          Best wishes,<br />
          <img src="http://122.170.109.73:9000/static/media/logo_final.aebfabe754cdb65518d2.png" style={{ height: '50px' }} /><br />
          Team 24sevenflix4u
        </p>
      </div>
    </div>
   
  )
}

export default Thank