
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from '../components/Layout/Layout';
import UserLogs from '../components/Setting/UserLogs';
import UserLogDetails from '../components/Setting/UserLogDetails';


function UserLogModule() {
    return(
        <div className='App'>

            <Routes>
                <Route path='/UserLogs' element ={<Layout children={UserLogs()}/>}/>
                <Route path='/UserLogsDetails' element={<Layout children={UserLogDetails()}/>}/>
            </Routes>
        </div>
    )
}

export default UserLogModule;