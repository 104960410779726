import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import AdvertiserElements from '../components/Dashboard/elements/AdvertiserTables/AdvertiserElements';

function AdvertiserElementsModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateAdvertiserElements' element={<Layout children={AdvertiserElements()} />} />
                < Route path='/EditAdvertiserElements' element={<Layout children={AdvertiserElements()} />} />
                < Route path='/AdvertiserElements' element={<Layout children={AdvertiserElements()} />} />
            </Routes>
        </div>
    )
}
export default AdvertiserElementsModule