import React from "react";
import { useState,useEffect,useMemo } from "react";
import Export from "../../utils/Export";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "./../../utils/Table";
import { all_registered_customer_list } from '../../../actions/Report/registered_customer';
import { useDispatch,useSelector } from 'react-redux';

export default function RegisteredCustomer() {
	const dispatch=useDispatch()
	const [tableData,setTableData]= useState({
	
		tableTitle: "Registered Customers :03",
		disableDelete:true,
		tableHead: [
			{
				id: "user_name",
				label: "User Name",
			},
			{
				id: "email_id",
				label: "Email Id",
			},
			{
				id: "mobile_no",
				label: "Mobile No",
			},
			{
				id: "registered_on",
				label: "Registered On",
			},
			{
				id: "device_type",
				label: "Device Type",
			}
		],
		tableBody: [
			{
				id: 0,
				user_name: "Movie",
				email_id: "Landscape",
				mobile_no: "2",
				registered_on: "Active",
				device_type: 0,
			},
		],
	});


	
    const registered_customers = useSelector((state) => state.masters.registered_customers);
    useEffect(()=>{
      dispatch(all_registered_customer_list())
    },[])
    useMemo(()=>{
      
      if(registered_customers?.statuscode==200){const temp=tableData
      temp.tableBody=registered_customers?.data
      setTableData({...temp,tableTitle:"Registered Customers : "+temp.tableBody.length})}
    },[registered_customers])

	
	
	return (
		<>
		
			
			<Export fileName={"Registered Customers"} exportData={tableData?.exportData||tableData?.tableBody} headings={tableData.tableHead.map((value)=>value.label)}/>
	
		
	
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setTableData={setTableData}
					
				/>
		
		</>
	);
}
