import React from "react";
import { useState,useEffect,useMemo } from "react";

import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "./../../utils/Table";
import Export from "../../utils/Export";
import { all_ppv_customer_list } from '../../../actions/Report/ppv_customer';
import { useDispatch,useSelector } from 'react-redux';
export default function PPVCustomer() {
	const dispatch=useDispatch()
	const [tableData,setTableData]= useState({
		tableTitle: "PPV Customers :03",
		disableDelete:true,
		tableHead: [
			{
				id: "user_name",
				label: "User Name",
			},
			{
				id: "movie_series",
				label: "Movie/Series",
			},
			{
				id: "email_id",
				label: "Email Id",
			},
			{
				id: "mobile_no",
				label: "Mobile No",
			},
			{
				id: "payment_amount",
				label: "Payment Amount",
			},
			
			{
				id: "payment_id",
				label: "Payment ID",
			},
			{
				id: "date",
				label: "Date",
			},
			{
				id: "promocode",
				label: "Promocode",
			}
		],
		tableBody: [
			{

				id: 0,
				user_name: "Movie",
				email_id: "Landscape",
				mobile_no: "2",
				payment_amount: "Active",
				payment_id: "Active",
				date: "Active",
				promocode: 0,
			},
			{
				id: 0,
				user_name: "Movie",
				email_id: "Landscape",
				mobile_no: "2",
				payment_amount: "Active",
				payment_id: "Active",
				date: "Active",
				promocode: 0,
			},
		],filterColumn:[
			
			{
				id: "2",
			title: "Select Video Type",
			name: "movie_series",
			options: ["Movie", "Series"],
			}
		]
		
	});


	
    const ppv_customers = useSelector((state) => state.masters.ppv_customers);
    useEffect(()=>{
      dispatch(all_ppv_customer_list())
    },[])
    useMemo(()=>{
      
      if(ppv_customers?.statuscode==200){const temp=tableData
      temp.tableBody=ppv_customers?.data
      setTableData({...temp,tableTitle:"PPV Customers : "+temp.tableBody.length})}
    },[ppv_customers])

	
	
	return (
		<>
		
			
<Export fileName={"PPV Customers"} exportData={tableData?.exportData||tableData?.tableBody} headings={tableData.tableHead.map((value)=>value.label)}/>
		
	
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setTableData={setTableData}
					
				/>
		
		</>
	);
}
