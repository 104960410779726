
import { useSelector } from "react-redux"

export const TableData= () => {
  const rights=useSelector((state) => state.layout.rights)
  return {
    tableTitle:"Upcoming Movies",
    deleteAccess:rights?.["Upcoming Movie"]?.["delete"]=="true",
    tableHead:[
        {
            id: 'movie_name',
            label: 'Movie Name',
          },
          {
            id: 'movie_poster',
            label: 'Image',
            isImage:true
          },
          // {
          //   id: 'movie_category',
          //   label: 'Category',
          // },
          
         
          {
            id: 'movie_language',
            label: 'Language',
          },
          {
            id: 'movieViews',
            label: 'Views',
          }
          // ,
          // {
          //   id: 'movieLikes',
          //   label: 'Likes',
          // }

          ,
          {
            id: 'release_date1',
            label: 'Release Date',
            isSpecial: true,
            align:"left"
          }

          ,
          {
            id: 'edit',
            label: 'Update',
            access:rights?.["Upcoming Movie"]?.["edit"]=="true"
          },
    ],
    tableBody:[
        {
            id:0,
            movie_name:"Movie",
            image:"Landscape",
            category:"2",
            uploaded_by:"Movie",
            language:"2",
            views:"Movie",
            likes:"Landscape",
            dislikes:"2",
         
            status:"Active",
            edit:0
        }
    ],
    filterColumn:[
      
      {
        id: "2",
      title: "Language",
      name: "movie_language",
      options:["English","Hindi","Gujarati"],
      },
    
      // {
      //   id: "4",
      // title: "Video Category",
      // name: "movie_category",
      // options:["Admin","Distributor"],
      // }
    ]
  }}