

import {  Routes, Route } from 'react-router-dom';
import SignInForm from '../components/Authentication/SignInForm';
import ChangePassword from '../components/Authentication/ChangePassword';
import ForgotPasswordForm from '../components/Authentication/ForgotPasswordForm';
import RegisterForm from '../components/Authentication/RegisterForm';
import PageNotFound from '../components/PageNotFound/PageNotFound';
function AuthenticationModule() {
  return (
    <div className="App">
    
<Routes>
  {/* Test */}
 
  {/* test */}
< Route path='/SignIn' element={<SignInForm key="SignInForm"/>}/>
< Route path='/ChangePassword' element={<ChangePassword key="ChangePassword"/>}/>   
< Route path='/ForgotPassword' element={<ForgotPasswordForm key="ForgotPasswordForm"/>}/>   
<Route path='/Register' element={<RegisterForm key="RegisterForm"/>}/>
< Route path='/' element={<SignInForm key="SignInForm"/>}/>
< Route path='*' element={<PageNotFound/>}/>

      </Routes>
    </div>
  );
}

export default AuthenticationModule;
