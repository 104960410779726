
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import LiveMovieView from '../components/LiveMovieView/LiveMovieView';

function LiveMovieViewModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/LiveMovieView' element={<Layout children={LiveMovieView()}/>}/>




      </Routes>
    </div>
  );
}

export default LiveMovieViewModule;
