import React from "react";
import { useState, useEffect, useMemo } from "react";

import { Link, useLocation } from "react-router-dom";
import styles from "./../../styles/PageTitle.module.css";
import ListTable from "../utils/Table";
import { useDispatch, useSelector } from 'react-redux';
import Export from "./../utils/Export"
import { all_advertise_transaction_list } from "../../actions/Advertiser/advertisement";
export default function AdTransaction() {
	const dispatch = useDispatch()
	const location = useLocation()
	const [isEdit, setIsEdit] = useState(false);
	const rights = useSelector((state) => state.layout.rights)
	const user=useSelector((state) => state.layout.profile)

	const [form, setForm] = useState({});
	const [tableData, setTableData] = useState({
		tableTitle: "Ad Transactions",
		disableDelete: true,
		column_sum: {
			name: "recharge_amount",
			title: "Total Amount"
		},
		tableHead: [
			// {
			// 	id: "name",
			// 	label: "Name",
			// 	link: "/Customer/CustomerDetail/CustomerDetail",
			// 	color: "var(--gradientColor2)"
			// },
			{
				id: "product_name",
				label: "Product Name",
				// subText: "mobile_no"
			},
			{
				id: "subscription_type",
				label: "Type",
				isSpecial: true,
				align: "left"
			},
			{
				id: "recharge_amount",
				label: "Amount",
			},
			{
				id: "payment_id",
				label: "Payment ID",
				subText: "payment_type"
			},
			{
				id: "created_at",
				label: "Payment Date",
			},

			// {
			// 	id: "device_type",
			// 	label: "Device Type",
			// },
			// {
			// 	id: "location",
			// 	label: "Location",
			// },
			// {
			// 	id: "payment_status",
			// 	label: "Payment Status",
			// 	isButtonDisplay: true
			// },
		],
		tableBody: [

		],
		filterColumn: [
			
			// {
			// 	id: "1",
			// 	title: "Payment Status",
			// 	name: "payment_status",
			// 	options: ["Success", "Failed", "Expired"],
			// }
		],
		isDateRangeFilter: "date"
	});



	const adtransactions = useSelector((state) => state?.advertisementspay?.adpayment);
	useEffect(() => {
		const data = new FormData()
		data.append("user" , user?.id)
		dispatch(all_advertise_transaction_list(data))
	}, [user])
	useMemo(() => {

		if (adtransactions?.statuscode == 200) {
			const temp = tableData
			temp.tableBody = adtransactions?.data.map((ele) => ({
				...ele, id: ele?.user?.id, payment_status: ele?.status, name: ele?.user?.firstName + " " + ele?.user?.lastName,

				plan: ele?.transaction_type == "SVOD" ? (
					<p>{ele?.plan?.plan_name}</p>
				) : (
					<><p >{ele?.transaction_type}</p>
						<span>({ele?.movie?.movie_name})</span></>
				),
			}))
			setTableData({ ...temp })
		}
	}, [adtransactions])
	console.log(adtransactions, "0111111")



	return (
		<>



			<Export fileName={"Ad Transactions"} access={rights?.["Transactions"]?.["export_excel"] == "true"} exportData={tableData?.exportData || tableData?.tableBody} headings={tableData.tableHead.map((value) => value.label)} />
			<ListTable
				tableData={tableData}
				key={"ListTable"}
				setTableData={setTableData}
				setForm={setForm}
				setIsEdit={setIsEdit}
			/>

		</>
	);
}
