import React from "react"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from "@mui/icons-material/Remove";

import SRTFile from "./../../../images/SRTFile.png"
import { useSelector } from "react-redux";
export const FormStructure = (isEdit) => {
    const role = useSelector((state) => state.layout.role)

    return [
        [

            {
                id: "1",
                type: isEdit ? "inputBox" :"select",
                title: "Ownership",
                name: "movie_ownership",
                options: ["In house", "Collaborator"],
                required: true,
                disabled: isEdit ? true : false,
                display: role == "Producer" ? "none" : "block"
            },
            {
                id: "2",
                type: isEdit ? "inputBox" :"select",
                title: "Select Collaborator",
                name: "movie_distributor",
                disabled: isEdit ? true : false,
                options: ["Later"],
                display: "none"
            },
            {
                id: "3",
                type: "inputBox",
                title: "Company Name",
                name: "company_name",
                disabled: true,
                display: "none"
            },
            {
                id: "4",
                type: isEdit ? "inputBox" :"select",
                title: "Select Producer",
                name: "movie_Producer",
                disabled: isEdit ? true : false,
                options: ["Later"],
                display: "none"
            },
            {
                id: "5",
                type: "inputBox",
                title: "Production Company Name",
                name: "Producer_company_name",
                disabled: true,
                display: "none"
            },
            // {
            //     id:"24",
            //     type:"inputBox",
            //     title:"Price Per Movie (TVOD )",
            //     name:"price_per_ticket",
            //     variant:"number",
            //     display:"none"
            // },

            {
                id: "6",
                type: "select",
                title: "Video Category",
                name: "movie_category",
                options: [],
                required: true
            },
            {
                id: "7",
                type: "select",
                title: "Sub Category",

                name: "movie_subcategory",
                options: [],
                required: true
            }
            ,
            {
                id: "8",
                type: "select",
                title: "Access",
                name: "movie_access",
                // disabled:isEdit ? true : false,
                options: ["FREE", "TVOD", "SVOD"],
                required: true
            }
            ,
            {
                id: "9",
                type: "inputBox",
                title: "Name",
                name: "movie_name",
                // maxLength: "100",

                maxLength: "50",
                showLimit:true,
                regex: /^[a-zA-Z0-9\s\&]+$/,
                isCapitalise: true,
                required: true
            }
            ,
            {
                id: "10",
                type: "select",
                title: "Language",
                name: "movie_language",
                options: ["English", "Hindi", "Gujarati"],
                required: true
            }
            ,
            {
                id: "11",
                type: "select_multiple",
                title: "Select Genre",
                name: "movie_genre",
                maxSelections: "3",
                errorText: "You can select max 3 genre at a time",
                options: [],
                required: true

            },

            {
                id: "12",
                type: "select_multiple",
                title: "Select Cast",
                name: "movie_cast",
                maxSelections: "7",
                errorText: "You can select max 7 cast at a time",
                options: [],
                required: true

            },
            {
                id: "13",
                type: "select_multiple",
                title: "Select Advisory",
                name: "content_advisory",
                maxSelections: "3",
                errorText: "You can select max 3 Advisory at a time",
                options: [],
                required: true

            },

            {
                id: "14",
                type: "select",
                title: "Publish Type",
                name: "publish_type",
                options: ["Today", "Later"],
                required: true

            },
            {
                id: "15",
                type: "inputBox",
                variant: "date",
                title: "Publish Date",
                min: new Date().toISOString().split('T')[0],
                name: "release_date",
                default:new Date().toISOString().split('T')[0],
                display: "none",
                required: true
            },
            {
                id: "16",
                type: "inputBox",
                variant: "time",
                title: "Publish Time",
                default:new Date().toISOString().split('T')[1],
                name: "publish_time",
                display: "none",
                required: true
            },
            
            {
                id: "17",
                type: "inputBox",
                title: `Rental Price (AD Free)`,
                name: "rental_price",
                regex: /^[0-9\.]+$/,
                maxLength: "6",
                display: "none",
                required: true
            },
            {
                id: "18",
                type: "inputBox",
                variant: "number",
                title: "Tax (18.5%) (AD Free)",
                name: "tax",
                disabled: true,
                display: "none",
                required: true
            },
            {
                id: "19",
                type: "inputBox",
                title: "Streaming Fees (USD)",
                name: "streaming_fees",
                regex: /^[0-9\.]+$/,
                maxLength: "2",
                display: "none",
                required: true
            },
            {
                id: "20",
                type: "inputBox",
                variant: "number",
                title: "Amount After Tax (AD Free)",
                name: "amount_received",
                disabled: true,
                display: "none",
                required: true
            },
            {
                id: "21",
                type: "inputBox",
                title: "Rental Price (with AD)",
                name: "rental_price_with_ad",
                regex: /^[0-9\.]+$/,
                maxLength: "6",
                display: "none",
                required: true
            },
            {
                id: "22",
                type: "inputBox",
                variant: "number",
                title: "Tax (18.5%) (with AD)",
                name: "tax_with_ad",
                disabled: true,
                display: "none",
                required: true
            },
            {
                id: "23",
                type: "inputBox",
                title: "Streaming Fees (USD)",
                name: "streaming_fees_with_ad",
                regex: /^[0-9\.]+$/,
                maxLength: "2",
                display: "none",
                required: true
            },
            {
                id: "24",
                type: "inputBox",
                variant: "number",
                title: "Amount After Tax (with AD)",
                name: "amount_received_with_ad",
                disabled: true,
                display: "none",
                required: true
            },
            {
                id: "25",
                type: "inputBox",
                title: "Collaborator's Share (in %)",
                name: "collaborator_share",
                regex: /^[0-9\.]+$/,
                maxLength: "2",
                display: "none",
                // required: true
            },
            {
                id: "26",
                type: "inputBox",
                title: "Producer's Share (in %)",
                name: "producer_share",
                regex: /^[0-9\.]+$/,
                maxLength: "2",
                display: "none",
                // required: true
            },
          
            {
                id: "27",
                type: "inputBox",
                title: "Admin's Share (in %)",
                name: "admin_share",
                regex: /^[0-9\.]+$/,
                maxLength: "2",
                display: "none",
                // required: true,
                disabled:true
            },
            {
                id: "28",
                type: "inputBox",
                title: "Available Till (Days)",
                name: "available_for_days",
                regex: /^[0-9]+$/,
                maxLength: "2",
                display: "none",
                required: true
            },
            {
                id: "29",
                type: "toggle",
                title: "Available for Basic Plan",
                name: "available_for_basic_plan",
                default: "Yes",
                display: "none",

                size: "3",
                options: [{ value: "Yes", color: "success" }, { value: "No", color: "danger" }],


            },
            {
                id: "30",
                type: "description",
                title: "Description",
                name: "movie_description",
                required: true
            },
        ],
        [
            {
                id: "1",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "M3U8 URL ( Resolution : 1080p)",
                endsWith: ".m3u8",
                errorText: "File extension should be .m3u8 only",
                name: "movieLink_1080p",
                required: true
            },
            {
                id: "2",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "M3U8 URL ( Resolution : 720p)",
                endsWith: ".m3u8",
                errorText: "File extension should be .m3u8 only",
                name: "movieLink_720p",
                required: true
            },
            {
                id: "3",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "M3U8 URL ( Resolution : 480p)",
                endsWith: ".m3u8",
                errorText: "File extension should be .m3u8 only",
                name: "movieLink_480p",
                required: true
            },
            {
                id: "4",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "M3U8 URL ( Resolution: 360p)",
                endsWith: ".m3u8",
                errorText: "File extension should be .m3u8 only",
                name: "movieLink_360p",
                required: true
            },
            {
                id: "5",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "Upload Movie Mp4 or MKV URL",
                endsWith: ".mp4",
                endsWith1: ".mkv",
                errorText: "File extension should be .mp4 or .mkv only",
                name: "movieMp4_URL",
                required: true
            },
            {
                id: "6",
                type: "inputBox",
                title: "Duration",
                name: "movie_duration",
                disabled: true,
                required: true
            },

            {
                id: "7",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "Trailer URL ( .m3u8 or .mp4)",
                endsWith: ".m3u8",
                endsWith1: ".mp4",
                errorText: "File extension should be .m3u8 or .mp4 only",
                name: "movie_trailer",
                required: true
            },
            {
                id: "8",
                type: "inputBox",
                title: "Trailer Duration",
                name: "movie_trailer_duration",
                disabled: true,
                required: true
            },
            {
                id: "9",
                type: isEdit ? "lockedInput" : "inputBox",
                title: "Free Preview URL",
                endsWith: ".m3u8",
                endsWith1: ".mp4",
                errorText: "File extension should be .m3u8 or .mp4 only",
                name: "free_preview",
                // required: true,
              },
              {
                id: "10",
                type: "duration",
                title: "Skip Start",
                name: "skip_start",
                size:"2",
                // disabled: true,
                // required: true
            },
           
            {
                id: "11",
                type: "duration",
                title: "Skip End",
                name: "skip_end",
                size:"2",
                // disabled: true,
                // required: true
            },
            {
                id: "12",
                type: "duration",
                title: "Movie End",
                name: "movie_end",
                size:"2",
                // disabled: true,
                // required: true
            },
            {
                id: "13",
                type: "select",
                title: "Select Suggestion",
                name: "movie_suggestion",
                options: [],
                // required: true
            },
            {
                id: "14",
                type: isEdit ? "lockedInput" : "inputBox",
                variant: "number",
                title: "Set Position",
                name: "set_position",
                min: "0",
                max: "99",
                size:"6",
                required: true
            },
            {
                id: "12",
                type: "toggle",
                title: "Available Platform",
                name: "platform",
                default: "Both",
                size: "12",
                options: [
                  { value: "Both", color: "success" },
                  { value: "App", color: "danger" },
                  { value: "Web", color: "danger" },
                ],
        
                // required: true
              },
            {
                id: "19",
                type: "image",
                subtitle: "(Resolution : 1920px x 1080px) *",
                subsubtitle: "Max File Size 1MB",
                subsubsubtitle: "Support only JPG,PNG,JPEG",
                title: 'Browse Poster',
                name: "movie_poster",
                size: 3,
                required: true
            },
            {
                id: "18",
                type: "image",
                title: "Browse Thumbnail",
                subtitle: "(Resolution : 1920px x 720px) *",
                subsubtitle: "Max File Size 1MB",
                subsubsubtitle: "Support only JPG,PNG,JPEG",
                name: "movie_thumbnail",
                required: true,
                size: 3,

            }, 
            {
                id: "19",
                type: "image",
                subtitle: "(Resolution : 1080px x 1080px) *",
                subsubtitle: "Max File Size 1MB",
                subsubsubtitle: "Support only JPG,PNG,JPEG",
                title: "Movie Scene",
                name: "movie_scene",
                size: 3,
                required: true,
              },
              {
                id: "19",
                type: "image",
                subtitle: "(Resolution : 1920px x 1080px) *",
                subsubtitle: "Max File Size 1MB",
                subsubsubtitle: "Support only JPG,PNG,JPEG",
                title: 'Upload Movie Name ( Logo )',
                name: "movie_logo",
                size: 3,
                // required: true
            },
        ],
        [
            {
                id: "23",
                type: "toggle",
                title: "Audio Language",
                name: "audio_language",
                default: "Inactive",
                size: "3",
                options: [{ value: "Active", color: "success" }, { value: "Inactive", color: "danger" }],


            },
            {
                type: "undefined"
            },
            {
                id: "23",
                type: "button",
                title: <AddIcon sx={{ color: '#fff !important' }} />,
                align: "left",
                forceShow: true
            },
            {
                id: "21",
                type: "select",
                title: "Select Language",
                size: "6",
                name: "audio_language_0",
                options: ["Advertise 1", "Advertise 2"],
                required: true,

            },
            {
                id: "19",
                type: isEdit ? "lockedInput" : "inputBox",
                subtitle: "*",
                endsWith: ".mp3",
                endsWith1: ".wav",
                errorText: "File extension should be .mp3 or .wav only",
                title: 'Upload Audio File',
                name: "audio_file_0",
                size: "5.5",
             
                required: true,

            },
        ],
        [


            {
                id: "21",
                type: "toggle",
                title: "Is This Video 18+ ?",
                name: "movie_is_for18Plus",
                default: "No",
                size: "3",

                options: [{ value: "Yes", color: "success" }, { value: "No", color: "danger" }]

            },
            {
                id: "22",
                type: "toggle",
                title: "Allow Download ?",
                name: "allow_download",
                default: "Yes",
                size: "3",
                options: [{ value: "Yes", color: "success" }, { value: "No", color: "danger" }],


            },
            {
                id: "20",
                type: "toggle",
                title: "Subtitles",
                name: "subtitles",

                default: "Inactive",
                size: "3",
                options: [{ value: "Active", color: "success" }, { value: "Inactive", color: "danger" }],


            },

            // {
            //     id: "21",
            //     type: "toggle",
            //     title: "Admin's Approval",
            //     size: "3",
            //     name: "admin_approval",
            //     options: [{ value: "Approve", color: "success" }, { value: "Reject", color: "danger" }],
            //     display: "none"

            // },

            // {
            //     id: "24",
            //     type: "inputBox",
            //     title: "Admin's Remarks",
            //     name: "admin_remarks",
            //     display: "none"
            // },
            {
                id: "20",
                type: "toggle",
                title: "Status",
                name: "status",
                default: "Active",
                display: isEdit ? "block" :  "none",

                size: "3",
                options: [{ value: "Active", color: "success" }, { value: "Inactive", color: "danger" }],
            },
            {
                id: "23",
                type: "button",
                title: <AddIcon sx={{ color: '#fff !important' }} />,
                align: "left",
                forceShow: true
            },
            {
                id: "22",
                type: "select",
                title: "Select Language",
                size: "6",
                name: "subtitle_language_0",
                options: ["Advertise 1", "Advertise 2"]

            },
            {
                id: "28",
                type: isEdit ? "lockedInput" : "inputBox",
                subtitle: "*",
                title: 'Upload Subtitle File',
                name: "subtitle_file_0",
                size: "5.5",
                endsWith: ".srt",
                endsWith1: ".asc",
                errorText: "File extension should be .srt or .asc only",
                required: true,
                display: "none"
            },
        ],
        // [
        //     {
        //         id: "23",
        //         type: "button",
        //         title: <AddIcon sx={{ color: '#fff !important' }} />,

        //         align: "left",
        //         forceShow: true
        //     },
        //     {
        //         id: "21",
        //         type: "select",
        //         title: "Select Advertise",
        //         size: "3",
        //         name: "advertise0",
        //         options: ["Advertise 1", "Advertise 2"]

        //     },
        //     {
        //         id: "24",
        //         type: "inputBox",
        //         size: "1.5",
        //         title: "Ad Duration",
        //         name: "duration0",
        //         disabled: true
        //     },
        //     {
        //         id: "24",
        //         type: "duration",
        //         // regex:/^([0]?[0-3]):([0-5]?[0-9]):([0-5]?[0-9])+$/,
        //         // default:"00:00:00",
        //         // maxLength:"8",
        //         // variant:"time",
        //         // min:"00:00",
        //         // max:"03:00",
        //         size: "2",
        //         title: "set Duration",
        //         name: "time0",
        //         color:"red"
        //     },

        //     {
        //         id: "24",
        //         type: "inputBox",
        //         size: "2",
        //         title: "Company Name",
        //         name: "company_name0",
        //         disabled: true
        //     },
        //     {
        //         id: "24",
        //         type: "inputBox",
        //         size: "1.5",
        //         title: "View Required",
        //         name: "views_required0",
        //         disabled: true
        //     },
        //     {
        //         id: "24",
        //         type: "inputBox",
        //         size: "1.5",
        //         title: "Avbl. views",
        //         name: "available_views0",
        //         disabled: true
        //     },
        // ],

    ]
}