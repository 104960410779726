import { REPORTS} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const report_data = (formData) => async (dispatch) => {
    try {
      const { data } = await api.report_data(formData);
      dispatch({ type: REPORTS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };