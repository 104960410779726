
import { MESSAGE  , PRODUCERFORMDATA ,PRODUCERFORMDETAILS} from '../constants/actionTypes';
import * as api from '../api/index.js';


// export const producer_create = (formData) => async (dispatch) => {
//     try {
//       const { data } = await api.producer_create(formData);
//       dispatch({ type: MESSAGE, payload:data });
  
   
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   export const producer_update = (formData) => async (dispatch) => {
//     try {
//       const { data } = await api.producer_update(formData);
//       dispatch({ type: MESSAGE, payload:data });
  
   
//     } catch (error) {
//       console.log(error);
//     }
//   };
  export const producer_movie_form_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.producer_movie_form_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const producer_movie_form_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.producer_movie_form_update(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  // export const all_producer_list = (formData) => async (dispatch) => {
  //   try {
  //     const { data } = await api.all_producer_list(formData);
  //     dispatch({ type: PRODUCER, payload:data });
  
   
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // export const producer_list_movie = (formData) => async (dispatch) => {
  //   try {
  //     const { data } = await api.producer_list_movie(formData);
  //     dispatch({ type: PRODUCERMOVIE, payload:data });
  
   
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  export const all_producer_movie_form_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_producer_movie_form_list(formData);
      dispatch({ type: PRODUCERFORMDATA, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const producer_movie_detail_form = (formData) => async (dispatch) => {
    try {
      const { data } = await api.producer_movie_detail_form(formData);
      dispatch({ type: PRODUCERFORMDETAILS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
