
import { REGISTERED_CUSTOMERS } from '../../constants/actionTypes';
import * as api from '../../api/index.js';


  export const all_registered_customer_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_registered_customer_list(formData);
      
      dispatch({ type: REGISTERED_CUSTOMERS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  