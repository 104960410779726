import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import MultiStepForm from "../../utils/MultiStepForm";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
  movie_create,
  movie_delete,
  movie_update,
  all_movie_list,
  subtitle_delete,
  audio_file_delete,
} from "../../../actions/Movie/movie";
import { all_distributor_list } from "../../../actions/distributor";
import { all_category_list } from "./../../../actions/Masters/category";
import { all_subcategory_list } from "../../../actions/Masters/subcategory";
import { all_cast_list } from "../../../actions/Masters/cast";
import { all_genre_list } from "../../../actions/Masters/genre";
import { all_language_list } from "../../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import Export from "../../utils/Export";
import Import from "../../utils/Import";
import { useRef } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import { all_advertisement_list } from "../../../actions/Advertiser/advertisement";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import Popup from "../../utils/Popup";
import EnlargedView from "../../utils/EnlargedView";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Reload from "../../utils/Reload";
import LoadingGIF from "../../../images/Loading.gif";
import { all_content_advisory_list } from "../../../actions/Masters/contentadvisory";

export default function Movie() {
  const user = useSelector((state) => state.layout.profile);
  const role = useSelector((state) => state.layout.role);
  const distributor = useSelector((state) => state.layout.distributor);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openApproval, setOpenApproval] = useState(false);
  const [openAdError, setOpenAdError] = useState(false);
  const [flag, setFlag] = useState(false);
  // const [adPosition, setAdPosition] = useState();
  const [subtitlePosition, setSubtitlePosition] = useState();
  const [audioPosition, setAudioPosition] = useState();
  const [result, setResult] = useState(undefined);
  const rights = useSelector((state) => state.layout.rights);
  const [content, setPopupContent] = useState("");
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);

  const tempTableData = {
    ...TableData(),
    deleteRecord: movie_delete,
    disableDelete: role !== "Producer" ? false : true,

    onDeleteText: [
      "Are you sure want to delete this video ?",
      "The Customer Will not be able to see this video once you delete it.",
    ],
    customisedStatusUpdateMessage: true,
    onActiveText: "Are you sure want to Activate the video ?",
    onInactiveText: [
      "Are you sure want to Inactivate the video ?",
      "The Customer Will not be able to see this video once you Inactivate it.",
    ],
    updateRecord: movie_update,
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  // console.log(location ,"951159")
  const tempFormStruct = FormStructure(isEdit);
  const [open1, setOpen1] = useState(false);
  const [subTitleID, setsubTitleID] = useState("");
  const [audioFileId, setAudioFileId] = useState("");

  const [formStructure, setFormStructure] = useState([...tempFormStruct]);

  const [formTitle, setFormTitle] = useState(
    [
      "Movie Details",
      "Movie Resolutions",
      "Audio Language",
      "Age Restrictions & Downloads",
      // role != "Producer" && "Advertisement Details",
    ].filter((e) => e)
  );
  const [formTitleNew, setFormTitleNew] = useState(
    [
      "Movie Details",
      "Movie Resolutions",
      "Audio Language",
      "Age Restrictions & Downloads",
      // role != "Producer" && `Advertisement Details`,
    ].filter((e) => e)
  );
  const advertisements = useSelector(
    (state) => state.advertisers.advertisements
  );
  const distributors = useSelector((state) => state.distributors.distributors);
  const categories = useSelector((state) => state.masters.categories);
  const subcategories = useSelector((state) => state.masters.subcategories);
  const casts = useSelector((state) => state.masters.casts);
  const genre = useSelector((state) => state.masters.genre);
  const language = useSelector((state) => state.masters.languages);
  const Advisory = useSelector((state) => state.masters.advisory);

  const path = location?.pathname.split("/")[2];
  useEffect(() => {
    setTimeout(() => {
      setView(path != "Movie" ? "create_new" : "view_all");
    }, 200);
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {
        set_position: tableData.tableBody.length + 1,
        movie_ownership: role == "Producer" ? "Collaborator" : "",
        movie_distributor: role == "Producer" ? distributor?.name : "",
        subtitles: "Inactive",
        movie_is_for18Plus: "No",
        allow_download: "Yes",
        status: "Active",
        platform:"Both",
        movie_access: role == "Producer" ? "TVOD" : "",
        audio_language: "Inactive",
      }
    );
    setIsEdit(path == "EditMovie");
  }, [location]);
  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  const movies = useSelector((state) => state.movies.movies);
  useMemo(() => {
    if (user?.id) {
      const data = new FormData();
      data.append("id", user?.id);
      dispatch(all_movie_list(data));
      // dispatch(all_advertisement_list(data));
      dispatch(all_subcategory_list());
      dispatch(all_category_list());
      dispatch(all_cast_list());
      dispatch(all_genre_list());
      dispatch(all_content_advisory_list());

      dispatch(all_language_list());
      dispatch(all_distributor_list(data));
    }
  }, [user]);

  useMemo(() => {
    if (movies?.statuscode == 200) {
      // console.log(movies,"20/12/2023")
      const temp = tableData;
      // const movieAdvertise = [];
      // movies?.data.map((value) => {
      //   const advertise = {};
      //   value?.advertise &&
      //     value?.advertise?.map((ele, index) => {
      //       advertise["advertise" + index] = ele?.advertise?.product_name;
      //       advertise["time" + index] = ele?.time;
      //       advertise["duration" + index] = ele?.advertise?.duration;
      //       advertise["company_name" + index] =
      //         ele?.advertise?.advertiser?.company_name;
      //       advertise["views_required" + index] =
      //         ele?.advertise?.views_required;
      //       advertise["available_views" + index] =
      //         ele?.advertise?.views_required - ele?.advertise?.no_of_views;
      //     });
      //   movieAdvertise.push(advertise);
      // });
      const movieSubtitle = [];
      movies?.data.map((value) => {
        // console.log(value, "value")
        const subtitle = {};
        value?.subtitle_list &&
          value?.subtitle_list?.map((ele, index) => {
            // console.log(ele?.subtitle_file, ele?.subtitle_language, "idd")
            subtitle["subtitle_language_" + index] = ele?.subtitle_language;
            subtitle["subtitle_file_" + index] = ele?.subtitle_file;
            subtitle["subtitle_id_" + index] = ele?.id;
          });
        movieSubtitle.push(subtitle);
      });

      const movieAudio = [];
      movies?.data.map((value) => {
        // console.log(value, "value")
        const Audio = {};
        value?.audio_file_list &&
          value?.audio_file_list?.map((ele, index) => {
            // console.log(index, "idd")
            Audio["audio_language_" + index] = ele?.audio_language;
            Audio["audio_file_" + index] = ele?.audio_file;
            Audio["audio_id_" + index] = ele?.id;
            // console.log(index, ele?.id, "12301230")
          });
        movieAudio.push(Audio);
      });

      temp.tableBody = movies?.data.map((value, index) => ({
        ...value,
        // ...movieAdvertise[index],
        ...movieSubtitle[index],
        ...movieAudio[index],
        movie_ownership:
          value.movie_ownership !== null ? value?.movie_ownership : "In house",
        movie_genre: value?.movie_genre.map((genre) => genre.genre_title),
        movie_cast: value?.movie_cast.map((cast) => cast.cast_name),
        content_advisory: value?.content_advisory.map(
          (ele) => ele.content_advisory
        ),
        uploaded_by: value?.created_by?.firstName,
        movie_distributor: value?.movie_distributor?.name,
        movie_suggestion:value?.movie_suggestion?.movie_name,
        company_name: value?.movie_distributor?.company_name,
        movie_access : value?.movie_access === "FREE" ? <span style={{color:"var(--successColor)"}}>{value?.movie_access}</span> : value?.movie_access === "TVOD"  ?<span style={{color:"var(--dangerColor)"}}>{value?.movie_access}</span> : <span style={{color:"var(--warningColor)"}}>{value?.movie_access}</span>,
        released_status:
          new Date(value?.release_date) > new Date()
            ? "Upcoming"
            : value?.release_date,

        // status_approval: (
        // 	<span
        // 		style={{ cursor: "pointer", fontWeight: "500" }}
        // 		variant="contained"
        // 		className={
        // 			value?.admin_approval == "Approve"
        // 				? "successBadge"
        // 				: "dangerBadge"
        // 		}
        // 		onClick={() => {
        // 			setPopupContent(
        // 				<p style={{ color: "black" }}>{value?.admin_remarks}</p>
        // 			);
        // 			setOpenApproval(true);
        // 		}}
        // 	>
        // 		{value?.admin_approval}
        // 	</span>
        // ),
        color: new Date(value?.release_date) > new Date() && "rgb(27,27,27)",
      }));
      temp.filterColumn[4]["options"] = [
        ...new Set(movies?.data.map((value) => value?.created_by?.firstName)),
      ];
      setTableData({ ...temp });
      setForm({ ...form, set_position: temp.tableBody.length + 1 });
    }
  }, [movies]);
  // console.log(movies,"6666")
  // const handleClick = () => {
  //   if (formStructure[4].length < 35) {
  //     const temp = formStructure;
  //     const index = temp[4].length - 3;
  //     const randomName = formStructure[4].length / 7;
  //     temp[4].push(
  //       {
  //         id: "21",
  //         type: "select",
  //         size: "3",
  //         title: "Select Advertise",
  //         name: "advertise" + randomName,
  //         options: temp[4][1]["options"],
  //       },
  //       {
  //         id: "24",
  //         type: "inputBox",
  //         size: "1.5",
  //         title: "Ad Duration",
  //         name: "duration" + randomName,
  //         disabled: true,
  //       },
  //       {
  //         id: "24",
  //         type: "duration",

  //         size: "2",
  //         title: "Set Duration",
  //         name: "time" + randomName,
  //       },

  //       {
  //         id: "24",
  //         type: "inputBox",
  //         size: "2",
  //         title: "Company Name",
  //         name: "company_name" + randomName,
  //         disabled: true,
  //       },
  //       {
  //         id: "24",
  //         type: "inputBox",
  //         size: "1.5",
  //         title: "View Required",
  //         name: "views_required" + randomName,
  //         disabled: true,
  //       },
  //       {
  //         id: "24",
  //         type: "inputBox",
  //         size: "1.5",
  //         title: "Avbl. views",
  //         name: "available_views" + randomName,
  //         disabled: true,
  //       },
  //       {
  //         id: "23",
  //         type: "button",
  //         title: <RemoveIcon sx={{ color: "#fff !important" }} />,

  //         align: "right",
  //         size: ".5",
  //         padding: "2px 2px",
  //         position: randomName,
  //         forceShow: true,
  //         handleClick: (e) => {
  //           setOpen(true);
  //           setAdPosition(randomName);
  //           setPopupContent(
  //             "Do you want to remove this advertisement from this movie?"
  //           );
  //         },
  //       }
  //     );

  //     setFormStructure([...temp]);
  //   } else {
  //     setOpenAdError(true);
  //     setPopupContent("You can add max 5 advertisement in a movie");
  //   }
  // };
  // console.log("namaste")
  const handleAddSubtitle = (e) => {
    // console.log(e.currentTarget,"1111");
    const temp = formStructure;

    if (formStructure[3].length - 4 < language?.data.length * 3) {
      // console.log(form, "tr")

      const randomName = (formStructure[3].length - 4) / 3;
      // console.log(randomName, "101010")

      temp[3].push(
        {
          id: "21",
          type: "select",
          title: "Select Language",
          size: "6",
          name: "subtitle_language_" + randomName,
          options: temp[3][5]["options"],
        },
        {
          id: "19",
          type: isEdit ? "lockedInput" : "inputBox",
          subtitle: "*",
          title: "Upload Subtitle File",
          size: "5.5",
          name: "subtitle_file_" + randomName,
          endsWith: ".srt",
          endsWith1: ".asc",
          errorText: "File extension should be .srt or .asc only",
          required: true,
        },

        {
          id: "23",
          type: "button",
          title: <RemoveIcon sx={{ color: "#fff !important" }} />,
          align: "right",
          size: ".5",
          padding: "2px 2px",
          position: randomName,
          forceShow: true,
          handleClick: () => {
            setOpen(true);
            setSubtitlePosition(randomName);
            setsubTitleID(form["subtitle_id_" + randomName]);
            setPopupContent(
              "Do you want to remove this subtitle from this movie?"
            );
          },
        }
      );

      setFormStructure([...temp]);
    } else {
      setOpenAdError(true);
      setPopupContent(
        "You can add max " + language?.data.length + " subtitles in a movie"
      );
    }
  };
  const handleAddAudio = (e) => {
    // console.log(e.currentTarget,"1111");
    const temp = formStructure;

    if (formStructure[2].length - 2 < language?.data.length * 3) {
      // console.log(form, "tr")

      const randomName = (formStructure[2].length - 2) / 3;
      // console.log(randomName, "11111")

      temp[2].push(
        {
          id: "21",
          type: "select",
          title: "Select Language",
          size: "6",
          name: "audio_language_" + randomName,
          options: temp[2][3]["options"],
        },
        {
          id: "19",
          type: isEdit ? "lockedInput" : "inputBox",
          subtitle: "*",
          title: "Upload Audio File",
          endsWith: ".mp3",
          endsWith1: ".wav",
          errorText: "File extension should be .mp3 or .wav only",
          name: "audio_file_" + randomName,
          size: "5.5",
          required: true,
        },

        {
          id: "23",
          type: "button",
          title: <RemoveIcon sx={{ color: "#fff !important" }} />,
          align: "right",
          size: ".5",
          padding: "2px 2px",
          position: randomName,
          forceShow: true,
          handleClick: () => {
            setOpen(true);
            setAudioPosition(randomName);
            setAudioFileId(form["audio_id_" + randomName]);
            setPopupContent(
              "Do you want to remove this audio file from this movie?"
            );
          },
        }
      );

      setFormStructure([...temp]);
    } else {
      setOpenAdError(true);
      setPopupContent(
        "You can add max " + language?.data.length + " audio file in a movie"
      );
    }
  };
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
      // if (form?.advertise?.length > formStructure?.[4].length / 7)
      //   form?.advertise?.map(
      //     (ele) =>
      //       form?.advertise.length > formStructure?.[4].length / 7 &&
      //       handleClick()
      //   );
      if (form?.subtitle_list?.length > (formStructure?.[3].length - 4) / 3)
        form?.subtitle_list.map(
          (ele) =>
            form?.subtitle_list.length > (formStructure?.[3].length - 4) / 3 &&
            handleAddSubtitle()
        );

      if (form?.audio_file_list.length > (formStructure?.[2].length - 2) / 3)
        form?.audio_file_list.map(
          (ele) =>
            form?.audio_file_list.length >
              (formStructure?.[2].length - 2) / 3 && handleAddAudio()
        );

      //   if (form?.movie_ownership == null) {
      //     setForm({ ...form, movie_ownership: "In house" });
      //   }
      console.log(form, "check");
      console.log(formStructure, "check123");
    } else {
      setView("view_all");
      setForm({
        set_position: tableData.tableBody.length + 1,
        subtitles: "Inactive",

        audio_language: "Inactive",
        movie_is_for18Plus: "No",
        allow_download: "Yes",
        status: "Active",
        platform:"Both",
        movie_access: role == "Producer" ? "TVOD" : "",
        movie_ownership: role == "Producer" ? "Collaborator" : "",
        movie_distributor: role == "Producer" ? distributor?.name : "",
      });
    }
    const temp = formStructure;
    tempFormStruct.map((arr, i) =>
      arr.map(
        (ele, j) =>
          !["select", "select_multiple"].includes(ele.type) &&
          (temp[i][j] = ele)
      )
    );
    temp[2][2]["handleClick"] = handleAddAudio;
    // temp[4][0]["handleClick"] = handleClick;
    temp[3][4]["handleClick"] = handleAddSubtitle;

    setFormStructure([...temp]);
  }, [isEdit, role, language, location]);
  useMemo(() => {
    if (form?.publish_type == "Later") {
      const temp = formStructure;
      temp[0][14]["display"] = "block";
      temp[0][15]["display"] = "block";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][14]["display"] = "none";
      temp[0][15]["display"] = "none";
      setFormStructure([...temp]);
    }
    if (form?.movie_access == "TVOD") {
      const temp = formStructure;
      temp[0][16]["display"] = "block";
      temp[0][17]["display"] = "block";
      temp[0][18]["display"] = "block";
      temp[0][19]["display"] = "block";
      temp[0][20]["display"] = "block";
      temp[0][21]["display"] = "block";
      temp[0][22]["display"] = "block";
      temp[0][23]["display"] = "block";
      // temp[0][24]["display"] = "block";
      // temp[0][25]["display"] = "block";
      // temp[0][26]["display"] = "block";
      temp[0][27]["display"] = "block";

      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][16]["display"] = "none";
      temp[0][17]["display"] = "none";
      temp[0][18]["display"] = "none";
      temp[0][19]["display"] = "none";
      temp[0][20]["display"] = "none";
      temp[0][21]["display"] = "none";
      temp[0][22]["display"] = "none";
      temp[0][23]["display"] = "none";
      // temp[0][24]["display"] = "none";
      // temp[0][25]["display"] = "none";
      // temp[0][26]["display"] = "none";
      temp[0][27]["display"] = "none";

      setFormStructure([...temp]);
    }
    if (form?.movie_access == "SVOD") {
      const temp = formStructure;
      temp[0][28]["display"] = "block";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][28]["display"] = "none";
      setFormStructure([...temp]);
    }
    if (form?.movie_ownership == "Collaborator" && role != "Producer") {
      const temp = formStructure;
      temp[0][1]["display"] = "block";
      temp[0][2]["display"] = "block";
      // temp[0][3]["display"] = "block";
      // temp[0][4]["display"] = "block";
      // isEdit
      //   ? (temp[3][3]["display"] = "block")
      //   : (temp[3][3]["display"] = "none");
      temp[0][7]["disabled"] = true;
      temp[0][7]["type"] = "inputBox";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][1]["display"] = "none";
      temp[0][2]["display"] = "none";
      // temp[0][3]["display"] = "none";
      // temp[0][4]["display"] = "none";
      // temp[3][3]["display"] = "none";
      if (role != "Producer") {
        temp[0][7]["disabled"] = false;
        temp[0][7]["type"] = "select";
      } else {
        temp[0][7]["disabled"] = false;
        temp[0][7]["type"] = "select";
      }
      setFormStructure([...temp]);
    }
    if (form?.subtitles == "Active") {
      const temp = formStructure;
      temp[3].map((ele, index) => index >= 4 && (ele["display"] = "block"));
      setForm({ ...form, subtitle_count: 1 });
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[3].map((ele, index) => index >= 4 && (ele["display"] = "none"));
      setFormStructure([...temp]);
    }

    // if (role == "Producer") {
    // 	if (form?.admin_approval == "Approve") {
    // 		const temp = formStructure;
    // 		temp[3][5]["display"] = "block";
    // 		temp[3][5]["required"] = true;
    // 		setFormStructure([...temp]);
    // 	} else {
    // 		const temp = formStructure;
    // 		temp[3][5]["display"] = "none";
    // 		temp[3][5]["required"] = false;
    // 		setFormStructure([...temp]);
    // 	}

    // } else {
    // 	const temp = formStructure;
    // 	temp[3][5]["display"] = "block";
    // 	temp[3][5]["required"] = true;
    // 	setFormStructure([...temp]);

    // }

    // if (form?.admin_approval == "Reject") {
    // 	const temp = formStructure;
    // 	temp[3][4]["display"] = "block";
    // 	temp[3][4]["required"] = true;

    // 	setFormStructure([...temp]);
    // } else {
    // 	const temp = formStructure;
    // 	temp[3][4]["display"] = "none";
    // 	temp[3][4]["required"] = false;
    // 	setFormStructure([...temp]);
    // }
    if (form?.audio_language == "Active") {
      const temp = formStructure;
      temp[2].map((ele, index) => index >= 2 && (ele["display"] = "block"));
      setForm({ ...form });
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[2].map((ele, index) => index >= 2 && (ele["display"] = "none"));
      setFormStructure([...temp]);
    }
  }, [
    form?.publish_type,
    form?.movie_access,
    form?.movie_ownership,
    form?.subtitles,
    form?.audio_language,
  ]);
  useMemo(()=>{
    if(isEdit){
      const temp = formStructure
     temp[3][3]["display"] = "block"
     setFormStructure([...temp])
    }else{
      const temp = formStructure
      temp[3][3]["display"] = "none"
      setFormStructure([...temp])
    }
  },[isEdit])
  useMemo(() => {
    // if(form?.movie_ownership == ""){
    // 	setForm({...form, movie_ownership : "In house"})
    // }
    if (form?.movie_ownership == "Collaborator")
      setForm({ ...form, movie_access: "TVOD" });
  }, [form?.movie_ownership]);
  useMemo(() => {
    if (form?.rental_price != "") {
      setForm({
        ...form,
        amount_received:
          (form?.rental_price -
          form?.streaming_fees -
          Number(form?.rental_price) * 0.185).toFixed(2),
        tax: (Number(form?.rental_price) * 0.185).toFixed(2),
      });
    }
  }, [form?.rental_price, form?.streaming_fees]);
  useMemo(() => {
    if (form?.amount_received < 0) {
      setOpenAdError(true);
      setPopupContent("Your receivable amount goes into Minus(-)");
    }
  }, [form?.amount_received]);
  // useMemo(()=>{
  //   const CollaboratorShare = Number(form?.collaborator_share)
  //   const ProducerShare = Number(form?.producer_share)
  //   const AdminShare = Number(form?.admin_share)

  //   if( CollaboratorShare )
  //   if(form?.collaborator_share && form?.producer_share){
  //     const totelShare =
  //     Number(form?.admin_share) +
  //     Number(form?.collaborator_share) 
  //     if(totelShare <=99){

  //       setForm({...form,
  //         admin_share : 100 -( Number(form?.collaborator_share) + Number(form?.producer_share))})
  //     }else{

  //     }
  //     }

  // },[form?.collaborator_share, form?.producer_share])
  useMemo(()=>{
    if(form?.collaborator_share && form?.producer_share){
      const CollaboratorShare = Number(form?.collaborator_share)
      const ProducerShare = Number(form?.producer_share)
      if( ProducerShare <= (100 -CollaboratorShare)  ){
        if(ProducerShare + CollaboratorShare < 100){
          
          setForm({...form,
                   admin_share :  100 -( Number(form?.collaborator_share) + Number(form?.producer_share)) == 0 ? 0 :100 -( Number(form?.collaborator_share) + Number(form?.producer_share)) })
        }else if(ProducerShare + CollaboratorShare == 100){
          setForm({...form,
            admin_share : "0"})
        }
      }else{
        console.log("check123");
        setOpenAdError(true);
        setPopupContent("Totel share(%) is more then 100%");
        setForm({
                  ...form,
                  producer_share: "",
                  admin_share:""
                });
      }

    }

  },[form?.collaborator_share , form?.producer_share])
  // useMemo(() => {
  //   if (form?.admin_share && form?.collaborator_share && form?.producer_share) {
  //     const totelShare =
  //       Number(form?.admin_share) +
  //       Number(form?.collaborator_share) +
  //       Number(form?.producer_share);
  //     console.log(totelShare, "totelShare");
  //     if (totelShare > 100) {
  //       console.log("check123");
  //       setOpenAdError(true);
  //       setPopupContent("Totel share(%) is more then 100%");
  //       setForm({
  //         ...form,
  //         admin_share: "",
  //         collaborator_share: "",
  //         producer_share: "",
  //       });
  //     } else if (totelShare < 100) {
  //       setOpenAdError(true);
  //       setPopupContent("Totel share(%) is less then 100%");
  //       setForm({
  //         ...form,
  //         admin_share: "",
  //         collaborator_share: "",
  //         producer_share: "",
  //       });
  //     }
  //   }
  // }, [form?.admin_share, form?.collaborator_share, form?.producer_share]);
  useMemo(() => {
    if (form?.rental_price_with_ad != "") {
      setForm({
        ...form,
        amount_received_with_ad:
          (form?.rental_price_with_ad -
          form?.streaming_fees_with_ad -
          Number(form?.rental_price_with_ad) * 0.185).toFixed(2),
        tax_with_ad: (Number(form?.rental_price_with_ad) * 0.185).toFixed(2),
      });
    }
  }, [form?.rental_price_with_ad, form?.streaming_fees_with_ad]);
  useMemo(() => {
    if (form?.amount_received_with_ad < 0) {
      setOpenAdError(true);
      setPopupContent("Your receivable amount goes into Minus(-)");
    }
  }, [form?.amount_received_with_ad]);

  useMemo(() => {
    if (categories?.statuscode == 200) {
      const temp = formStructure;
      temp[0][5]["options"] = categories?.data.map(
        (category) => category?.category_name
      );

      setFormStructure([...temp]);
      const tempFilter = tableData;
      tempFilter["filterColumn"][2]["options"] = categories?.data.map(
        (category) => category?.category_name
      );

      setTableData({ ...tempFilter });
    }
  }, [categories]);
  // useMemo(() => {
  //   if (advertisements?.statuscode == 200) {
  //     const temp = formStructure;
  //     const currentDate = new Date();
  //     temp[4][1]["options"] = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.status == "Active" && advertisement?.payment_status !== "Pending" && advertisement?.product_name
  //       )
  //       .filter((e) => e);

  //     setFormStructure([...temp]);
  //     // console.log(advertisements, temp, "statuscode")
  //   }
  // }, [advertisements]);
  useMemo(() => {
    if (distributors?.statuscode == 200) {
      // console.log(distributors, "disss")
      const temp = formStructure;
      temp[0][1]["options"] = distributors?.data
        .map((distributor) => distributor?.name)
        .filter((e) => e);

      setFormStructure([...temp]);
    }
  }, [distributors]);
  useMemo(() => {
    if (movies?.statuscode == 200) {
      // console.log(distributors, "disss")
      const temp = formStructure;
      temp[1][12]["options"] =  movies?.data
        .map((movie) =>(form?.movie_name !== movie?.movie_name) && movie?.movie_name )
        .filter((e) => e);

      setFormStructure([...temp]);
    }
  }, [movies]);
  useMemo(() => {
    if (subcategories?.statuscode == 200) {
      const temp = formStructure;
      const tempForm = form;
      const tempFilter = tableData;
      tempFilter["filterColumn"][3]["options"] = subcategories?.data;

      if (form?.movie_category != undefined) {
        temp[0][6]["options"] = subcategories?.data
          .map(
            (subcategory) =>
              subcategory?.category_name == form?.movie_category &&
              subcategory?.subcategory_name
          )
          .filter((e) => e);
        tempForm.movie_subcategory = temp[0][6]["options"].includes(
          form?.movie_subcategory
        )
          ? form?.movie_subcategory
          : "";
        // tempFilter["filterColumn"][3]["options"]=subcategories?.data.map((subcategory)=>(subcategory?.category_name==form?.movie_category&&subcategory?.subcategory_name))
      } else {
        temp[0][6]["options"] = subcategories?.data.map(
          (subcategory) => subcategory?.subcategory_name
        );
      }
      setFormStructure([...temp]);
      setForm({ ...tempForm });
      setTableData({ ...tempFilter });
    }
  }, [subcategories, form?.movie_category]);
  useMemo(() => {
    if (casts?.statuscode == 200) {
      const temp = formStructure;
      temp[0][11]["options"] = casts?.data.map((cast) => cast?.cast_name);

      setFormStructure([...temp]);
    }
  }, [casts]);
  useMemo(() => {
    if (Advisory?.statuscode == 200) {
      const temp = formStructure;
      temp[0][12]["options"] = Advisory?.data.map(
        (advisory) => advisory?.content_advisory
      );

      setFormStructure([...temp]);
    }
  }, [Advisory]);
  useMemo(() => {
    if (genre?.statuscode == 200) {
      const temp = formStructure;
      temp[0][10]["options"] = genre?.data.map((genre) => genre?.genre_title);

      setFormStructure([...temp]);
    }
  }, [genre]);
  useEffect(() => {
    if (form?.movie_duration != null || undefined) {
      setFormTitle(
        [
          "Movie Details",
          "Movie Resolutions",
          "Audio Language",
          "Age Restrictions & Downloads",
          // role != "Producer" &&
          //   `Advertisement Details (Video duration:- ${form?.movie_duration})`,
        ].filter((e) => e)
      );
    }
  }, [form?.movie_duration]);
  useMemo(() => {
    if (language?.statuscode == 200) {
      const temp = formStructure;
      temp[0][9]["options"] = language?.data.map(
        (language) => language?.language_name
      );
      temp[3][5]["options"] = language?.data.map(
        (language) => language?.language_name
      );
      temp[2][3]["options"] = language?.data.map(
        (language) => language?.language_name
      );
      setFormStructure([...temp]);
      const tempFilter = tableData;
      tempFilter["filterColumn"][1]["options"] = language?.data.map(
        (language) => language?.language_name
      );

      setTableData({ ...tempFilter });
    }
  }, [language]);
  useMemo(() => {
    if (form?.movie_distributor)
      setForm({
        ...form,
        company_name: distributors?.data
          .map(
            (option) =>
              form?.movie_distributor == option.name && option.company_name
          )
          .filter((e) => e),
      });
  }, [form?.movie_distributor]);
  const message = useSelector((state) => state.layout.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(form, "8888")
    const data = new FormData();
    // const advertise = [];
    // for (var i = 0; i < formStructure[4].length / 7; i++) {
    //   advertise.push({
    //     advertise: advertisements?.data
    //       .map(
    //         (advertisement) =>
    //           advertisement?.product_name == form?.["advertise" + i] &&
    //           advertisement?.id
    //       )
    //       .filter((e) => e)[0],
    //     time: form?.["time" + i],
    //   });
    // }

    const temp_movie_category = form;
    temp_movie_category["tempmovie_subcategory"] = form?.["movie_subcategory"];
    temp_movie_category["tempmovie_category"] = form?.["movie_category"];
    temp_movie_category["tempmovie_cast"] = form?.["movie_cast"];
    temp_movie_category["tempmovie_genre"] = form?.["movie_genre"];
    temp_movie_category["tempmovie_language"] = form?.["movie_language"];
    temp_movie_category["tempmovie_distributor"] = form?.["movie_distributor"];
    temp_movie_category["tempmovie_suggestion"] = form?.["movie_suggestion"];
    temp_movie_category["tempcontent_advisory"] = form?.["content_advisory"];
    temp_movie_category["movie_subcategory"] = subcategories?.data
      .map(
        (option) =>
          form?.["movie_subcategory"]?.includes(option.subcategory_name) &&
          option.id
      )
      .filter((e) => e);
    temp_movie_category["movie_category"] = categories?.data
      .map(
        (option) =>
          form?.["movie_category"]?.includes(option.category_name) && option.id
      )
      .filter((e) => e);
    temp_movie_category["content_advisory"] = Advisory?.data
      .map(
        (option) =>
          form?.["content_advisory"]?.includes(option?.content_advisory) &&
          option.id
      )
      .filter((e) => e);
    temp_movie_category["movie_cast"] = casts?.data
      .map(
        (option) =>
          form?.["movie_cast"]?.includes(option.cast_name) && option.id
      )
      .filter((e) => e);
    temp_movie_category["movie_genre"] = genre?.data
      .map(
        (option) =>
          form?.["movie_genre"]?.includes(option.genre_title) && option.id
      )
      .filter((e) => e);
    temp_movie_category["movie_suggestion"] = movies?.data.map(
      (options) =>
        form?.["movie_suggestion"]?.includes(options.movie_name) && options.id
    ) .filter((e) => e);;
    temp_movie_category["movie_language"] = language?.data
      .map(
        (option) =>
          form?.["movie_language"]?.includes(option.language_name) && option.id
      )
      .filter((e) => e);
    temp_movie_category["movie_distributor"] = distributors?.data
      .map(
        (option) =>
          form?.["movie_distributor"]?.includes(option.name) && option.id
      )
      .filter((e) => e);

    // temp_movie_category["advertise"] = JSON.stringify(advertise);
    temp_movie_category["created_by"] = user?.id;
    // temp_movie_category["available_for_basic_plan"] = form?.["movie_access"] == "SVOD" ?
    setForm({
      ...temp_movie_category,
    });

    Object.keys(form).map((key) => data.append(key, form?.[key]));

    formStructure.map((structure) =>
      structure.map((element) => {
        if (
          element.type == "image" &&
          form?.[element.name] &&
          typeof form?.[element.name] != "string"
        ) {
          const temp = form;
          temp["temp" + element.name] = form?.[element.name];
          temp[element.name] = URL.createObjectURL(form?.[element.name]);

          setForm({
            ...temp,
          });
        }
      })
    );

    const temp = tableData;
    if (isEdit) {
      dispatch(movie_update(data));
    } else {
      dispatch(movie_create(data));
    }
    setFlag(true);
  };
  // console.log("meeeeeee", subcategories)

  useMemo(() => {
    if (message?.statuscode == 200 && flag) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });

      setIsEdit(false);
      setFormStructure([...tempFormStruct]);
      setForm({
        set_position: tableData.tableBody.length + 1,
        movie_ownership: role == "Producer" ? "Collaborator" : "",
        movie_distributor: role == "Producer" ? distributor?.name : "",
        subtitles: "Inactive",
        audio_language: "Inactive",
        movie_is_for18Plus: "No",
        platform:"Both",
        allow_download: "Yes",
        status: "Active",
      });
      setFlag(false);
      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);
        dispatch(all_movie_list(data));
      }, 1000);
      navigate("/Movie/Movie", { state: { view: "view_all" } });
      setView("view_all");
    } else {
      const tempForm = form;
      tempForm["movie_subcategory"] = form?.["tempmovie_subcategory"];
      tempForm["movie_category"] = form?.["tempmovie_category"];
      tempForm["movie_cast"] = form?.["tempmovie_cast"];
      tempForm["content_advisory"] = form?.["tempcontent_advisory"];
      tempForm["movie_genre"] = form?.["tempmovie_genre"];
      tempForm["movie_language"] = form?.["tempmovie_language"];
      tempForm["movie_distributor"] = form?.["tempmovie_distributor"];
      tempForm["movie_thumbnail"] = form?.["tempmovie_thumbnail"];
      tempForm["movie_poster"] = form?.["tempmovie_poster"];
      tempForm["movie_suggestion"] = form?.["tempmovie_suggestion"]
      setForm({ ...tempForm });
    }
  }, [message]);
  const videoEl = useRef(null);
  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    var d = Number(video.duration);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = (h > 9 ? h : "0" + h) + ":";
    var mDisplay = (m > 9 ? m : "0" + m) + ":";
    var sDisplay = s > 9 ? s : "0" + s;

    setForm({ ...form, movie_duration: hDisplay + mDisplay + sDisplay });
  };
  const videoTrailer = useRef(null);
  const handleLoadedMetadataTrailer = () => {
    const video = videoTrailer.current;
    console.log(video, "videoss");
    var d = Number(video.duration);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = (h > 9 ? h : "0" + h) + ":";
    var mDisplay = (m > 9 ? m : "0" + m) + ":";
    var sDisplay = s > 9 ? s : "0" + s;

    setForm({
      ...form,
      movie_trailer_duration: hDisplay + mDisplay + sDisplay,
    });
  };

  useMemo(() => {
    if (result) {
      const temp = formStructure;
      // if (adPosition != undefined) {
      //   temp[4].map((value, index) => {
      //     if (value.position == adPosition) {
      //       const tempForm = form;
      //       tempForm["advertise" + value.position] = "";
      //       tempForm["duration" + value.position] = "";
      //       tempForm["time" + value.position] = "";
      //       tempForm["company_name" + value.position] = "";
      //       tempForm["views_required" + value.position] = "";
      //       tempForm["available_views" + value.position] = "";
      //       setForm({ ...tempForm });
      //       temp[4].splice(index - 6, 7);
      //     }
      //   });
      //   setFormStructure([...temp]);
      //   setAdPosition(undefined);
      // } else
      if (subtitlePosition != undefined) {
        var scount = 1;
        temp[3].map((value, index) => {
          if (
            value.position >= subtitlePosition &&
            !temp[3][temp[3].length - 1]["isLast"]
          ) {
            console.log(
              value.position,
              subtitlePosition,
              !temp[3][temp[3].length - 1]["isLast"],
              "8888"
            );
            const tempForm = form;
            if (index < temp[3].length - 3) {
              tempForm["subtitle_file_" + value.position] =
                tempForm["subtitle_file_" + (Number(value.position) + 1)];
              tempForm["subtitle_language_" + value.position] =
                tempForm["subtitle_language_" + (Number(value.position) + 1)];
            } else {
              tempForm["subtitle_file_" + value.position] = "";
              tempForm["subtitle_language_" + value.position] = "";
            }

            setForm({ ...tempForm });
          }
        });
        temp[3].map((value, index) => {
          if (value.type == "button" && index > 3) {
            temp[3][index - 1]["name"] = "subtitle_file_" + scount;
            temp[3][index - 2]["name"] = "subtitle_language_" + scount;
            temp[3][index]["position"] = scount;
            // if(index == temp[2].length -1 ){
            //   temp[2][index]["isLast"] = true

            // }
            scount++;
          }
        });
        console.log(temp[3], temp[3].length, "testing4");
        if (!temp[3][temp[3].length - 1]["isLast"]) {
          temp[3].splice(temp[3].length - 3, 3);
        }
        temp[3][temp[3].length - 1]["isLast"] = true;
        // setFormStructure([...temp]);
        setFormStructure([...temp]);
        setSubtitlePosition(undefined);

        dispatch(subtitle_delete({ id: subTitleID }));
        // console.log(form, "idssss")
      } else if (audioPosition != undefined) {
        var count = 1;
        temp[2].map((value, index) => {
          if (
            value.position >= audioPosition &&
            !temp[2][temp[2].length - 1]["isLast"]
          ) {
            console.log(
              value.position,
              audioPosition,
              !temp[2][temp[2].length - 1]["isLast"],
              "8889"
            );
            // console.log(value.position, "8888")]
            const tempForm = form;
            if (index < temp[2].length - 3) {
              tempForm["audio_file_" + value.position] =
                tempForm["audio_file_" + (Number(value.position) + 1)];
              tempForm["audio_language_" + value.position] =
                tempForm["audio_language_" + (Number(value.position) + 1)];
            } else {
              tempForm["audio_file_" + value.position] = "";
              tempForm["audio_language_" + value.position] = "";
            }

            setForm({ ...tempForm });
          }
        });

        temp[2].map((value, index) => {
          if (value.type == "button" && index > 3) {
            temp[2][index - 1]["name"] = "audio_file_" + count;
            temp[2][index - 2]["name"] = "audio_language_" + count;
            temp[2][index]["position"] = count;
            // if(index == temp[2].length -1 ){
            //   temp[2][index]["isLast"] = true

            // }
            count++;
          }
        });
        console.log(temp[2], temp[2].length, "testing3");
        if (!temp[2][temp[2].length - 1]["isLast"]) {
          temp[2].splice(temp[2].length - 3, 3);
        }
        temp[2][temp[2].length - 1]["isLast"] = true;
        setFormStructure([...temp]);

        setAudioPosition(undefined);
        dispatch(audio_file_delete({ id: audioFileId }));
      }
    }
    setResult(undefined);
  }, [result]);

  // useMemo(() => {
  //   if (form?.advertise0 != undefined) {
  //     const temp = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.product_name == form?.advertise0 && advertisement
  //       )
  //       .filter((e) => e)[0];

  //     setForm({
  //       ...form,
  //       duration0: temp?.duration,
  //       company_name0: temp?.advertiser?.company_name,
  //       views_required0: temp?.views_required,
  //       available_views0: temp?.views_required - temp?.no_of_views,
  //     });
  //   } else {
  //     setForm({
  //       ...form,
  //       duration0: "",
  //       time0: "",
  //       company_name0: "",
  //       views_required0: "",
  //       available_views0: "",
  //     });
  //   }
  // }, [form?.advertise0]);
  // useMemo(() => {
  //   if (form?.advertise1 != undefined) {
  //     const temp = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.product_name == form?.advertise1 && advertisement
  //       )
  //       .filter((e) => e)[0];

  //     setForm({
  //       ...form,
  //       duration1: temp?.duration,
  //       company_name1: temp?.advertiser?.company_name,
  //       views_required1: temp?.views_required,
  //       available_views1: temp?.views_required - temp?.no_of_views,
  //     });
  //   }
  // }, [form?.advertise1]);
  // useMemo(() => {
  //   if (form?.advertise2 != undefined) {
  //     const temp = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.product_name == form?.advertise2 && advertisement
  //       )
  //       .filter((e) => e)[0];

  //     setForm({
  //       ...form,
  //       duration2: temp?.duration,
  //       company_name2: temp?.advertiser?.company_name,
  //       views_required2: temp?.views_required,
  //       available_views2: temp?.views_required - temp?.no_of_views,
  //     });
  //   }
  // }, [form?.advertise2]);
  // useMemo(() => {
  //   if (form?.advertise3 != undefined) {
  //     const temp = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.product_name == form?.advertise3 && advertisement
  //       )
  //       .filter((e) => e)[0];

  //     setForm({
  //       ...form,
  //       duration3: temp?.duration,
  //       company_name3: temp?.advertiser?.company_name,
  //       views_required3: temp?.views_required,
  //       available_views3: temp?.views_required - temp?.no_of_views,
  //     });
  //   }
  // }, [form?.advertise3]);
  // useMemo(() => {
  //   if (form?.advertise4 != undefined) {
  //     const temp = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.product_name == form?.advertise4 && advertisement
  //       )
  //       .filter((e) => e)[0];

  //     setForm({
  //       ...form,
  //       duration4: temp?.duration,
  //       company_name4: temp?.advertiser?.company_name,
  //       views_required4: temp?.views_required,
  //       available_views4: temp?.views_required - temp?.no_of_views,
  //     });
  //   }
  // }, [form?.advertise4]);

  const handleClose = () => {
    setOpenAdError(false);
  };
  // useMemo(() => {
  //   const times = [
  //     form?.time0,
  //     form?.time1,
  //     form?.time2,
  //     form?.time3,
  //     form?.time4,
  //   ];
  //   var temp = [
  //     form?.time0,
  //     form?.time1,
  //     form?.time2,
  //     form?.time3,
  //     form?.time4,
  //   ];
  //   for (var j = temp.length - 1; j >= 0; j--)
  //     for (var i = temp.length - 1; i >= 0; i--) {
  //       if (j > i && temp[j] == temp[i]) {
  //         temp[j] = undefined;
  //       }
  //     }

  //   if (JSON.stringify(times) != JSON.stringify(temp)) {
  //     setOpenAdError(true);
  //     setPopupContent(
  //       "You have already added the advertisement for the same duration"
  //     );
  //   }
  //   setForm({
  //     ...form,
  //     time0: temp[0],
  //     time1: temp[1],
  //     time2: temp[2],
  //     time3: temp[3],
  //     time4: temp[4],
  //   });
  // }, [form?.time0, form?.time1, form?.time2, form?.time3, form?.time4]);

  useEffect(() => {
    const temp = formStructure;
    // temp[4][0]["handleClick"] = handleClick;
    temp[3][6]["handleClick"] = handleAddSubtitle;
    temp[2][3]["handleClick"] = handleAddAudio;
    setFormStructure([...temp]);
  }, []);
  const subtitles = language?.data?.map((ele, index) =>
    eval("form?.subtitle_language_" + index)
  );
  const audio_language = language?.data?.map((ele, index) =>
    eval("form?.audio_language_" + index)
  );
  useMemo(() => {
    const newLanguageList = language?.data
      ?.map(
        (ele, index) =>
          !subtitles.includes(ele?.language_name) && ele?.language_name
      )
      .filter((e) => e);
    const newAudioLanguage = language?.data
      ?.map(
        (ele, index) =>
          !audio_language.includes(ele?.language_name) && ele?.language_name
      )
      .filter((e) => e);
    const temp = formStructure;
    temp[3].map(
      (ele, index) =>
        ele.type == "select" && (temp[3][index]["options"] = newLanguageList)
    );
    temp[2].map(
      (ele, index) =>
        ele.type == "select" && (temp[2][index]["options"] = newAudioLanguage)
    );
    // if(form?.movie_ownership == ""){
    // 	setForm({...form,movie_ownership:"In house"})
    // }
    setFormStructure([...temp]);
  }, [form]);
  useEffect(() => {
    if (movies?.statuscode !== 200) {
      setOpen1(true);
    } else {
      setOpen1(false);
    }
  }, [movies]);

  return (
    <>
      {/* <Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 ,
				 display: 'flex',
				alignItems: 'center',
				gap:"0rem",
				flexDirection: 'column', }}

				open={open1}
			>
				<img src={LoadingGIF} style={{width:"8rem",marginBottom:"-1rem"}}/>
			
				<p>Due to High Consumption of our users , it will take time time to send Notifications
It will take Few Min</p>
			</Backdrop> */}
      <video
        style={{ display: "none" }}
        id="myvid"
        src={form?.movieMp4_URL}
        ref={videoEl}
        type="video/mp4"
        onLoadedMetadata={handleLoadedMetadata}
      ></video>
      <video
        style={{ display: "none" }}
        id="myvid1"
        src={form?.movie_trailer}
        ref={videoTrailer}
        type="video/mp4"
        onLoadedMetadata={handleLoadedMetadataTrailer}
      ></video>
      <EnlargedView
        open={openApproval}
        setOpen={setOpenApproval}
        content={content}
      />
      <Popup
        open={open}
        setOpen={setOpen}
        content={content}
        setResult={setResult}
      />
      {role == "Producer" && (
        <div style={{ textAlign: "right", marginBottom: "10px" }}>
          <Export
            fileName={"Movies"}
            access={rights?.["Movie"]?.["export_excel"] == "true"}
            isClubed={true}
            exportData={tableData?.exportData || tableData?.tableBody}
            headings={tableData.tableHead.map((value) => value.label)}
          />
        </div>
      )}
      {role !== "Producer" && (
        <ViewChange
          setForm={setForm}
          setView={setView}
          setIsEdit={setIsEdit}
          view={view}
          isEdit={isEdit}
          create_new={"/Movie/CreateMovie/"}
          view_all={"/Movie/Movie/"}
          form={form}
          access={rights?.["Movie"]?.["create"] == "true"}
          export_excel={
            <Export
              fileName={"Movies"}
              access={rights?.["Movie"]?.["export_excel"] == "true"}
              isClubed={true}
              exportData={tableData?.exportData || tableData?.tableBody}
              headings={tableData.tableHead.map((value) => value.label)}
            />
          }
          import_excel={
            <Import
              access={rights?.["Movie"]?.["import_excel"] == "true"}
              isClubed={true}
              module_name="movie"
            />
          }
          reload={<Reload isClubed={true} />}
        />
      )}

      {view == "create_new" && (
        <MultiStepForm
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          formTitleNew={formTitleNew}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Movie/EditMovie"}
        />
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
    </>
  );
}
