import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import Movie from "./../../../images/Movie.png"
import Advertisement from "./../../../images/Advertisement.png"
import Distrubutor from "./../../../images/Distrubutor.png"
import Livestreaming from "./../../../images/Livestreaming.png"
import transactions from "./../../../images/transactions.png"



const Features = ({dashboardcount,role}) => {
  const FeaturesData = [
  
    {
      id: "1",
      subTitle: dashboardcount?.[0]?.["data"],
      title: "Movies",
      image: Movie,
      link:"/Movie/Movie",
      backgroundColor:"#FFF5EE"
    },
    // {
    //   id: "2",
    //   subTitle: dashboardcount?.[2]?.["data"],
    //   title: "Live Streaming",
    //   image: Livestreaming,
    //   link:"/LiveStreaming/TVChannel",
    //   backgroundColor:"#FFF5EE"
    // },
    {
      id: "5",
      subTitle: dashboardcount?.[1]?.["data"],
      title: "Web Series",
      image: Distrubutor,
      link:"/Series/Series/"
    },
    role!="Producer"&&{
      id: "3",
      subTitle:  dashboardcount?.[2]?.["data"],
      title: "Transactions",
      image: transactions,
      link:"/Transactions/Transactions"
    },
    role!="Producer"&&{
      id: "3",
      subTitle:  dashboardcount?.[3]?.["data"],
      title: "Advertisements",
      image: transactions,
      link:"/Advertisers/Advertisers/"
    },
    role!="Producer"&&{
      id: "3",
      subTitle:  dashboardcount?.[4]?.["data"],
      title: "Collaborators",
      image: transactions,
      link:"/Distributors/Distributors/"
    },
    role!="Producer"&&{
      id: "3",
      subTitle:  dashboardcount?.[5]?.["data"],
      title: "Complaints",
      image: transactions,
      link:"/Complaint/Complaint/"
    },
    role!="Producer"&&{
      id: "4",
      subTitle: dashboardcount?.[6]?.["data"],
      title: "TVOD Customers",
      image: transactions,
      state:"TVOD",
      link:"/Transactions/Transactions"
    },
    role!="Producer"&&{
      id: "5",
      subTitle: dashboardcount?.[7]?.["data"],
      title: "Promocodes",
      image: Distrubutor,
      link:"/Coupon/Coupon"
    },
    // role!="Producer"&&{
    //   id: "6",
    //   subTitle: dashboardcount?.[6]?.["data"],
    //   title: "Advertisements",
    //   image: Advertisement,
    //   link:"/Advertisers/Advertisement/"
    // },
    
  ].filter((e)=>e);
  return (
    <>
      <Grid
        container
        justifyContent="center"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      >
        {FeaturesData.map((feature) => (
          <Grid item xs={10} sm={4} md={3} lg={role!="Producer"?3:6} key={feature.id} >
            <Link to={feature.link} state={feature.state} style={{ textDecoration: 'none' }}>
            <Card
              sx={{
                // background: "#000",
                background: "linear-gradient(225deg, black 0%,#232121 91.25%)",
                borderRadius: "10px",
                p: "25px 20px",
                mb: "15px",
                // boxShadow: "var(--gradientColorLighter2) 0px 6px 12px -2px, var(--gradientColorLighter1) 0px 3px 7px -3px",
                border: "1px solid #363535"
                
                
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  
                }}
              >
                {/* <Box
                  sx={{
                    width: "62px",
                    height: "62px",
                    lineHeight: "85px",
                    background: "#ffffff17",
                    borderRadius: "8px",
                    textAlign: "center",
                    
                  }}
                >
                  <img src={feature.image} alt="Graph" style={{verticalAlign:"baseline"}}/>
                </Box> */}
                <Box>
                  <Typography
                    variant="h1"
                    sx={{ fontSize: 20, fontWeight: 700,textAlign:"center" }}
                 
                  >
                   <span style={{color:"#fff"}}> {feature.title}</span>
                  </Typography>
                  <Typography variant="p"  fontSize={16} fontWeight={700}  textAlign={"center"} width={"100%"} display={"block"} >
                   <span style={{color:"#e55e08"}}> {feature.subTitle}</span>
                  </Typography>
                </Box>

                
              </Box>

            
            </Card></Link>
          </Grid>
        ))}
        
      </Grid>
    </>
  );
};

export default Features;
