import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import { TableData } from "./TableData";
import ViewChange from "../utils/ViewChange";
import { useDispatch, useSelector } from "react-redux";
import { FormStructure } from "./FormStructure";
import { useLocation } from "react-router-dom";
import Reload from "../utils/Reload";
import Export from "../utils/Export";
import {
  advertise_form_create,
  advertise_form_upate,
  all_advertise_form_list,
  advertise_form_delete,
} from "../../actions/adForm";
import notification_icon from "../../images/notification_icon.png"

const AdForm = () => {
  const user = useSelector((state) => state.layout.profile);
  console.log(user, "newUser");
  const role = useSelector((state) => state.layout.role);
  const rights = useSelector((state) => state.layout.rights);
  const tempFormStruct = FormStructure();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  const [flag, setFlag] = useState(false);
  const path = location?.pathname.split("/")[2];
  const message = useSelector((state) => state.layout.message);

  // cons
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
      setForm({});
    }
  }, [isEdit]);
  const tempTableData = {
    ...TableData(),
    // disableDelete: role !== "Advertiser" ? false: true,

    deleteRecord: advertise_form_delete,
    updateRecord: advertise_form_upate,
    deleteAccess: "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);

  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  useEffect(() => {
    const data = new FormData();
    data.append("user", user?.id);
    dispatch(all_advertise_form_list(data));
  }, [user]);
  const adForm = useSelector((state) => state.adForm.adForm);
  console.log(adForm, "newAdd");
  useEffect(() => {
    // setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    const newDataForm =
      location?.state?.form && JSON.parse(location?.state?.form);
    console.log(location?.state?.form, "newDataaa");
    setForm(newDataForm || { email: user?.email });

    setView(path != "AdForm" ? "create_new" : "view_all");

    setIsEdit(path == "EditAdForm");
    // console.log(producerformdata,"fddfsdfds");
  }, [location]);
  useMemo(() => {
    const temp = formStructure;
    if (form?.subscription_type == "Recharge Based") {
      temp[4]["display"] = "block";
      temp[5]["disabled"] = true;
      temp[6]["display"] = "none";
    } else if (form?.subscription_type == "Pay Per View") {
      temp[4]["display"] = "none";
      temp[5]["display"] = "block";
      temp[5]["disabled"] = false;
      temp[6]["display"] = "block";
      temp[6]["disabled"] = true;

      // temp[10]["disabled"] = true
    }

    setFormStructure([...temp]);
  }, [form?.subscription_type]);
  useMemo(() => {
    const temp = form;
    temp["views_required"] = Math.round(
      form?.recharge_amount / form?.tvod_amount
    );
    setForm({ ...temp });
  }, [form?.recharge_amount]);

  useMemo(() => {
    const temp = form;
    temp["recharge_amount"] = Math.round(
      form?.views_required * ((form?.tvod_amount * 100) / 100)
    );
    setForm({ ...temp });
  }, [form?.views_required]);
  useMemo(() => {
    if (isEdit) {
      const temp = formStructure;
      role == "Advertiser"
        ? (temp[3]["disabled"] = true)
        : (temp[3]["disabled"] = false);
      role == "Advertiser"
        ? (temp[7]["display"] = "none")
        : (temp[7]["display"] = "block");
      setFormStructure([...temp]);
    }
  }, [isEdit, role]);
  useMemo(() => {
    if (form?.status == "Rejected") {
      const temp = formStructure;
      if(role!=="Advertiser"){

        temp[8]["display"] = "block";
      }else{
        temp[8]["display"] = "none";
      }
      setFormStructure([...temp]);
    }
    else{
      const temp = formStructure;
      temp[8]["display"] = "none";
      setFormStructure([...temp]);
    }
  }, [form?.status]);
  useMemo(() => {
    if (adForm?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = adForm?.data.map((ele) => ({ ...ele , edit: ele?.status == "Approved"?  "Approved":"",
			notification_send: ele?.status == "Rejected"  ? <Link to='/Notifications/CreateNotifications/' state={{customer: ele?.advertiser?.user,send :"nofitication"}}><img src={notification_icon} alt="Notifications" height={"25px"} style={{marginRight:"5px"}} /></Link> : "-",
      Advertiser_name : ele?.advertiser?.name,
      advertiser:ele?.advertiser?.id,
      company_name : ele?.advertiser?.company_name
    
    }));
      setTableData({ ...temp });
    }
  }, [adForm]);
  console.log(adForm,"nwAd")
  
  const formTitle = isEdit ? "Edit Advertisement" : "Create Advertisement";

  const handleSubmit = (event) => {
    event.preventDefault();
    const temp_form = form;
    temp_form["user"] = user?.id;
    // temp_form["movie_genre"] = genre?.data.map((option) => form?.["movie_genre"]?.includes(option.genre_title) && option.id).filter(e => e)
    setForm({
      ...temp_form,
    });
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    if (isEdit) {
      dispatch(advertise_form_upate(data));
    } else {
      dispatch(advertise_form_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });

      setIsEdit(false);
      setTimeout(() => {
        const data = new FormData();
        data.append("email", user?.email);
        data.append("user", user?.id);

        dispatch(all_advertise_form_list(data));
        navigate("/AdForm/AdForm/", { state: { view: "view_all" } });
        setView("view_all");
      }, 900);
    } else {
      const tempForm = form;
      setForm({ ...tempForm });
    }
  }, [message]);
  return (
    <>
      {/* <Popup
      open={open}
      setOpen={setOpen}
      content={content}
      setResult={setResult}
    /> */}
      {role === "Advertiser" && (
        <ViewChange
          setForm={setForm}
          setView={setView}
          setIsEdit={setIsEdit}
          view={view}
          isEdit={isEdit}
          create_new={"/AdForm/CreateAdForm/"}
          view_all={"/AdForm/AdForm/"}
          form={form}
          reload={<Reload isClubed={true} />}
          access="true"
        />
      )}
      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/AdForm/EditAdForm"}
        />
      )}

      {/* <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={openAdError}
      autoHideDuration={1500}
      onClose={handleClose}
    >
      <Alert severity="info" variant="filled" color="success">
        {content}
      </Alert>
    </Snackbar> */}
    </>
  );
};

export default AdForm;
