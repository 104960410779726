import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";



import thumbnail from "./../../images/thumbnail.jpg";
import "./ProductDetailsContent.css";
import view from "./../../images/View.png";
import Like from "./../../images/Like.png";
import download from "./../../images/download.png";
import { IMAGE } from "./../../api/index";
const ProductProfile= ({data,path}) => {
	// console.log(data,"kklldd")
	return (
		<>
		
					<Card
						sx={{
							textAlign: "center",
							boxShadow: "none",
							borderRadius: "10px",
							p: "15px 15px",
							mb: "15px",
							
						}}
					><div style={{display:"flex",justifyContent:"space-evenly" ,height:"230px"}}>
						{path=="MovieDetails" &&<img
							src={IMAGE+(path=="MovieDetails"?data?.movie_thumbnail:data?.episode_thumbnail)}
							alt="Member"
							// width="148px"
							height="200px"
							style={{ borderRadius: "10px" }}
						/>}
							
								<img
									src={IMAGE+(path=="MovieDetails"?data?.movie_poster:data?.episode_poster)}
									alt="Image"
									height="200px"
									style={{ borderRadius: "10px" }}
								/>
								</div>
						<Typography
							as="h4"
							sx={{
								fontSize: 16,
								fontWeight: 500,
								mt: "10px",
							}}
						>
							{path=="MovieDetails"?data?.movie_name:data?.episode_title}
						</Typography>

						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-around",
								textAlign: "center",
								mt: "20px",
							}}
						>
							<Box>
								<Typography color="#A9A9C8"  fontSize="13px">
									{/* <img src={Like} height={"50px"} /> */}Likes
								</Typography>
								<Typography fontWeight="500" fontSize="16px">
								{path=="MovieDetails"?data?.movieLikes:data?.episodeLikes}
								</Typography>
							</Box>

							<Box>
								<Typography color="#A9A9C8"  fontSize="13px">
									{/* <img src={download} height={"50px"} /> */}Downloads
								</Typography>
								<Typography fontWeight="500" fontSize="16px">
								{path=="MovieDetails"?data?.total_downloads:data?.episodeDownloads}
								</Typography>
							</Box>

							<Box>
								<Typography color="#A9A9C8"  fontSize="13px">
									{/* <img src={view} height={"50px"} /> */}Views
								</Typography>
								<Typography fontWeight="500" fontSize="16px">
								{path=="MovieDetails"?data?.movieViews:data?.episodeViews}
								</Typography>
							</Box>
						</Box>
					</Card>
					
			
			
		</>
	);
};

export default ProductProfile;
