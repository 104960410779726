

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Series from "../components/WebSeries/Series/Series"
import Episode from '../components/WebSeries/Episode/Episode';
import Season from '../components/WebSeries/Season/Season';
import ProductDetails from "../components/ProductDetails/ProductDetailsContent"
function WebSeriesModule() {
  return (
    <div className="App">
    
<Routes>
{/* < Route path='/Series' element={<Layout children={Series()}/>}/>
< Route path='/EditSeries' element={<Layout children={Series()}/>}/>
< Route path='/CreateSeries' element={<Layout children={Series()}/>}/> */}


{/* < Route path='/Episode' element={<Layout children={Episode()}/>}/>
< Route path='/EditEpisode' element={<Layout children={Episode()}/>}/>
< Route path='/CreateEpisode' element={<Layout children={Episode()}/>}/> */}

{/* < Route path='/Season' element={<Layout children={Season()}/>}/>
< Route path='/EditSeason' element={<Layout children={Season()}/>}/>
< Route path='/CreateSeason' element={<Layout children={Season()}/>}/> */}


{/* < Route path='/Episode/EpisodeDetails' element={<Layout children={ProductDetails()}/>}/> */}
      </Routes>
    </div>
  );
}

export default WebSeriesModule;
