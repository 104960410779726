import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import SubAdmin from '../components/Masters/SubAdmin/SubAdmin';

function SubAdminModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateSubAdmin/' element={<Layout children={SubAdmin()} />} />
                < Route path='/EditSubAdmin/' element={<Layout children={SubAdmin()} />} />
                < Route path='/SubAdmin/' element={<Layout children={SubAdmin()} />} />
            </Routes>
        </div>
    )
}
export default SubAdminModule